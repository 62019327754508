import {
  CHANGE_PROFILE_INPUT,
  DEPENDANTS_MODAL_OPEN,
  EDUCATION_MODAL_OPEN,
  PROFILE_DATE_CHANGE,
  SAVE_PROFILE_FORM_REQUESTED,
  ADD_EDUCATION_REQUESTED,
  ADD_DEPENDANT_REQUESTED,
  GET_PERSONAL_DATA_REQUESTED,
  CHANGE_PROFILE_PICTURE_REQUESTED,
  DELETE_EXAM_MODAL_OPEN,
  EXAM_MODAL_OPEN,
  EXAM_PLAN_MODAL_OPEN,
  ADD_EXAM_REQUESTED,
  ADD_EXAM_PLAN_REQUESTED,
  DELETE_EXAM_REQUESTED,
  DELETE_EXAM_PLAN_MODAL_OPEN,
  DELETE_EXAM_PLAN_REQUESTED,
  COMPLETE_EXAM_PLAN_MODAL_OPEN,
  COMPLETE_EXAM_PLAN_REQUESTED,
} from 'consts/actionTypes';

export const changeProfileInput = (field, value) => ({
  type: CHANGE_PROFILE_INPUT,
  payload: {
    field,
    value,
  },
});


export const dependantsModalOpen = (modalOpen, { childName, childTaxNumber, childTajNumber, childsMotherName, childsBirthday, nodeId }) => ({
  type: DEPENDANTS_MODAL_OPEN,
  payload: {
    modalOpen,
    childName: childName || '',
    childTaxNumber: childTaxNumber || '',
    childTajNumber: childTajNumber || '',
    childsMotherName: childsMotherName || '',
    childsBirthday,
    nodeId: nodeId || null,
  },
});

export const educationModalOpen = (modalOpen, { instituteName, fieldOfStudy, degree, serialNumber, nodeId }) => ({
  type: EDUCATION_MODAL_OPEN,
  payload: {
    modalOpen,
    instituteName: instituteName || '',
    fieldOfStudy: fieldOfStudy || '',
    degree: degree || '',
    serialNumber: serialNumber || '',
    nodeId: nodeId || null,
  },
});

export const deleteExamModalOpen = (modalOpen, { name, nodeId }) => ({
  type: DELETE_EXAM_MODAL_OPEN,
  payload: {
    modalOpen,
    name: name || '',
    nodeId: nodeId || null,
  },
});

export const deleteExamPlanModalOpen = (modalOpen, { name, nodeId }) => ({
  type: DELETE_EXAM_PLAN_MODAL_OPEN,
  payload: {
    modalOpen,
    name: name || '',
    nodeId: nodeId || null,
  },
});

export const completeExamPlanModalOpen = (modalOpen, { name, nodeId }) => ({
  type: COMPLETE_EXAM_PLAN_MODAL_OPEN,
  payload: {
    modalOpen,
    name: name || '',
    nodeId: nodeId || null,
  },
});

export const examModalOpen = (modalOpen, { name, acquiredAt, expiresAt, nodeId }) => ({
  type: EXAM_MODAL_OPEN,
  payload: {
    modalOpen,
    name: name || '',
    acquiredAt: acquiredAt || '',
    expiresAt: expiresAt || '',
    nodeId: nodeId || null,
  },
});

export const examPlanModalOpen = (modalOpen, { name, deadlineDate, nodeId }) => ({
  type: EXAM_PLAN_MODAL_OPEN,
  payload: {
    modalOpen,
    name: name || '',
    deadlineDate: deadlineDate || '',
    nodeId: nodeId || null,
  },
});

export const dateChange = (name, date) => ({
  type: PROFILE_DATE_CHANGE,
  payload: {
    name,
    date,
  },
});

export const saveProfileForm = () => ({
  type: SAVE_PROFILE_FORM_REQUESTED,
});

export const addEducation = (instituteName, fieldOfStudy, degree, serialNumber, node) => ({
  type: ADD_EDUCATION_REQUESTED,
  payload: {
    instituteName,
    fieldOfStudy,
    degree,
    serialNumber,
    node,
  },
});

export const addDependant = (childName, childTaxNumber, childTajNumber, childsMotherName, childsBirthday, node) => ({
  type: ADD_DEPENDANT_REQUESTED,
  payload: {
    childName,
    childTaxNumber,
    childTajNumber,
    childsMotherName,
    childsBirthday,
    node,
  },
});

export const deleteExam = (node) => ({
  type: DELETE_EXAM_REQUESTED,
  payload: {
    node,
  },
});

export const deleteExamPlan = (node) => ({
  type: DELETE_EXAM_PLAN_REQUESTED,
  payload: {
    node,
  },
});

export const completeExamPlan = (payload) => ({
  type: COMPLETE_EXAM_PLAN_REQUESTED,
  payload,
});

export const addExam = (name, acquiredAt, expiresAt, node) => ({
  type: ADD_EXAM_REQUESTED,
  payload: {
    name,
    acquiredAt,
    expiresAt,
    node,
  },
});

export const addExamPlan = (name, deadlineDate, node) => ({
  type: ADD_EXAM_PLAN_REQUESTED,
  payload: {
    name,
    deadlineDate,
    node,
  },
});

export const getPersonalDetails = () => ({
  type: GET_PERSONAL_DATA_REQUESTED,
});

export const profilePictureChange = (file, fileName) => ({
  type: CHANGE_PROFILE_PICTURE_REQUESTED,
  payload: {
    file,
    fileName,
  },
});
