import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Alert } from 'antd';
import { login, cognitoLogin } from 'actions/userActions';
import 'routes/LoginPage/login-page.scss';
import { CompanyLogo, getPageTitle } from '../../utils/branding';

function LoginPage(props) {
  const { isAuthenticating, hasAuthenticatingError, dispatchCognitoLogin, dispatchLogin, cognitoCode: code } = props;
  const hasError = !isAuthenticating && hasAuthenticatingError;

  useEffect(() => {
    document.title = getPageTitle('Login');
  }, []);

  const handleLogin = () => {
    dispatchCognitoLogin();
    dispatchLogin(code);
  };

  useEffect(() => {
    if (code) {
      handleLogin();
    }
  }, [code]);

  const loginLink = new URL(
    `${process.env.REACT_APP_COGNITO_URL}?scope=openid+profile`,
  );
  loginLink.searchParams.append(
    'client_id',
    process.env.REACT_APP_COGNITO_CLIENT_ID,
  );
  loginLink.searchParams.append(
    'redirect_uri',
    process.env.REACT_APP_COGNITO_CALLBACK_URL,
  );
  loginLink.searchParams.append('response_type', 'code');

  return (
    <div
      className="login-container"
      style={{ marginTop: hasError ? 106 : 200 }}
    >
      {
        hasError
        && (
          <Alert
            message="Your session has timed out"
            description="Please log in again"
            type="error"
            showIcon
          />
        )
      }
      <CompanyLogo
        style={{ marginTop: hasError ? 12 : 0 }}
        alt="Company logo"
        className="login-logo"
      />
      <Button
        loading={isAuthenticating}
        onClick={() => {
          window.location.href = loginLink;
        }}
      >
        Login
      </Button>
    </div>
  );
}

LoginPage.propTypes = {
  dispatchCognitoLogin: PropTypes.func.isRequired,
  hasAuthenticatingError: PropTypes.bool.isRequired,
  dispatchLogin: PropTypes.func.isRequired,
  isAuthenticating: PropTypes.bool.isRequired,
  cognitoCode: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticating: state.userReducer.isAuthenticating,
  hasAuthenticatingError: state.userReducer.hasAuthenticatingError,
  cognitoCode: state.userReducer.code,
});

const mapDispatchToProps = {
  dispatchCognitoLogin: cognitoLogin,
  dispatchLogin: login,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
