import {
  GET_COLLEAGUES_REQUESTED,
  SEARCH_COLLEAGUE,
  REMOVE_COLLEAGUE_SEARCH_FIELD,
  ROLE_FILTER,
  STATUS_FILTER,
  CLEAR_COLLEAGUE_FILTER,
  EMPTY_COLLEAGUES,
} from 'consts/actionTypes';

export const getColleagues = () => ({
  type: GET_COLLEAGUES_REQUESTED,
});

export const searchColleague = (value) => ({
  type: SEARCH_COLLEAGUE,
  payload: {
    value,
  },
});


export const removeSearchField = () => ({
  type: REMOVE_COLLEAGUE_SEARCH_FIELD,
});

export const roleChange = (filteredRoles) => ({
  type: ROLE_FILTER,
  payload: {
    filteredRoles,
  },
});

export const statusChange = (filteredStatuses) => ({
  type: STATUS_FILTER,
  payload: {
    filteredStatuses,
  },
});

export const clearFilter = () => ({
  type: CLEAR_COLLEAGUE_FILTER,
});

export const emptyColleages = () => ({
  type: EMPTY_COLLEAGUES,
});
