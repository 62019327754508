import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { ReactComponent as AddMore } from 'assets/images/add_new.svg';
import { DatePicker } from 'antd';
import IntraModal from 'components/Modal/Modal';
import ProfileInputField from 'components/ProfileCard/ProfileInputField';
import './exam-modal.scss';


const ExamModal = ({
  onModalOpen, name, acquiredAt, expiresAt,
  showModal, handleClose,
  handleSubmit, onChange, onDateChange,
  nodeId,
}) => {
  const disabledSubmit = (!name || name === '')
    || (!acquiredAt || acquiredAt === '')
    || (!expiresAt || expiresAt === '');
  return (
    <>
      <div
        className="add-new-button"
        tabIndex={0}
        role="button"
        onKeyPress={() => { }}
        onClick={onModalOpen}
      >
        <AddMore width={15} height={15} />
        <div>ADD NEW EXAM</div>
      </div>
      <IntraModal
        title="EXAMS"
        open={showModal}
        close={handleClose}
        submit={() => { handleSubmit(nodeId); }}
        disabled={disabledSubmit}
        okButton={nodeId ? 'CHANGE EXAM DATA' : 'ADD NEW EXAM'}
        cancelButton="CANCEL"
        className="exams-modal"
      >
        <div className="profile-page-inputs">
          <ProfileInputField
            name="name"
            title="EXAM NAME"
            value={name}
            onChange={onChange}
            placeholder="Enter name"
          />
        </div>
        <div className="profile-page-inputs">
          <div className="profile-card-input-container">
            <div className="profile-card-input-label">ACQUIRED</div>
            <DatePicker
              value={!acquiredAt ? acquiredAt : moment(acquiredAt)}
              onChange={(date, dateString) => onDateChange(date, dateString, 'acquiredAt')}
              placeholder="Select date"
            />
          </div>
          <div className="profile-card-input-container">
            <div className="profile-card-input-label">EXPIRES</div>
            <DatePicker
              value={!expiresAt ? expiresAt : moment(expiresAt)}
              onChange={(date, dateString) => onDateChange(date, dateString, 'expiresAt')}
              placeholder="Select date"
              allowClear
            />
          </div>
        </div>
      </IntraModal>
    </>
  );
};

ExamModal.propTypes = {
  onModalOpen: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  acquiredAt: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  expiresAt: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  showModal: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onDateChange: PropTypes.func.isRequired,
  nodeId: PropTypes.number,
};

ExamModal.defaultProps = {
  acquiredAt: null,
  expiresAt: null,
  nodeId: undefined,
};

export default ExamModal;
