import React from 'react';
import PropTypes from 'prop-types';
import IntraAvatar from 'components/IntraAvatar/IntraAvatar';
import getStatusColor from 'styles/statusColors';


const ColleagueCard = ({ profilePicture, lastStatus, firstName, lastName, role }) => (
  <div style={{ display: 'flex', flex: 1 }}>
    <div className="colleague-avatar-container">
      <IntraAvatar
        size={50}
        src={profilePicture}
        status={lastStatus}
        firstName={firstName}
        lastName={lastName}
        isDisabled
      />
    </div>
    <div className="colleague-card-data-container">
      <div className="colleague-card-data-name">{`${firstName} ${lastName}`}</div>
      <div className="colleague-card-data-role">{role}</div>
      <div className="colleague-card-data-status" style={{ color: getStatusColor(lastStatus) }}>{lastStatus}</div>
    </div>
  </div>
);


ColleagueCard.propTypes = {
  profilePicture: PropTypes.string.isRequired,
  lastStatus: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
};

export default ColleagueCard;
