import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import IntraModal from 'components/Modal/Modal';
import ProfileInputField from 'components/ProfileCard/ProfileInputField';
import { ReactComponent as AddMore } from 'assets/images/add_new.svg';
import './education-modal.scss';


const EducationModal = ({
  onModalOpen, instituteName, fieldOfStudy, degree, serialNumber, showModal, handleClose, handleSubmit, onChange, handleSelectChange, degrees, nodeId,
}) => {
  const disabledSubmit = (!instituteName || instituteName === '')
    || (!fieldOfStudy || fieldOfStudy === '')
    || (!degree || degree === '')
    || (!serialNumber || serialNumber === '');
  return (
    <>
      <div
        className="add-new-button"
        tabIndex={0}
        role="button"
        onKeyPress={() => { }}
        onClick={onModalOpen}
      >
        <AddMore width={15} height={15} />
        <div>ADD NEW DEGREE</div>
      </div>
      <IntraModal
        title="HIGHEST LEVEL EDUCATION"
        open={showModal}
        close={handleClose}
        submit={() => { handleSubmit(nodeId); }}
        disabled={disabledSubmit}
        okButton={nodeId ? 'CHANGE DEGREE' : 'ADD NEW DEGREE'}
        cancelButton="CANCEL"
        className="education-modal"
      >
        <div className="profile-page-inputs">
          <ProfileInputField
            name="instituteName"
            title="INSTITUTE NAME"
            value={instituteName}
            onChange={onChange}
            placeholder="Enter institute name"
          />
          <ProfileInputField
            name="fieldOfStudy"
            title="FIELD OF STUDY"
            value={fieldOfStudy}
            onChange={onChange}
            placeholder="Enter field of study"
          />
        </div>
        <div className="profile-page-inputs">
          <div className="profile-card-input-container">
            <div className="profile-card-input-label">DEGREE</div>
            <Select
              showArrow
              placeholder={`${!degree ? 'Choose degree' : ''}`}
              onChange={handleSelectChange}
              value={degree}
            >
              {degrees.map((item, index) => (
                <Select.Option value={item.value} key={index.toString()}>{item.display}</Select.Option>
              ))}
            </Select>
          </div>
          <ProfileInputField
            name="serialNumber"
            title="SERIAL NUMBER"
            value={serialNumber}
            onChange={onChange}
            placeholder="Enter serial number"
          />
        </div>
      </IntraModal>
    </>
  );
};

EducationModal.propTypes = {
  onModalOpen: PropTypes.func.isRequired,
  instituteName: PropTypes.string.isRequired,
  fieldOfStudy: PropTypes.string.isRequired,
  degree: PropTypes.string.isRequired,
  serialNumber: PropTypes.string.isRequired,
  showModal: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  degrees: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleSelectChange: PropTypes.func.isRequired,
  nodeId: PropTypes.number,
};

EducationModal.defaultProps = {
  nodeId: undefined,
};

export default EducationModal;
