import React from 'react';
import PropTypes from 'prop-types';


const LanguageSelector = ({ lang, onSelectLanguage, topic, current, selectedLang }) => (topic[lang]
  ? (
    <div
      className={`wiki-details-content-header-language${current === lang ? ' selected' : ''}`}
      tabIndex={0}
      role="button"
      onKeyPress={() => { }}
      onClick={() => {
        if (selectedLang !== lang) {
          onSelectLanguage(lang);
        }
      }}
    >
      {lang}
    </div>
  )
  : null);

LanguageSelector.propTypes = {
  selectedLang: PropTypes.string.isRequired,
  lang: PropTypes.string.isRequired,
  current: PropTypes.string.isRequired,
  onSelectLanguage: PropTypes.func.isRequired,
  topic: PropTypes.shape({}).isRequired,
};

export default LanguageSelector;
