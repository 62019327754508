import React from 'react';
import ReactDOM from 'react-dom';
import Root from 'components/App/Root';
import { history } from 'services/config';
import store from 'store';
import * as serviceWorker from 'serviceWorker';
import 'styles/ant.less';
import './index.scss';


require('dotenv').config();

ReactDOM.render(<Root store={store} history={history} />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
