import React from 'react';
import StatusAndWorktimeContainer from 'components/StatusAndWorktime/StatusAndWorktimeContainer';
import BreadCrumb from 'components/BreadCrumb/BreadCrumb';
import './status-and-worktime.scss';

const StatusAndWorktime = () => (
  <div className="page page-status-and-worktime">
    <BreadCrumb breadcrumbText="status and worktime" />
    <StatusAndWorktimeContainer />
  </div>
);

export default StatusAndWorktime;
