import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { AutoComplete } from 'antd';
import IosSearch from 'react-ionicons/lib/IosSearch';
import { setSelectedTags, setNoResultMessage } from 'actions/wikiActions';
import { history } from 'services/config';
import './wiki-auto-complete-input.scss';


class WikiAutoCompleteInput extends Component {
  state = {
    dataSource: [],
    value: '',
    placeholderVisible: true,
  }

  findTags = (tagList, searchText) => {
    const dataSource = [];
    const { selectedTags } = this.props;
    tagList.forEach((tag) => {
      if (selectedTags.length > 0 && selectedTags.indexOf(tag.id) === -1) {
        dataSource.push({ text: `${tag.hun} - ${tag.eng}`, value: tag.id });
      } else if (selectedTags.length === 0) {
        dataSource.push({ text: `${tag.hun} - ${tag.eng}`, value: tag.id });
      }
    });
    return dataSource.filter((tag) => tag.text.toLowerCase().includes(searchText));
  }

  handleSearch = (searchText) => {
    if (searchText.length > 0) {
      const { tags } = this.props;
      const newDataSource = this.findTags(tags, searchText);
      this.setState({
        dataSource: newDataSource,
      });
    } else {
      this.setState({ dataSource: [] });
    }
  }

  handleSelect = (value) => {
    const { tags, dispatchSetSelectedTags, dispatchSetNoResultMessage } = this.props;
    this.setState({
      dataSource: [],
      value: '',
    });
    dispatchSetNoResultMessage('No results found');
    dispatchSetSelectedTags(tags.find((tag) => tag.id === value).id);
    if (!window.location.pathname.includes('/wiki/searchresults')) {
      history.push('/wiki/searchresults');
    }
  }

  handleChange = async (value) => {
    await this.setState({ value });
  }

  handleFocus = () => {
    this.setState({
      placeholderVisible: false,
    });
  }

  handleBlur = () => {
    const { value } = this.state;
    if (value === '') {
      this.setState({
        placeholderVisible: true,
      });
    }
  }

  setPlaceHolder = () => {
    const { placeholderText, tags } = this.props;
    if (!tags) {
      return 'Loading tag data...';
    }
    if (tags[0] === 'No tags found.') {
      return 'No tags found.';
    }
    return placeholderText;
  }

  render() {
    const { dataSource, value, placeholderVisible } = this.state;
    const { tags } = this.props;

    return (
      <div className="wiki-autocomplete-input-container">
        <AutoComplete
          placeholder={placeholderVisible ? this.setPlaceHolder() : ''}
          value={value}
          style={{ width: 200 }}
          disabled={!tags || tags[0] === 'No tags found.'}
          dataSource={dataSource}
          onSelect={this.handleSelect}
          onSearch={this.handleSearch}
          onChange={this.handleChange}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
        />
        <button
          type="button"
          className="autocomplete-search-button"
          onClick={this.handleSelect}
        >
          <IosSearch fontSize="18px" />
        </button>
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  tags: store.wikiReducer.tags,
  selectedTags: store.wikiReducer.selectedTags,
});

const mapDispatchToProps = {
  dispatchSetSelectedTags: setSelectedTags,
  dispatchSetNoResultMessage: setNoResultMessage,
};

WikiAutoCompleteInput.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.shape({})),
  selectedTags: PropTypes.arrayOf(PropTypes.string),
  placeholderText: PropTypes.string,
  dispatchSetSelectedTags: PropTypes.func.isRequired,
  dispatchSetNoResultMessage: PropTypes.func.isRequired,
};

WikiAutoCompleteInput.defaultProps = {
  tags: [],
  selectedTags: [],
  placeholderText: '',
};

export default connect(mapStateToProps, mapDispatchToProps)(WikiAutoCompleteInput);
