import {
  APP_GET_CURRENT_USER_SUCCEEDED,
  APP_GET_STATUSES_SUCCEEDED,
  APP_GET_CURRENT_STATUS_SUCCEEDED,
  APP_MENU_CLICK,
  APP_REQUEST_STATUS_CHANGE_SUCCEEDED,
  USER_LOGOUT_SUCCEEDED,
  APP_RESIZE,
  RESTORE_USER_BY_TOKEN_SUCCEEDED,
} from 'consts/actionTypes';
import initialState from './initialState';


const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case APP_GET_CURRENT_USER_SUCCEEDED: {
      const { intra_first_name, intra_last_name, intra_profile_picture } = action.payload; // eslint-disable-line
      const name = `${intra_first_name[0].value} ${intra_last_name[0].value}`;
      const avatar = {
        /**
         * There is no support for optional chainging in this setup.
         * Likely required webpack 5 or an additional plugin.
         * https://github.com/webpack/webpack/issues/10227#issuecomment-642734920
         */
        url: intra_profile_picture.length > 0 ? intra_profile_picture[0].url : '',
        alt: intra_profile_picture.length > 0 ? intra_profile_picture[0].alt : '',
      };

      return {
        ...state,
        user: {
          name,
          avatar,
        },
      };
    }

    case APP_MENU_CLICK: {
      const { key } = action.payload;
      if (key.includes('https')) {
        return state;
      }

      return {
        ...state,
        currentPage: key,
      };
    }

    case APP_GET_STATUSES_SUCCEEDED:
      return {
        ...state,
        statuses: [
          ...action.payload,
        ],
      };

    case APP_RESIZE:
      return {
        ...state,
        isMobile: action.payload.isMobile,
        showMenu: action.payload.showMenu,
      };

    case APP_GET_CURRENT_STATUS_SUCCEEDED:
      return {
        ...state,
        currentStatus: action.payload.data[0].status,
      };

    case APP_REQUEST_STATUS_CHANGE_SUCCEEDED:
      return {
        ...state,
        currentStatus: action.payload,
      };

    case RESTORE_USER_BY_TOKEN_SUCCEEDED: {
      return {
        ...state,
        currentPage: action.payload.next,
      };
    }

    case USER_LOGOUT_SUCCEEDED:
      return initialState;

    default:
      return state;
  }
};

export default appReducer;
