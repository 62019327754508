import React from 'react';
import PropTypes from 'prop-types';
import MdCreate from 'react-ionicons/lib/MdCreate';
import MagicTable from 'containers/MagicTable/MagicTable';


const DependantsList = ({ dependants, isMobile, onModalOpen, isLoading }) => (
  <>
    <div className="profile-page-main-title">DEPENDANTS</div>
    <MagicTable
      table="dependants"
      isMobile={isMobile}
      isLoading={isLoading}
      outerColumns={[
        { title: 'Child\'s name', key: 'childName', dataIndex: 'childName' },
        { title: 'Mother\'s name', key: 'childsMotherName', dataIndex: 'childsMotherName' },
        { title: 'Birthday', key: 'childsBirthday', dataIndex: 'childsBirthday' },
        { title: 'Tax number', key: 'childTaxNumber', dataIndex: 'childTaxNumber' },
        { title: 'Social security number', key: 'childTajNumber', dataIndex: 'childTajNumber' },
        { title: '', key: 'action', dataIndex: 'action' },
      ]}
      render={{
        action: (data) => [
          (<MdCreate key="1" fontSize="15px" onClick={() => onModalOpen(data)} />),
        ],
      }}
      outerDataSource={dependants}
    />
  </>
);

DependantsList.propTypes = {
  dependants: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isMobile: PropTypes.bool.isRequired,
  onModalOpen: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default DependantsList;
