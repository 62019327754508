import React from 'react';
import PropTypes from 'prop-types';
import ColleagueModal from 'components/ColleagueModal/ColleagueModal';

const ColleagueRole = ({ attendance, filteredColleagues, width }) => {
  const isAvailable = filteredColleagues.find((colleague) => colleague.last_status === attendance);
  const alphabeticalFilter = filteredColleagues.sort((a, b) => a.intra_first_name.localeCompare(b.intra_first_name));
  return isAvailable ? (
    <div className="colleagues-separator">
      <hr />
      <div className="colleagues-separator-title">{attendance}</div>
      <div className="colleague-cards-container">
        {
          alphabeticalFilter.map((colleague) => (colleague.last_status === attendance
            ? (
              <ColleagueModal
                key={colleague.email}
                profilePicture={colleague.profile_picture_orig}
                lastStatus={colleague.last_status}
                firstName={colleague.intra_first_name}
                lastName={colleague.intra_last_name}
                email={colleague.email}
                phone={colleague.phone}
                role={colleague.role}
                width={width}
              />
            )
            : null))
        }
      </div>
    </div>
  ) : null;
};

ColleagueRole.propTypes = {
  attendance: PropTypes.string.isRequired,
  filteredColleagues: PropTypes.arrayOf(PropTypes.shape({
    intra_first_name: PropTypes.string,
    role: PropTypes.string,
  })).isRequired,
  width: PropTypes.number.isRequired,
};

export default ColleagueRole;
