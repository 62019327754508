import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Tag } from 'antd';
import { setSearchValue, closeSelectedTag, setNoResultMessage } from 'actions/wikiActions';
import './wiki-search-result-header.scss';


class SearchResultHeader extends Component {
  closeSearchTag = () => {
    const { dispatchSetSearchValue, dispatchSetNoResultMessage } = this.props;
    dispatchSetNoResultMessage('');
    dispatchSetSearchValue('');
  }

  closeTag = (tag) => {
    const { dispatchCloseSelectedTag, dispatchSetNoResultMessage, selectedTags } = this.props;
    if (selectedTags.length === 1) {
      dispatchSetNoResultMessage('');
    }
    dispatchCloseSelectedTag(tag);
  }

  getTagNames = (tagId) => {
    const { tagList } = this.props;
    if (tagList) {
      return tagList.filter((tag) => tag.id === tagId);
    }
    return null;
  }

  render() {
    const { selectedTags, searchValue } = this.props;
    return (
      <>
        {
          selectedTags.length === 0 && searchValue.length === 0
            ? (
              null
            ) : (
              <div className="search-result-header-container">
                <span className="search-result-header-label">search result for:</span>
                {
                  selectedTags
                    ? (
                      selectedTags.map((tag) => {
                        const tagNames = this.getTagNames(tag)[0];
                        return (
                          <span className="tag-list" key={tag}>
                            <Tag
                              closable
                              onClose={() => this.closeTag(tag)}
                              color="#3695FF"
                            >
                              {`tag: ${tagNames.eng}`}
                            </Tag>
                            <Tag
                              closable
                              onClose={() => this.closeTag(tag)}
                              color="#3695FF"
                            >
                              {`tag: ${tagNames.hun}`}
                            </Tag>
                          </span>
                        );
                      })
                    ) : 'loading'
                }
                {
                  searchValue.length > 0
                    ? (
                      <Tag
                        closable
                        onClose={this.closeSearchTag}
                        color="#3695FF"
                      >
                        {searchValue}
                      </Tag>
                    ) : (
                      null
                    )
                }
              </div>
            )
        }
      </>
    );
  }
}

const mapStateToProps = (store) => ({
  selectedTags: store.wikiReducer.selectedTags,
  searchValue: store.wikiReducer.searchValue,
});

const mapDispatchToProps = {
  dispatchSetSearchValue: setSearchValue,
  dispatchCloseSelectedTag: closeSelectedTag,
  dispatchSetNoResultMessage: setNoResultMessage,
};

SearchResultHeader.propTypes = {
  selectedTags: PropTypes.arrayOf(PropTypes.string),
  searchValue: PropTypes.string,
  dispatchSetSearchValue: PropTypes.func.isRequired,
  dispatchCloseSelectedTag: PropTypes.func.isRequired,
  dispatchSetNoResultMessage: PropTypes.func.isRequired,
  tagList: PropTypes.arrayOf(PropTypes.shape({})),
};

SearchResultHeader.defaultProps = {
  selectedTags: [],
  searchValue: '',
  tagList: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchResultHeader);
