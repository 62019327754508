import {
  SELECT_WIKI_TOPIC_HISTORY, SELECT_WIKI_TAG, SELECT_TOPIC_LANG,
  SELECT_TOPIC,
  WIKI_GET_CHANGELOG_REQUESTED,
  WIKI_GET_HISTORIES_REQUESTED,
  WIKI_GET_CATEGORIES_REQUESTED,
  WIKI_GET_TOPICS_BY_CATEGORY_REQUESTED,
  WIKI_GET_TAGS_REQUESTED,
  WIKI_SEARCH_REQUESTED,
  SELECT_WIKI_CATEGORY,
  WIKI_SET_SEARCH_VALUE,
  WIKI_SET_SELECTED_TAGS,
  WIKI_CLOSE_SELECTED_TAG,
  WIKI_EMPTY_SELECTED_TAGS,
  WIKI_SEARCH_EMPTY_ALL,
  WIKI_TOPIC_LOADING,
  WIKI_SET_NO_RESULT_MESSAGE,
  WIKI_GET_TOPIC_REQUESTED,
} from 'consts/actionTypes';

export const selectHistory = (key) => ({
  type: SELECT_WIKI_TOPIC_HISTORY,
  payload: {
    key,
  },
});

export const selectTag = (tagId) => ({
  type: SELECT_WIKI_TAG,
  payload: {
    tagId,
  },
});

export const selectTopicLang = (lang) => ({
  type: SELECT_TOPIC_LANG,
  payload: {
    lang,
  },
});

export const selectTopic = (topic) => ({
  type: SELECT_TOPIC,
  payload: {
    topic,
  },
});

export const getChangeLog = () => ({
  type: WIKI_GET_CHANGELOG_REQUESTED,
});

export const getHistories = (ids) => ({
  type: WIKI_GET_HISTORIES_REQUESTED,
  payload: {
    ids,
  },
});

export const getCategories = () => ({
  type: WIKI_GET_CATEGORIES_REQUESTED,
});

export const getTopicsByCategory = (id, categoryName) => ({
  type: WIKI_GET_TOPICS_BY_CATEGORY_REQUESTED,
  payload: {
    id,
    categoryName,
  },
});

export const getTags = () => ({
  type: WIKI_GET_TAGS_REQUESTED,
});

export const search = () => ({
  type: WIKI_SEARCH_REQUESTED,
});

export const selectCategory = (category, id) => ({
  type: SELECT_WIKI_CATEGORY,
  payload: {
    category,
    id,
  },
});

export const getTopic = (categoryName, categoryId, topicId) => ({
  type: WIKI_GET_TOPIC_REQUESTED,
  payload: {
    categoryName,
    categoryId,
    topicId,
  },
});

export const setSearchValue = (searchValue) => ({
  type: WIKI_SET_SEARCH_VALUE,
  payload: {
    searchValue,
  },
});

export const setSelectedTags = (tag) => ({
  type: WIKI_SET_SELECTED_TAGS,
  payload: {
    tag,
  },
});

export const closeSelectedTag = (tag) => ({
  type: WIKI_CLOSE_SELECTED_TAG,
  payload: {
    tag,
  },
});

export const setNoResultMessage = (noResultMessage) => ({
  type: WIKI_SET_NO_RESULT_MESSAGE,
  payload: {
    noResultMessage,
  },
});

export const emptySelectedTags = () => ({
  type: WIKI_EMPTY_SELECTED_TAGS,
});

export const emptyAllSearchItem = () => ({
  type: WIKI_SEARCH_EMPTY_ALL,
});

export const loading = () => ({
  type: WIKI_TOPIC_LOADING,
});
