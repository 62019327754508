import React from 'react';
import PropTypes from 'prop-types';
import MdCreate from 'react-ionicons/lib/MdCreate';
import MagicTable from 'containers/MagicTable/MagicTable';


const EducationList = ({ educations, isMobile, onModalOpen, isLoading }) => (
  <>
    <div className="profile-page-main-title">HIGHEST LEVEL EDUCATION</div>
    <MagicTable
      table="educations"
      isMobile={isMobile}
      isLoading={isLoading}
      outerColumns={[
        { title: 'institute name', key: 'instituteName', dataIndex: 'instituteName' },
        { title: 'field of study', key: 'fieldOfStudy', dataIndex: 'fieldOfStudy' },
        { title: 'degree', key: 'degree', dataIndex: 'degree' },
        { title: 'serial number', key: 'serialNumber', dataIndex: 'serialNumber' },
        { title: '', key: 'action', dataIndex: 'action' },
      ]}
      render={{
        action: (data) => [
          (<MdCreate key="1" fontSize="15px" onClick={() => onModalOpen(data)} />),
        ],
      }}
      outerDataSource={educations}
    />
  </>
);

EducationList.propTypes = {
  educations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isMobile: PropTypes.bool.isRequired,
  onModalOpen: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default EducationList;
