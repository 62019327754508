import React from 'react';
import PropTypes from 'prop-types';
import ChartTitle from 'components/Charts/ChartTitle/ChartTitle';
import EventItem from './EventItem';
import './events.scss';


const Events = ({
  today, events,
}) => (
  <div className="events-main-container">
    <ChartTitle
      chartTitle="my events today"
      iconName="IosCalendar"
    />
    <div className="events-today-date">
      {today}
    </div>
    <div className="events-data-container">
      {
        events.length === 0
          ? <div className="no-events">you have no events today</div>
          : (
            <ul className="event-list">
              {events.map((event) => (
                <EventItem
                  key={event.id}
                  time={event.time}
                  name={event.name}
                  location={event.location}
                  htmlLink={event.htmlLink}
                  hangoutLink={event.hangoutLink}
                  isDeclined={event.isDeclined}
                />
              ))}
            </ul>
          )
      }
    </div>
  </div>
);

Events.propTypes = {
  today: PropTypes.string.isRequired,
  events: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default Events;
