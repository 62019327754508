import React from 'react';
import PropTypes from 'prop-types';
import IosContact from 'react-ionicons/lib/IosContact';
import { Collapse } from 'antd';
import PersonalDatas from 'components/Profile/PersonalDatas';
import IntraAvatar from 'components/IntraAvatar/IntraAvatar';
import { ReactComponent as AddPhoto } from 'assets/images/add_photo.svg';
import IosArrowUp from 'react-ionicons/lib/IosArrowUp';

const { Panel } = Collapse;


const PersonalProfile = ({ isMobile, personalDetails, handlePictureChange, profilePicture }) => (
  <div className="profile-required-data">
    {
      isMobile
        ? (
          <Collapse>
            <Panel
              header={(
                <>
                  <div className="profile-collapse-container">
                    <div className="profile-card-photo">
                      {
                        profilePicture
                          ? (
                            <IntraAvatar
                              size={160}
                              src={profilePicture}
                              status="Away"
                              firstName={personalDetails.firstName}
                              lastName={personalDetails.lastName}
                              isDisabled
                            />
                          )
                          : <IosContact fontSize="160px" color="#CCCCCC" />
                      }
                      <div
                        className="add-photo-container"
                        role="button"
                        tabIndex={0}
                        onKeyPress={() => { }}
                        onClick={handlePictureChange}
                      >
                        <AddPhoto className="add-photo" />
                      </div>
                    </div>
                    <div className="profile-page-name">{`${personalDetails.firstName} ${personalDetails.lastName}`}</div>
                  </div>
                  <div className="profile-page-main-title">
                    MORE PERSONAL DATA
                    <IosArrowUp className="arrow-transform" />
                  </div>
                </>
              )}
              key="1"
              showArrow
            >
              <div className="profile-page-required-container">
                <PersonalDatas
                  isCollapse
                  firstName={personalDetails.firstName}
                  lastName={personalDetails.lastName}
                  birthDay={personalDetails.birthDay}
                  birthPlace={personalDetails.birthPlace}
                  motherName={personalDetails.motherName}
                  idCard={personalDetails.idCard}
                  taxNumber={personalDetails.taxNumber}
                  tajNumber={personalDetails.tajNumber}
                />
              </div>
            </Panel>
          </Collapse>
        ) : (
          <>
            <div className="profile-card-photo">
              {
                profilePicture
                  ? (
                    <IntraAvatar
                      size={160}
                      src={profilePicture}
                      status="Away"
                      firstName={personalDetails.firstName}
                      lastName={personalDetails.lastName}
                      isDisabled
                    />
                  )
                  : <IosContact fontSize="160px" color="#CCCCCC" />
              }
              <div
                className="add-photo-container"
                role="button"
                tabIndex={0}
                onKeyPress={() => { }}
                onClick={handlePictureChange}
              >
                <AddPhoto className="add-photo" />
              </div>
            </div>
            <div className="profile-page-required-container">
              <PersonalDatas
                isCollapse={false}
                firstName={personalDetails.firstName}
                lastName={personalDetails.lastName}
                birthDay={personalDetails.birthDay}
                birthPlace={personalDetails.birthPlace}
                motherName={personalDetails.motherName}
                idCard={personalDetails.idCard}
                taxNumber={personalDetails.taxNumber}
                tajNumber={personalDetails.tajNumber}
              />
            </div>
          </>
        )
    }
  </div>
);

PersonalProfile.propTypes = {
  personalDetails: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    birthDay: PropTypes.string,
    birthPlace: PropTypes.string,
    motherName: PropTypes.string,
    idCard: PropTypes.string,
    taxNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    tajNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  isMobile: PropTypes.bool.isRequired,
  profilePicture: PropTypes.string,
  handlePictureChange: PropTypes.func.isRequired,
};

PersonalProfile.defaultProps = {
  profilePicture: null,
};

export default PersonalProfile;
