import React from 'react';
import PropTypes from 'prop-types';
import MdCreate from 'react-ionicons/lib/MdCreate';
import MdRemove from 'react-ionicons/lib/MdTrash';
import MagicTable from 'containers/MagicTable/MagicTable';


const ExamList = ({ exams, isMobile, onModalOpen, onDeleteModalOpen, isLoading }) => (
  <>
    <div className="profile-page-main-title">EXAMS, ACCREDITATIONS</div>
    <MagicTable
      table="exams"
      isMobile={isMobile}
      isLoading={isLoading}
      outerColumns={[
        { title: 'name', key: 'name', dataIndex: 'name' },
        { title: 'acquired', key: 'acquiredAt', dataIndex: 'acquiredAt' },
        { title: 'expires', key: 'expiresAt', dataIndex: 'expiresAt' },
        { title: '', key: 'action', dataIndex: 'action' },
      ]}
      render={{
        action: (data) => [
          <MdCreate key="1" className="cursor-pointer" fontSize="15px" onClick={() => onModalOpen(data)} />,
          <MdRemove key="2" className="cursor-pointer" fontSize="15px" color="indianred" onClick={() => onDeleteModalOpen(data)} />,
        ],
      }}
      outerDataSource={exams}
    />
  </>
);


ExamList.propTypes = {
  exams: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isMobile: PropTypes.bool.isRequired,
  onModalOpen: PropTypes.func.isRequired,
  onDeleteModalOpen: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default ExamList;
