import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Select, Input } from 'antd';
import MdArrowDropdown from 'react-ionicons/lib/MdArrowDropdown';
import IntraModal from 'components/Modal/Modal';
import getStatusColor from 'styles/statusColors';
import './header-status.scss';

const { TextArea } = Input;

class HeaderStatus extends Component {
  state = {
    showModal: false,
    itemToSend: {
      value: '',
    },
  }

  filterOptions = (options, selectedItem) => options.filter(({ name }) => ![selectedItem, 'On holiday'].includes(name));

  getColor = (statuses, selectedItem) => {
    try {
      return getStatusColor(statuses.find(({ name }) => name === selectedItem).name);
    } catch {
      return 'black';
    }
  }

  handleChange = (key) => {
    const { statuses } = this.props;
    const selectedStatus = statuses.find((status) => status.id === key);
    this.setState((prevState) => ({
      showModal: true,
      itemToSend: {
        ...prevState.itemToSend,
        name: selectedStatus.name,
        id: selectedStatus.id,
        popup_message: Object.prototype.hasOwnProperty.call(selectedStatus, 'popup_message') ? selectedStatus.popup_message : '',
        show_message_input: Object.prototype.hasOwnProperty.call(selectedStatus, 'show_message_input') ? selectedStatus.show_message_input : false,
      },
    }));
  }

  onChange = ({ target: { value } }) => {
    const { itemToSend } = this.state;
    this.setState({ itemToSend: { ...itemToSend, value } });
  };

  render() {
    const { showModal, itemToSend } = this.state;
    const { statuses, currentStatus, handleStatusChange, showMenu } = this.props;
    const selectValue = {
      key: currentStatus,
      label: (
        <div className="header-status-selected">
          {currentStatus}
          <div className="header-status-circle" style={{ backgroundColor: this.getColor(statuses, currentStatus) }} />
        </div>
      ),
    };
    return (
      <div className="header-status-dropdown">
        {!showMenu
          && (
            <div className="header-status-dropdown-label">
              Status
              <MdArrowDropdown fontSize="18px" />
            </div>
          )}
        <Select
          value={selectValue}
          onChange={(item) => { this.handleChange(item.key); }}
          style={{ width: '205px' }}
          showArrow={false}
          dropdownMenuStype={{ maxHeight: '1000px', borderRadius: '12px' }}
          labelInValue
          dropdownClassName="header-status-select-dropdown"
        >
          {statuses
            && this.filterOptions(statuses, currentStatus).map((item) => (
              <Select.Option
                key={item.id}
                value={item.id}
              >
                <div className="header-status-select-option">
                  {item.name}
                  <div className="header-status-circle" style={{ backgroundColor: getStatusColor(item.name) }} />
                </div>
              </Select.Option>
            ))}
        </Select>
        <IntraModal
          title="STATUS CHANGE CONFIRMATION"
          open={showModal}
          close={() => this.setState({ showModal: false, itemToSend: { value: '' } })}
          submit={() => {
            handleStatusChange(itemToSend);
            this.setState({ showModal: false });
          }}
          disabled={itemToSend.value === '' && itemToSend.show_message_input}
          okButton="YES"
          cancelButton="NO"
        >
          Do you really want to change your status to
          <b>{` ${itemToSend.name}`}</b>
          ?
          {itemToSend.show_message_input
            ? (
              <>
                <div style={{ paddingTop: '20px', paddingBottom: '10px', textAlign: 'justify' }}>{itemToSend.popup_message}</div>
                <div className="working-from-home-message-required">MESSAGE</div>
                <TextArea
                  value={itemToSend.value}
                  onChange={this.onChange}
                  placeholder="Enter something..."
                  autoSize={{ minRows: 3, maxRows: 5 }}
                />
              </>
            ) : null}
        </IntraModal>
      </div>
    );
  }
}

HeaderStatus.propTypes = {
  statuses: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
  })).isRequired,
  currentStatus: PropTypes.string,
  handleStatusChange: PropTypes.func.isRequired,
  showMenu: PropTypes.bool,
};

HeaderStatus.defaultProps = {
  currentStatus: 'Away',
  showMenu: false,
};

export default HeaderStatus;
