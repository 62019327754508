import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Tag } from 'antd';
import { setSelectedTags, emptyAllSearchItem, getTopic } from 'actions/wikiActions';

import './wiki-search-result-tile.scss';


class WikiSearchResultTile extends Component {
  getTagNames = (tagId) => {
    const { tagList } = this.props;
    if (tagList) {
      return tagList.find((tag) => tag.id === tagId);
    }
    return null;
  }

  handleTagclick = (tag) => {
    const { dispatchEmptyAllSearchItem, dispatchSetSelectedTags } = this.props;
    dispatchEmptyAllSearchItem();
    dispatchSetSelectedTags(tag.id);
  }

  getLangCode = (languageCode) => (
    languageCode.includes('en')
      ? 'ENG'
      : 'HUN'
  );

  openResult = () => {
    const { dispatchGetTopic, categoryLabel, categoryId, id } = this.props;
    dispatchGetTopic(categoryLabel, categoryId, id);
  };

  render() {
    const {
      title, langcode, tags, content, lastEdit, isTranslated,
    } = this.props;
    const transformedLangCode = this.getLangCode(langcode);
    return (
      <div className="wiki-search-result-tile-container">
        <div className="wiki-search-result-tile-header">
          <div
            className="wiki-search-result-tile-title"
            role="button"
            tabIndex={0}
            onClick={() => this.openResult()}
          >
            {title}
          </div>
          <div className="{wiki-search-result-tile-}language-container">
            {
              isTranslated
                ? (
                  <>
                    <div
                      role="button"
                      tabIndex={0}
                      className={transformedLangCode === 'ENG' ? 'language-option active' : 'language-option'}
                      onClick={transformedLangCode === 'ENG' ? () => this.openResult() : () => {}}
                    >
                      eng
                    </div>
                    {' '}
                    /
                    {' '}
                    <div
                      role="button"
                      tabIndex={0}
                      className={transformedLangCode === 'HUN' ? 'language-option active' : 'language-option'}
                      onClick={transformedLangCode === 'HUN' ? () => this.openResult() : () => {}}
                    >
                      hun
                    </div>
                  </>
                ) : (
                  <div
                    role="button"
                    tabIndex={0}
                    className="language-option active"
                    onClick={() => this.openResult()}
                  >
                    {this.getLangCode(langcode)}
                  </div>
                )
            }
          </div>
        </div>
        <div className="search-result-tag-container">
          {
            tags.map((tag) => {
              const tagNames = this.getTagNames(tag);
              return (
                <span className="search-result-tag" key={tag}>
                  <Tag
                    onClick={() => this.handleTagclick(tagNames)}
                    color="#3695FF"
                  >
                    {`tag: ${tagNames.eng}`}
                  </Tag>
                  <Tag
                    onClick={() => this.handleTagclick(tagNames)}
                    color="#3695FF"
                  >
                    {`tag: ${tagNames.hun}`}
                  </Tag>
                </span>
              );
            })
          }
        </div>
        <div
          className="content"
          dangerouslySetInnerHTML={ // eslint-disable-line
            { __html: content }
          }
        />
        <div className="last-edit">
          {`last edit: ${moment(lastEdit).format('DD MMM, YYYY')}`}
        </div>
      </div>
    );
  }
}

WikiSearchResultTile.propTypes = {
  title: PropTypes.string.isRequired,
  langcode: PropTypes.string.isRequired,
  isTranslated: PropTypes.bool.isRequired,
  content: PropTypes.string.isRequired,
  lastEdit: PropTypes.string.isRequired,
  categoryLabel: PropTypes.string.isRequired,
  categoryId: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string).isRequired,
  tagList: PropTypes.arrayOf(PropTypes.shape({})),
};

WikiSearchResultTile.defaultProps = {
  tagList: null,
};

const mapDispatchToProps = {
  dispatchSetSelectedTags: setSelectedTags,
  dispatchEmptyAllSearchItem: emptyAllSearchItem,
  dispatchGetTopic: getTopic,
};

WikiSearchResultTile.propTypes = {
  dispatchSetSelectedTags: PropTypes.func.isRequired,
  dispatchEmptyAllSearchItem: PropTypes.func.isRequired,
  dispatchGetTopic: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(WikiSearchResultTile);
