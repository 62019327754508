import {
  COMMON_TABLE_ROW_CLICK,
  COMMON_TABLE_SORT_CLICK,
  COMMON_TABLE_SELECT_FILTER,
  COMMON_TABLE_SELECT_PAGER,
  COMMON_TABLE_CHANGE_TABLE,
  COMMON_TABLE_SELECT_RANGE,
} from 'consts/actionTypes';


export const tableRowClick = (table, { data }) => ({
  type: COMMON_TABLE_ROW_CLICK,
  payload: {
    data,
    table,
  },
});

export const sortClick = (table, column) => ({
  type: COMMON_TABLE_SORT_CLICK,
  payload: {
    column,
    table,
  },
});

export const selectFilter = (table, column, value) => ({
  type: COMMON_TABLE_SELECT_FILTER,
  payload: {
    column,
    value,
    table,
  },
});

export const pagerClick = (table, currentPage) => ({
  type: COMMON_TABLE_SELECT_PAGER,
  payload: {
    currentPage,
    table,
  },
});

export const selectRange = (table, min, max) => ({
  type: COMMON_TABLE_SELECT_RANGE,
  payload: {
    min,
    max,
    table,
  },
});

export const changeTable = (table) => ({
  type: COMMON_TABLE_CHANGE_TABLE,
  payload: {
    table,
  },
});
