import React from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'antd';
import IosArrowUp from 'react-ionicons/lib/IosArrowUp';
import WikiTopics from 'components/Wiki/WikiTopics';
import './wiki-topics.scss';

const { Panel } = Collapse;

const WikiTopicsContainer = ({ topics, handleClick, selectedTopic, isMobile, selectedCategory }) => (isMobile ? (
  <Collapse>
    <Panel
      header={(
        <div className="wiki-topics-container">
          <div className="wiki-topics-title">
            TOPICS
            <IosArrowUp className="arrow-transform" />
          </div>
        </div>
      )}
      key="1"
      showArrow={false}
    >
      <WikiTopics
        topics={topics}
        handleClick={handleClick}
        selectedTopic={selectedTopic}
        selectedCategory={selectedCategory}
      />
    </Panel>
  </Collapse>
) : (
  <div className="wiki-topics-container">
    <div className="wiki-topics-title">TOPICS</div>
    <WikiTopics
      topics={topics}
      handleClick={handleClick}
      selectedTopic={selectedTopic}
      selectedCategory={selectedCategory}
    />
  </div>
));

WikiTopicsContainer.propTypes = {
  topics: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleClick: PropTypes.func.isRequired,
  selectedCategory: PropTypes.string.isRequired,
  selectedTopic: PropTypes.shape({}).isRequired,
  isMobile: PropTypes.bool.isRequired,
};

export default WikiTopicsContainer;
