const initialState = {
  selectedLang: 'eng',
  categoryLoading: false,
  topicLoading: false,
  searchLoading: false,
  backupLang: 'hun',
  selectedCategory: null,
  changeLog: null,
  categories: null,
  topics: {},
  maritalStatuses: null,
  degrees: null,
  dataTypes: null,
  personalDetails: null,
  educations: null,
  dependants: null,
  currentHistory: null,
  selectedTags: [],
  searchValue: '',
  searchResults: null,
  selectedTopic: null,
  histories: null,
  currentHistories: null,
  tags: null,
  noResultMessage: '',
  numberOfDisplayedItems: 0,
};

export default initialState;
