import React from 'react';
import PropTypes from 'prop-types';
import ProgressBar from 'components/Charts/ProgressBar/ProgressBar';
import ChartTitle from 'components/Charts/ChartTitle/ChartTitle';
import SpinnerContainer from 'components/SpinnerContainer/SpinnerContainer';
import InfoHover from 'components/InfoHover/InfoHover';
import './progress-bar.scss';

const ProgressBarContainer = ({ pmWorkHours }) => {
  const popoverContent = (
    <div className="popover-content">
      <p>The amount of logged work needed to cover your weekly and monthly quotas.</p>
      <ul className="popover-content-list">
        <li>
          <span style={{ color: '#F08887' }}>Red: </span>
          with whatever you have logged today, you do not meet your quota up till yesterday.
          You need to go the extra mile in the next couple of days if you want to get back on track.
        </li>
        <li>
          <span style={{ color: '#FFC977' }}>Orange: </span>
          currently you have met your quota up till yesterday, and if all goes well you will most likely meet the one up till today as well.
        </li>
        <li>
          <span style={{ color: '#67E49C' }}>Green: </span>
          You have met the quota up till today.
        </li>
      </ul>
    </div>
  );

  if (pmWorkHours === null) {
    return (
      <div className="progress-bar-container">
        <SpinnerContainer className="progressbar-spinner-container" />
      </div>
    );
  }
  return (
    <div className="progress-bar-container">
      <>
        <div className="progress-bar-header-container">
          <ChartTitle
            chartTitle="pm workhours"
            iconName="IosTrendingUp"
          />
          <InfoHover content={popoverContent} title="ABOUT PM WORKHOURS" />
        </div>
        <div className="progress-bar-chart-container">
          <ProgressBar
            currentLoggedTimeFormatted={pmWorkHours.weekly_statistics.logged_time_formatted}
            currentLoggedTime={pmWorkHours.weekly_statistics.logged_time}
            expectedLoggedTime={pmWorkHours.weekly_statistics.current_expected_hours}
            totalLoggedTime={pmWorkHours.weekly_statistics.total_weekly_workhours}
            loggedTimeStatus={pmWorkHours.weekly_statistics.logged_time_status}
            remainingLoggedTimeStatus={pmWorkHours.weekly_statistics.remaining_logged_time_status}
            currentDate={pmWorkHours.current_date}
            remainingLogTimeFormatted={pmWorkHours.weekly_statistics.time_difference_from_expectations_formatted}
            title="CURRENT WEEK"
            popoverLeft="The amount of work you have logged in Jira this week."
            popoverRight="The amount of logged work needed to cover your weekly quota. Today included.
            Please note that 5 or 6 hours is the minimum amount of logged work needed whether you are in office or home office respectively."
          />
          <ProgressBar
            currentLoggedTimeFormatted={pmWorkHours.monthly_statistics.logged_time_formatted}
            currentLoggedTime={pmWorkHours.monthly_statistics.logged_time}
            expectedLoggedTime={pmWorkHours.monthly_statistics.current_expected_hours}
            totalLoggedTime={pmWorkHours.monthly_statistics.total_monthly_workhours}
            loggedTimeStatus={pmWorkHours.monthly_statistics.logged_time_status}
            remainingLoggedTimeStatus={pmWorkHours.monthly_statistics.remaining_logged_time_status}
            currentDate={pmWorkHours.current_date}
            remainingLogTimeFormatted={pmWorkHours.monthly_statistics.time_difference_from_expectations_formatted}
            title="CURRENT MONTH"
            popoverLeft="The amount of work you have logged in Jira this month."
            popoverRight="The amount of logged work needed to cover your monthly quota.
            Today included. Please note that 5 or 6 hours is the minimum amount of logged work needed whether you are in office or home office respectively."
          />
        </div>
      </>
    </div>
  );
};

ProgressBarContainer.propTypes = {
  pmWorkHours: PropTypes.shape({
    current_date: PropTypes.string,
    monthly_statistics: PropTypes.shape({
      logged_time_formatted: PropTypes.string,
      logged_time: PropTypes.number,
      current_expected_hours: PropTypes.number,
      total_monthly_workhours: PropTypes.number,
      logged_time_status: PropTypes.string,
      remaining_logged_time_status: PropTypes.string,
      time_difference_from_expectations_formatted: PropTypes.string,
    }),
    weekly_statistics: PropTypes.shape({
      logged_time_formatted: PropTypes.string,
      logged_time: PropTypes.number,
      current_expected_hours: PropTypes.number,
      total_weekly_workhours: PropTypes.number,
      logged_time_status: PropTypes.string,
      remaining_logged_time_status: PropTypes.string,
      time_difference_from_expectations_formatted: PropTypes.string,
    }),
  }),
};

ProgressBarContainer.defaultProps = {
  pmWorkHours: null,
};

export default ProgressBarContainer;
