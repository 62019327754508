import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Layout, Drawer } from 'antd';
import { history } from 'services/config';
import Favico from 'favico.js';
import { menuClick, appResize } from 'actions/appActions';
import { emptyColleages } from 'actions/colleaguesActions';
import MdArrowDropleft from 'react-ionicons/lib/MdArrowDropleft';
import MdArrowDropright from 'react-ionicons/lib/MdArrowDropright';
import SiderMenu from 'components/SiderMenu/SiderMenu';
import AppHeader from 'containers/AppHeader/AppHeader';
import getStatusColor from 'styles/statusColors';
import 'layouts/AppLayout/app-layout.scss';

const { Header, Sider, Content } = Layout;


export class AppLayout extends Component {
  state = {
    collapsed: false,
    visible: false,
  };

  favicon = new Favico({
    animation: 'none',
    position: 'up',
  });

  componentDidMount() {
    const { dispatchMenuClick } = this.props;
    window.onpopstate = () => dispatchMenuClick({
      key: window.location.pathname,
      item: { props: { table: window.location.pathname === '/status-and-worktime' ? '/status' : null } },
    });
    window.removeEventListener('resize', this.checkSize);
    window.addEventListener('resize', this.checkSize);
  }

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  onCollapse = (collapsed) => {
    this.setState({ collapsed });
  };

  getTrigger = (collapsed) => (
    <div className="app-trigger">
      {
        collapsed
          ? (<MdArrowDropright fontSize="40px" color="white" />)
          : (<MdArrowDropleft fontSize="40px" color="white" />)
      }
    </div>
  )

  handleSiderClick = (key, value) => {
    const { dispatchMenuClick, dispatchEmptyColleages } = this.props;
    if (!key.key.includes('colleages')) {
      dispatchEmptyColleages();
    }
    dispatchMenuClick(key, value);
    if (key.key.includes('https')) {
      window.open(key.key);
    } else {
      history.push(key.key);
    }
    this.setState({
      visible: false,
    });
  }

  checkSize = ({ target: { innerWidth } }) => {
    const { dispatchAppResize } = this.props;
    dispatchAppResize(innerWidth < 768, innerWidth >= 992);
  }

  render() {
    const {
      children, currentPage, showMenu,
      currentStatus,
    } = this.props;
    const { collapsed, visible } = this.state;

    return (
      <Layout style={{ minHeight: '100vh' }}>
        {currentStatus && <div className="status-indicator" style={{ backgroundColor: getStatusColor(currentStatus) }} />}
        {
          !showMenu
            ? (
              <Drawer
                placement="left"
                onClose={this.onClose}
                visible={visible}
                bodyStyle={{ padding: 0 }}
                closable={false}
              >
                <SiderMenu
                  handleClick={this.handleSiderClick}
                  selectedKey={currentPage}
                />
              </Drawer>
            )
            : (
              <Sider
                width={230}
                collapsedWidth={118}
                className="app-sider"
                collapsible
                collapsed={collapsed}
                onCollapse={this.onCollapse}
                trigger={this.getTrigger(collapsed)}
              >
                <SiderMenu
                  collapsed={collapsed}
                  handleClick={this.handleSiderClick}
                  selectedKey={currentPage}
                />
              </Sider>
            )
        }
        <Layout className="app-container">
          <Header className="app-layout-header">
            <AppHeader setVisible={() => this.setState({ visible: !visible })} />
          </Header>
          {/* { */}
          {/*  unreadMessageList && unreadMessageList.length */}
          {/*    ? ( */}
          {/*      <MessageNotification */}
          {/*        messageNumber={unreadMessageList.length} */}
          {/*        messageTitle={selectedUnreadMessage.title} */}
          {/*        onModalOpen={() => { this.setState({ messageModal: true }); }} */}
          {/*        onModalClose={() => { this.setState({ messageModal: false }); }} */}
          {/*        messageModal={messageModal} */}
          {/*        createdAt={createdAt} */}
          {/*        selectedMessage={selectedUnreadMessage} */}
          {/*        actualMessage={actualUnreadMessage} */}
          {/*        onReadMessage={() => { dispatchReadMessage(); }} */}
          {/*        getMessageDetails={() => { dispatchGetMessageDetails(unreadMessageList[0].id, 'notification'); }} */}
          {/*      /> */}
          {/*    ) : null */}
          {/* } */}
          <Content className="main-content">{children}</Content>
        </Layout>
      </Layout>
    );
  }
}

AppLayout.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  showMenu: PropTypes.bool.isRequired,
  dispatchMenuClick: PropTypes.func.isRequired,
  currentPage: PropTypes.string.isRequired,
  selectedUnreadMessage: PropTypes.shape({
    title: PropTypes.string,
  }),
  actualUnreadMessage: PropTypes.shape({}),
  dispatchAppResize: PropTypes.func.isRequired,
  dispatchEmptyColleages: PropTypes.func.isRequired,
  currentStatus: PropTypes.string,
};

AppLayout.defaultProps = {
  children: null,
  currentStatus: null,
  selectedUnreadMessage: null,
  actualUnreadMessage: null,
};

const mapStateToProps = (state) => ({
  showMenu: state.appReducer.showMenu,
  currentPage: state.appReducer.currentPage,
  currentStatus: state.appReducer.currentStatus,
});

const mapDispatchToProps = {
  dispatchMenuClick: menuClick,
  dispatchAppResize: appResize,
  dispatchEmptyColleages: emptyColleages,
};

export default connect(mapStateToProps, mapDispatchToProps)(AppLayout);
