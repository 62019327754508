import { camelCase } from 'lodash';
import {
  GET_ABSENCES_SUCCEEDED, GET_ABSENCE_DETAILS_SUCCEEDED,
  USER_LOGOUT_SUCCEEDED,
  GET_ABSENCES_REQUESTED, GET_ABSENCES_FAILED,
  GET_ABSENCE_TYPES_REQUESTED, GET_ABSENCE_TYPES_SUCCEEDED, GET_ABSENCE_TYPES_FAILED,
} from 'consts/actionTypes';
import initialState from './initialState';


const absenceReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ABSENCES_SUCCEEDED: {
      const { yearly } = action.payload;

      return {
        ...state,
        summary: {
          ...Object.entries(yearly.data[0]).reduce((prev, [key, value]) => ({ ...prev, [camelCase(key)]: value }), {}),
        },
        isLoading: false,
        hasLoadingError: false,
        errorMessage: '',
      };
    }

    case GET_ABSENCES_REQUESTED:
      return {
        ...state,
        isLoading: true,
      };

    case GET_ABSENCES_FAILED:
      return {
        ...state,
        isLoading: false,
        hasLoadingError: true,
        errorMessage: action.payload.errorMessage,
      };

    case GET_ABSENCE_TYPES_SUCCEEDED: {
      const { types } = action.payload;

      return {
        ...state,
        types,
        isLoading: false,
        hasLoadingError: false,
        errorMessage: '',
      };
    }

    case GET_ABSENCE_TYPES_REQUESTED:
      return {
        ...state,
        isLoading: true,
      };

    case GET_ABSENCE_TYPES_FAILED:
      return {
        ...state,
        isLoading: false,
        hasLoadingError: true,
        errorMessage: action.payload.errorMessage,
      };

    case GET_ABSENCE_DETAILS_SUCCEEDED:
      return {
        ...state,
        details: {
          ...state.details,
          [action.payload.nid[0].value]: action.payload,
        },
      };

    case USER_LOGOUT_SUCCEEDED:
      return initialState;

    default:
      return state;
  }
};

export default absenceReducer;
