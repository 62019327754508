import { call, takeEvery, put, select } from 'redux-saga/effects';
import * as config from 'services/config';
import * as API from 'services/api';
import {
  EVENTS_GET_EVENTS_REQUESTED,
  EVENTS_GET_EVENTS_SUCCEEDED,
  EVENTS_GET_EVENTS_FAILED,
} from 'consts/actionTypes';
import { openNotificationWithIcon } from 'services/utils';
import moment from 'moment';

export const getUserReducer = (store) => store.userReducer;

export function* getEvents() {
  try {
    const userReducer = yield select(getUserReducer);
    const { userId } = userReducer;
    const rawEvents = yield call(API.getData, `${config.host}/api/user/${userId}/calendar_events`);
    const listedEvents = rawEvents.sort((a, b) => (moment(a.start_time.valueOf()) > moment(b.start_time.valueOf())) ? 1 : -1); // eslint-disable-line
    const events = listedEvents.map((event, index) => (
      {
        id: index,
        time: `${event.start_time.substring(11, 16)} - ${event.end_time.substring(11, 16)}`,
        name: event.title,
        location: event.location,
        htmlLink: event.link,
        hangoutLink: event.hangout_link,
        isDeclined: event.status === 'declined',
      }
    ));
    yield put({
      type: EVENTS_GET_EVENTS_SUCCEEDED,
      payload: {
        events,
      },
    });
  } catch (error) {
    yield put({
      type: EVENTS_GET_EVENTS_FAILED,
      payload: {
        errorMessage: error.message,
      },
    });
    console.log(error); // eslint-disable-line
    openNotificationWithIcon('error', 'error', 'Cannot get calendar from google!');
  }
}

export const eventSagas = [
  takeEvery(EVENTS_GET_EVENTS_REQUESTED, getEvents),
];
