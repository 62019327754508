import {
  GET_ABSENCES_REQUESTED, GET_ABSENCE_TYPES_REQUESTED, NEW_ABSENCES_REQUESTED,
  GET_ABSENCE_DETAILS_REQUESTED, UPDATE_ABSENCE_DETAILS_REQUESTED,
  DELETE_ABSENCE_REQUESTED,
} from 'consts/actionTypes';

export const getAbsences = () => ({
  type: GET_ABSENCES_REQUESTED,
});

export const getAbsenceTypes = () => ({
  type: GET_ABSENCE_TYPES_REQUESTED,
});

export const newAbsence = ({ range, radio, text }, callback, id) => (id
  ? ({
    type: UPDATE_ABSENCE_DETAILS_REQUESTED,
    payload: {
      radio,
      text,
      range: range.map((moment) => moment.format('YYYY-MM-DD')),
      callback,
      id,
    },
  })
  : ({
    type: NEW_ABSENCES_REQUESTED,
    payload: {
      radio,
      text,
      range: range.map((moment) => moment.format('YYYY-MM-DD')),
      callback,
    },
  }));

export const getAbsenceDetails = (id, openModal) => ({
  type: GET_ABSENCE_DETAILS_REQUESTED,
  payload: {
    id,
    openModal,
  },
});

export const deleteAbsence = (id) => ({
  type: DELETE_ABSENCE_REQUESTED,
  payload: id,
});
