import React from 'react';
import PropTypes from 'prop-types';
import { Spin, Icon } from 'antd';

const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

const SpinnerContainer = ({ className }) => (
  <div className={className}>
    <Spin indicator={antIcon} />
  </div>
);

SpinnerContainer.propTypes = {
  className: PropTypes.string.isRequired,
};

export default SpinnerContainer;
