import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Spin } from 'antd';
import { Link } from 'react-router-dom';
import MdFiling from 'react-ionicons/lib/MdFiling';
import { getChangeLog } from 'actions/wikiActions';
import WikiChangeLog from 'components/Wiki/WikiChangeLog';
import './wiki-change-log-page.scss';
import { getPageTitle } from '../../utils/branding';


class WikiChangeLogPage extends React.Component {
  componentDidMount() {
    document.title = getPageTitle('Wiki');
    const { dispatchGetChangelog } = this.props;
    dispatchGetChangelog();
  }

  render() {
    const { changeLog } = this.props;
    return (
      <div className="wiki-change-log-page">
        <div className="wiki-change-log-title-container">
          <Link to="/wiki">
            <MdFiling />
            WIKI
          </Link>
          /CHANGELOG
        </div>
        {
          changeLog && changeLog.length
            ? <WikiChangeLog {...this.props} />
            : <Spin size="small" />
        }
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  isMobile: store.appReducer.isMobile,
  changeLog: store.wikiReducer.changeLog,
});

const mapDispatchToProps = {
  dispatchGetChangelog: getChangeLog,
};

WikiChangeLogPage.propTypes = {
  dispatchGetChangelog: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  changeLog: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(WikiChangeLogPage);
