import React from 'react';
import PropTypes from 'prop-types';
import {
  VictoryPie, VictoryChart, VictoryContainer, VictoryAxis,
} from 'victory';
import './donut-chart.scss';


const styles = {
  victoryAxis: {
    tickLabels: { display: 'none' },
    axis: { stroke: 'none' },
  },
};

const DonutChart = ({ innerRadius, padAngle, padding, data, style, height, width, wrapperSize }) => (
  <svg className="donut-chart-wrapper" width={wrapperSize} height={wrapperSize}>
    <VictoryChart
      standalone={false}
      padding={padding}
      height={height}
      width={width}
      containerComponent={<VictoryContainer />}
    >
      <VictoryPie
        innerRadius={innerRadius}
        padAngle={padAngle}
        padding={padding}
        data={data}
        style={style}
      />
      <VictoryAxis style={styles.victoryAxis} />
    </VictoryChart>
  </svg>
);

DonutChart.propTypes = {
  innerRadius: PropTypes.number.isRequired,
  padAngle: PropTypes.number.isRequired,
  padding: PropTypes.number.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  style: PropTypes.shape({}).isRequired,
  height: PropTypes.number,
  width: PropTypes.number,
  wrapperSize: PropTypes.number,
};

DonutChart.defaultProps = {
  height: null,
  width: null,
  wrapperSize: null,
};

export default DonutChart;
