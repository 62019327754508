import React from 'react';
import PropTypes from 'prop-types';
import './bookmark-category-menu.scss';

const BookmarkCategoryMenu = ({ items, selectedCategory, setSelectedCategory }) => (
  <ul className="bookmark-category-menu-ul">
    {items.map((item, key) => {
      const listIndex = key + 1;
      return (
        <li
          className={selectedCategory === item.category.id ? 'bookmark-category-active' : 'bookmark-category-inactive'}
          onClick={() => setSelectedCategory(item.category.id)}
        >
          {`${listIndex}. ${item.category.title}`}
        </li>
      );
    })}
  </ul>
);

BookmarkCategoryMenu.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  selectedCategory: PropTypes.number,
  setSelectedCategory: PropTypes.func.isRequired,
};

BookmarkCategoryMenu.defaultProps = {
  items: [],
  selectedCategory: null,
};
export default BookmarkCategoryMenu;
