import { ReactComponent as DevertixLogo } from 'assets/images/devertix-logo.svg';
import { ReactComponent as Tech2FlowLogo } from 'assets/images/tech2flow-logo.svg';
import { ReactComponent as Tech2FlowIcon } from 'assets/images/tech2flow-icon.svg';

const brand = process.env.REACT_APP_BRAND;

const brandCssVariables = {
  devertix: {
    '--dodger-blue': '#b680ec',
    '--dodger-blue-light': '#b680ecd1',
    '--malibu': '#6c00d9',
    '--deep-sky-blue': '#b680ec',
  },
  tech2flow: {
    '--dodger-blue': '#3695FF',
    '--dodger-blue-light': '#3695FFD1',
    '--malibu': '#f0f7ff',
    '--deep-sky-blue': '#1FB9F5',
  },
};

export const currentBrandColors = brandCssVariables[brand];

const logos = {
  devertix: DevertixLogo,
  tech2flow: Tech2FlowLogo,
};
const icons = {
  devertix: DevertixLogo,
  tech2flow: Tech2FlowIcon,
};

export const CompanyLogo = logos[brand];
export const CompanyIcon = icons[brand];

export const getPageTitle = (page) => {
  const capitalizedBrandName = brand.charAt(0).toUpperCase() + brand.slice(1);

  return `${page} | ${capitalizedBrandName} Intranet`;
};
