import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Uppy from '@uppy/core';
import PersonalProfile from 'components/Profile/PersonalProfile';
import ProfileForm from 'components/Profile/ProfileForm';
import EducationModal from 'components/EducationModal/EducationModal';
import DependantsModal from 'components/DependantsModal/DependantsModal';
import PictureModal from 'components/PictureModal/PictureModal';
import EducationList from 'components/Profile/EducationList';
import DependantsList from 'components/Profile/DependantsList';
import SpinnerContainer from 'components/SpinnerContainer/SpinnerContainer';
import ExamList from 'components/Profile/ExamList';
import ExamPlanList from 'components/Profile/ExamPlanList';
import ExamModal from 'components/ExamModal/ExamModal';
import ExamPlanModal from 'components/ExamPlanModal/ExamPlanModal';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import CompleteExamPlanModal from 'components/CompleteExamPlanModal/CompleteExamPlanModal';
import {
  changeProfileInput, dependantsModalOpen, educationModalOpen,
  dateChange, saveProfileForm, addEducation, addDependant,
  getPersonalDetails, profilePictureChange,
  deleteExamModalOpen, deleteExamPlanModalOpen, completeExamPlanModalOpen, examModalOpen, examPlanModalOpen,
  deleteExam, addExam, addExamPlan, deleteExamPlan, completeExamPlan,
} from 'actions/profileActions';
import {
  validateEmail, validatePhone, validateBankAccount,
  validateTajNumber, validateTaxNumber, openNotificationWithIcon,
} from 'services/utils';
import './profile.scss';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import { getPageTitle } from '../../utils/branding';

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      picture: null,
      pictureModalOpen: false,
      isPhoneValid: true,
      isEmailValid: true,
      isBankAccountValid: true,
      isTajNumberValid: true,
      isTaxNumberValid: true,
    };

    this.uppy = new Uppy({
      meta: { type: 'avatar' },
      restrictions: { maxNumberOfFiles: 1 },
      autoProceed: true,
    })
      .on('file-added', (file) => {
        this.setState({
          picture: file.data,
        });
      })
      .on('file-removed', () => {
        this.setState({
          picture: null,
        });
      });
  }

  componentDidMount() {
    document.title = getPageTitle('Profile');
    const { dispatchGetPersonalDetails } = this.props;
    dispatchGetPersonalDetails();
  }

  handleChange = (e, phoneName, phoneValue) => {
    const { dispatchChangeProfileInput } = this.props;
    if (e) {
      const { name, value } = e.target;
      dispatchChangeProfileInput(name, value);
    } else {
      dispatchChangeProfileInput(phoneName, phoneValue);
    }
  }

  handleSaveChanges = () => {
    const { dispatchSaveProfileForm, profileReducer: { inputs: { phone, email, bankAccount } } } = this.props;
    if (validatePhone(phone) && validateEmail(email) && validateBankAccount(bankAccount)) {
      dispatchSaveProfileForm();
    } else {
      openNotificationWithIcon('warning', 'wrong format', 'data has not been sent');
    }
  }

  handleAddDependant = (node) => {
    const {
      dispatchDependantsModalOpen, dispatchAddDependant,
      profileReducer: { inputs: { childName, childTaxNumber, childTajNumber, childsMotherName, childsBirthday } },
    } = this.props;
    if (validateTajNumber(childTajNumber) && validateTaxNumber(childTaxNumber)) {
      dispatchAddDependant(childName, childTaxNumber, childTajNumber, childsMotherName, childsBirthday, node);
      dispatchDependantsModalOpen(false, {});
    } else {
      openNotificationWithIcon('warning', 'wrong format', 'data has not been sent');
    }
  }

  handleStateChange = (field, value) => {
    this.setState({
      [field]: value,
    });
  }

  addProfilePicture = () => {
    const { picture } = this.state;
    const { dispatchProfilePictureChange } = this.props;
    const reader = new FileReader();
    reader.readAsArrayBuffer(picture);
    reader.onload = () => {
      dispatchProfilePictureChange(reader.result, picture.name);
    };
  }

  render() {
    const {
      pictureModalOpen, picture,
      isPhoneValid, isEmailValid,
      isTajNumberValid, isTaxNumberValid,
      isBankAccountValid,
    } = this.state;
    const {
      isMobile, avatar,
      dispatchDateChange, dispatchDependantsModalOpen,
      dispatchEducationModalOpen, dispatchAddEducation,
      dispatchDeleteExamModalOpen, dispatchDeleteExamPlanModalOpen, dispatchCompleteExamPlanModalOpen, dispatchExamModalOpen,
      dispatchExamPlanModalOpen, dispatchDeleteExamPlan, dispatchCompleteExamPlan, dispatchDeleteExam, dispatchAddExam,
      dispatchAddExamPlan, dispatchChangeProfileInput,
      profileReducer: {
        inputs: {
          permanentAddress, mailingAddress,
          bankAccount, email, skype, phone,
          fieldOfStudy, instituteName, degree,
          serialNumber, childsBirthday, childName,
          childTajNumber, childTaxNumber, childsMotherName,
          maritalStatus, slack, nodeId,
          name, acquiredAt, expiresAt, deadlineDate,
        },
        maritalStatuses, dependants, educations,
        exams, examPlans,
        personalDetails, dependantsModal, educationModal, deleteExamModal, deleteExamPlanModal, completeExamPlanModal,
        examModal, examPlanModal,
        degrees, isLoading,
      },
    } = this.props;
    return (
      <div className="profile-page-container">
        {
          personalDetails
            ? (
              <>
                <PersonalProfile
                  personalDetails={personalDetails}
                  isMobile={isMobile}
                  profilePicture={avatar ? avatar.url : null}
                  handlePictureChange={(event) => { this.setState({ pictureModalOpen: true }); event.stopPropagation(); }}
                />
                <PictureModal
                  showModal={pictureModalOpen}
                  picture={picture}
                  uppy={this.uppy || null}
                  handleClose={() => { this.setState({ pictureModalOpen: false }); }}
                  handleSubmit={this.addProfilePicture}
                />
                <div className="profile-changable-data">
                  <ProfileForm
                    permanentAddress={permanentAddress}
                    mailingAddress={mailingAddress}
                    skype={skype}
                    slack={slack}
                    email={email}
                    phone={phone}
                    maritalStatus={maritalStatus}
                    bankAccount={bankAccount}
                    maritalStatuses={maritalStatuses}
                    isPhoneValid={isPhoneValid}
                    isEmailValid={isEmailValid}
                    isBankAccountValid={isBankAccountValid}
                    saveChanges={this.handleSaveChanges}
                    handleChange={this.handleChange}
                    handleSelectChange={(value) => { dispatchChangeProfileInput('maritalStatus', value); }}
                    validatePhone={() => { this.handleStateChange('isPhoneValid', validatePhone(phone)); }}
                    validateEmail={() => { this.handleStateChange('isEmailValid', validateEmail(email)); }}
                    validateBankAccount={() => { this.handleStateChange('isBankAccountValid', validateBankAccount(bankAccount)); }}
                  />
                  <ExamList
                    exams={exams || []}
                    isMobile={isMobile}
                    onModalOpen={(data) => { dispatchExamModalOpen(true, data); }}
                    onDeleteModalOpen={(data) => { dispatchDeleteExamModalOpen(true, data); }}
                    isLoading={isLoading}
                  />
                  <ExamModal
                    handleSubmit={() => { dispatchAddExam(name, acquiredAt, expiresAt, nodeId); }}
                    onModalOpen={() => { dispatchExamModalOpen(true, {}); }}
                    onChange={this.handleChange}
                    handleClose={() => { dispatchExamModalOpen(false, {}); }}
                    name={name}
                    acquiredAt={acquiredAt}
                    expiresAt={expiresAt}
                    onDateChange={(date, dateString, fieldName) => { dispatchDateChange(fieldName, dateString); }}
                    showModal={examModal}
                    nodeId={nodeId}
                  />
                  <ConfirmationModal
                    modalTitle="DELETE EXAM"
                    description="Are you sure you want to delete this exam?"
                    subject={name}
                    submitButtonLabel="DELETE"
                    showModal={deleteExamModal}
                    handleClose={() => { dispatchDeleteExamModalOpen(false, {}); }}
                    handleSubmit={() => dispatchDeleteExam(nodeId)}
                    isDisabled={!nodeId}
                  />
                  <ExamPlanList
                    examPlans={examPlans || []}
                    isMobile={isMobile}
                    onModalOpen={(data) => { dispatchExamPlanModalOpen(true, data); }}
                    onDeleteModalOpen={(data) => { dispatchDeleteExamPlanModalOpen(true, data); }}
                    onSubmitModalOpen={(data) => dispatchCompleteExamPlanModalOpen(true, data)}
                    isLoading={isLoading}
                  />
                  <ExamPlanModal
                    handleSubmit={() => { dispatchAddExamPlan(name, deadlineDate, nodeId); }}
                    onModalOpen={() => { dispatchExamPlanModalOpen(true, {}); }}
                    onChange={this.handleChange}
                    handleClose={() => { dispatchExamPlanModalOpen(false, {}); }}
                    name={name}
                    deadlineDate={deadlineDate}
                    onDateChange={(date, dateString, fieldName) => { dispatchDateChange(fieldName, dateString); }}
                    showModal={examPlanModal}
                    nodeId={nodeId}
                  />
                  <ConfirmationModal
                    modalTitle="DELETE EXAM PLAN"
                    description="Are you sure you want to delete this exam plan?"
                    subject={name}
                    submitButtonLabel="DELETE"
                    showModal={deleteExamPlanModal}
                    handleClose={() => { dispatchDeleteExamPlanModalOpen(false, {}); }}
                    handleSubmit={() => dispatchDeleteExamPlan(nodeId)}
                    isDisabled={!nodeId}
                  />
                  <CompleteExamPlanModal
                    onSubmit={(data) => dispatchCompleteExamPlan(data)}
                    handleClose={() => dispatchCompleteExamPlanModalOpen(false, {})}
                    name={name}
                    showModal={completeExamPlanModal}
                    nodeId={nodeId}
                  />
                  <EducationList
                    educations={educations || []}
                    isMobile={isMobile}
                    onModalOpen={(data) => { dispatchEducationModalOpen(true, data); }}
                    isLoading={isLoading}
                  />
                  <EducationModal
                    instituteName={instituteName}
                    fieldOfStudy={fieldOfStudy}
                    degree={degree}
                    degrees={degrees}
                    serialNumber={serialNumber}
                    showModal={educationModal}
                    nodeId={nodeId}
                    onChange={this.handleChange}
                    handleSelectChange={(value) => { dispatchChangeProfileInput('degree', value); }}
                    onModalOpen={() => { dispatchEducationModalOpen(true, {}); }}
                    handleClose={() => { dispatchEducationModalOpen(false, {}); }}
                    handleSubmit={() => { dispatchAddEducation(instituteName, fieldOfStudy, degree, serialNumber, nodeId); }}
                  />
                  <DependantsList
                    dependants={dependants || []}
                    isMobile={isMobile}
                    onModalOpen={(data) => { dispatchDependantsModalOpen(true, data); }}
                    isLoading={isLoading}
                  />
                  <DependantsModal
                    childName={childName}
                    childTaxNumber={childTaxNumber}
                    childTajNumber={childTajNumber}
                    childsMotherName={childsMotherName}
                    childsBirthday={childsBirthday}
                    showModal={dependantsModal}
                    nodeId={nodeId}
                    isTajNumberValid={isTajNumberValid}
                    isTaxNumberValid={isTaxNumberValid}
                    onChange={this.handleChange}
                    handleSubmit={(node) => { this.handleAddDependant(node); }}
                    onModalOpen={() => { dispatchDependantsModalOpen(true, {}); }}
                    handleClose={() => {
                      dispatchDependantsModalOpen(false, {});
                      this.handleStateChange('isTajNumberValid', validateTajNumber(''));
                      this.handleStateChange('isTaxNumberValid', validateTaxNumber(''));
                    }}
                    onDateChange={(date, dateString, fieldName) => { dispatchDateChange(fieldName, dateString); }}
                    validateTajNumber={() => { this.handleStateChange('isTajNumberValid', validateTajNumber(childTajNumber)); }}
                    validateTaxNumber={() => { this.handleStateChange('isTaxNumberValid', validateTaxNumber(childTaxNumber)); }}
                  />
                </div>
              </>
            ) : <SpinnerContainer className="profile-spinner-container" />
        }
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  isMobile: store.appReducer.isMobile,
  avatar: store.appReducer.user.avatar,
  profileReducer: store.profileReducer,
});

const mapDispatchToProps = {
  dispatchChangeProfileInput: changeProfileInput,
  dispatchDependantsModalOpen: dependantsModalOpen,
  dispatchEducationModalOpen: educationModalOpen,
  dispatchDeleteExamModalOpen: deleteExamModalOpen,
  dispatchDeleteExamPlanModalOpen: deleteExamPlanModalOpen,
  dispatchCompleteExamPlanModalOpen: completeExamPlanModalOpen,
  dispatchExamModalOpen: examModalOpen,
  dispatchExamPlanModalOpen: examPlanModalOpen,
  dispatchDateChange: dateChange,
  dispatchSaveProfileForm: saveProfileForm,
  dispatchAddEducation: addEducation,
  dispatchAddDependant: addDependant,
  dispatchDeleteExam: deleteExam,
  dispatchDeleteExamPlan: deleteExamPlan,
  dispatchCompleteExamPlan: completeExamPlan,
  dispatchAddExam: addExam,
  dispatchAddExamPlan: addExamPlan,
  dispatchGetPersonalDetails: getPersonalDetails,
  dispatchProfilePictureChange: profilePictureChange,
};

Profile.propTypes = {
  dispatchChangeProfileInput: PropTypes.func.isRequired,
  dispatchDependantsModalOpen: PropTypes.func.isRequired,
  dispatchEducationModalOpen: PropTypes.func.isRequired,
  dispatchDeleteExamModalOpen: PropTypes.func.isRequired,
  dispatchDeleteExamPlanModalOpen: PropTypes.func.isRequired,
  dispatchCompleteExamPlanModalOpen: PropTypes.func.isRequired,
  dispatchExamModalOpen: PropTypes.func.isRequired,
  dispatchExamPlanModalOpen: PropTypes.func.isRequired,
  dispatchDateChange: PropTypes.func.isRequired,
  dispatchSaveProfileForm: PropTypes.func.isRequired,
  dispatchAddEducation: PropTypes.func.isRequired,
  dispatchAddDependant: PropTypes.func.isRequired,
  dispatchDeleteExam: PropTypes.func.isRequired,
  dispatchDeleteExamPlan: PropTypes.func.isRequired,
  dispatchCompleteExamPlan: PropTypes.func.isRequired,
  dispatchAddExam: PropTypes.func.isRequired,
  dispatchAddExamPlan: PropTypes.func.isRequired,
  dispatchGetPersonalDetails: PropTypes.func.isRequired,
  dispatchProfilePictureChange: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  avatar: PropTypes.shape({
    url: PropTypes.string,
  }),
  profileReducer: PropTypes.shape({
    inputs: PropTypes.shape({
      permanentAddress: PropTypes.string,
      mailingAddress: PropTypes.string,
      email: PropTypes.string,
      maritalStatus: PropTypes.string,
      skype: PropTypes.string,
      slack: PropTypes.string,
      phone: PropTypes.string,
      bankAccount: PropTypes.string,
      personalDataComment: PropTypes.string,
      fieldOfStudy: PropTypes.string,
      instituteName: PropTypes.string,
      degree: PropTypes.string,
      serialNumber: PropTypes.string,
      childsBirthday: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
      childName: PropTypes.string,
      childTajNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      childTaxNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      childsMotherName: PropTypes.string,
      name: PropTypes.string,
      acquiredAt: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
      expiresAt: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
      deadlineDate: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
      nodeId: PropTypes.number,
    }).isRequired,
    maritalStatuses: PropTypes.arrayOf(PropTypes.shape({})),
    degrees: PropTypes.arrayOf(PropTypes.shape({})),
    dependants: PropTypes.arrayOf(PropTypes.shape({})),
    educations: PropTypes.arrayOf(PropTypes.shape({})),
    exams: PropTypes.arrayOf(PropTypes.shape({})),
    examPlans: PropTypes.arrayOf(PropTypes.shape({})),
    personalDetails: PropTypes.shape({}),
    dependantsModal: PropTypes.bool.isRequired,
    educationModal: PropTypes.bool.isRequired,
    deleteExamModal: PropTypes.bool.isRequired,
    deleteExamPlanModal: PropTypes.bool.isRequired,
    completeExamPlanModal: PropTypes.bool.isRequired,
    examModal: PropTypes.bool.isRequired,
    examPlanModal: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
  }).isRequired,
};

Profile.defaultProps = {
  avatar: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
