import React from 'react';
import 'material-icons/iconfont/outlined.css';
import PropTypes from 'prop-types';

const ShareOutlined = ({ fontSize }) => {
  const style = {
    fontSize,
  };
  return (
    <span style={style} className="material-icons-outlined">share</span>
  );
};

ShareOutlined.propTypes = {
  fontSize: PropTypes.string.isRequired,
};

export default ShareOutlined;
