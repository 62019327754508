import {
  WORKTIME_GET_WORKTIME_REQUESTED,
  WORKTIME_GET_WORKTIMELIST_REQUESTED,
  WORKTIME_EMPTY_WORKTIMELIST,
} from 'consts/actionTypes';

export const getWorktime = () => ({
  type: WORKTIME_GET_WORKTIME_REQUESTED,
});

export const getWorktimeList = (date) => ({
  type: WORKTIME_GET_WORKTIMELIST_REQUESTED,
  payload: {
    date,
  },
});

export const emptyWorktimeList = (date) => ({
  type: WORKTIME_EMPTY_WORKTIMELIST,
  payload: {
    date,
  },
});
