const initialState = {
  isLoading: false,
  hasLoadingError: false,
  errorMessage: '',
  dailyWorktimeList: null,
  tasks: null,
  pmLoggedTime: null,
  firstWeekData: null,
  firstWeekTitle: null,
  secondWeekData: null,
  secondWeekTitle: null,
  expectedHours: null,
  myWorkTimeFormatted: null,
  todayFormatted: null,
  myWorkTimeInHours: null,
  monthlyWorktime: null,
  worktimeList: null,
  date: null,
  pmWorkHours: null,
};

export default initialState;
