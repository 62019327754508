import React from 'react';
import PropTypes from 'prop-types';
import { AbsenceDots } from './AbsenceDots';

const calculateHolidayPercentage = (maxHolidays, takenHolidays) => {
  if (maxHolidays === 0) {
    return 0;
  }
  const percentage = (takenHolidays / maxHolidays) * 100;
  return Math.ceil(percentage);
};

const translations = {
  1: {
    percentOfAll: 25,
    period: 'Jan - Mar',
    text: 'first',
  },
  2: {
    percentOfAll: 50,
    period: 'Apr - Sep',
    text: 'second and third',
  },
  3: {
    percentOfAll: 25,
    period: 'Oct - Dec',
    text: 'last',
  },
};

export const AbsenceSummaryBlock = ({ expectedDaysCount, takenDaysCount, third }) => (
  <div className="absence-summary-block">
    <p className="text-uppercase">
      {translations[third].text}
      {' '}
      quarter of the year (
      {translations[third].percentOfAll}
      %)
    </p>
    <p className="text-uppercase">
        (
      {translations[third].period}
        )
    </p>
    <p className="absence-summary-row">
      <div>
        <span
        // eslint-disable-next-line no-nested-ternary
          className={expectedDaysCount > takenDaysCount
            ? 'absence-row-text-red'
            : expectedDaysCount === takenDaysCount
              ? 'absence-row-text-green'
              : 'absence-row-text-yellow'}
        >
          {' '}
          {expectedDaysCount - takenDaysCount > 0
            ? expectedDaysCount - takenDaysCount
            : takenDaysCount}
          {' '}
              day(s)
        </span>
        {' '}
        {expectedDaysCount - takenDaysCount > 0 ? 'missing' : 'taken'}
        {' '}
      </div>
      <AbsenceDots
        greenCount={takenDaysCount}
        redCount={expectedDaysCount - takenDaysCount}
        yellowCount={takenDaysCount - expectedDaysCount > 0
          ? takenDaysCount - expectedDaysCount
          : 0}
      />
      <div>
        {' '}
          You have taken out
        {' '}
        {takenDaysCount}
        {' '}
          day(s)
        {' '}
        <span className="absence-row-text-gray">
            (
          {calculateHolidayPercentage(expectedDaysCount, takenDaysCount)}
              %)
          {' '}
        </span>
          for the
        {' '}
        {translations[third].text}
        {' '}
        quarter of the year.
      </div>
    </p>
  </div>
);

AbsenceSummaryBlock.propTypes = PropTypes.shape({
  expectedDaysCount: PropTypes.number.isRequired,
  takenDaysCount: PropTypes.number.isRequired,
  third: PropTypes.number.isRequired,
}).isRequired;
