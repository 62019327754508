import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { restoreUserByToken } from 'actions/userActions';


const requireAuthentication = (Component) => {
  class AuthenticatedComponent extends React.Component {
    componentDidMount() {
      this.checkAuth();
    }

    checkAuth() {
      const { user, history } = this.props;
      if (!user.isAuthenticated) {
        // RESTORE USER
        const token = localStorage.getItem('accessToken');
        const refreshToken = localStorage.getItem('refreshToken');
        const { location } = this.props;
        const next = location.pathname + location.search;
        history.push('/login');
        if (token || refreshToken) {
          const { dispatchRestoreUserByToken } = this.props;
          dispatchRestoreUserByToken(next);
        } else {
          history.push('/login');
        }
      }
    }

    render() {
      const { user } = this.props;
      return user.isAuthenticated
        ? <Component {...this.props} />
        : null;
    }
  }

  AuthenticatedComponent.propTypes = {
    user: PropTypes.shape().isRequired,
    location: PropTypes.shape().isRequired,
    history: PropTypes.shape().isRequired,
    dispatchRestoreUserByToken: PropTypes.func.isRequired,
  };

  const mapActionCreators = {
    dispatchRestoreUserByToken: restoreUserByToken,
  };

  const mapStateToProps = (state) => ({
    user: state.userReducer,
  });

  return withRouter(connect(mapStateToProps, mapActionCreators)(AuthenticatedComponent));
};

export default requireAuthentication;
