import React from 'react';
import PropTypes from 'prop-types';
import ChartTitle from 'components/Charts/ChartTitle/ChartTitle';
import WorktimeChart from 'components/Charts/WorktimeChart/WorktimeChart';
import WorktimeChartDateLabel from 'components/Charts/WorktimeChart/WorktimeChartDateLabel';
import WorktimeChartBottomLabel from 'components/Charts/WorktimeChart/WorktimeChartBottomLabel';
import SpinnerContainer from 'components/SpinnerContainer/SpinnerContainer';
import './worktime-chart.scss';


const WorktimeChartContainer = ({ firstWeekData, secondWeekData, firstWeekTitle, secondWeekTitle }) => {
  if (firstWeekData === null || secondWeekData === null) {
    return (
      <div className="worktime-chart-main-container">
        <SpinnerContainer className="barchart-spinner-container" />
      </div>
    );
  }
  return (
    <div className="worktime-chart-main-container">
      <>
        <ChartTitle
          chartTitle="office time and pm workhours"
          iconName="IosClock"
        />
        <div className="worktime-chart-fadein-container">
          <div className="worktime-chart-barchart-container">
            <div className="worktime-chart-weekly-barchart-container">
              <WorktimeChartDateLabel weekTitle={firstWeekTitle} />
              <WorktimeChart
                data={firstWeekData}
                height={200}
                width={240}
                padding={30}
                margin={0}
                offset={18}
                barWidth={14}
              />
              <WorktimeChartBottomLabel weekData={firstWeekData} />
            </div>
            <div className="worktime-chart-weekly-barchart-container">
              <WorktimeChartDateLabel weekTitle={secondWeekTitle} />
              <WorktimeChart
                data={secondWeekData}
                height={200}
                width={240}
                padding={30}
                margin={0}
                offset={18}
                barWidth={14}
              />
              <WorktimeChartBottomLabel weekData={secondWeekData} />
            </div>
          </div>
          <div className="worktime-chart-barchart-container-wide">
            <div className="worktime-chart-weekly-barchart-container-wide">
              <WorktimeChartDateLabel weekTitle={firstWeekTitle} />
              <WorktimeChart
                data={firstWeekData}
                height={200}
                width={320}
                padding={30}
                margin={0}
                offset={24}
                barWidth={20}
              />
              <WorktimeChartBottomLabel weekData={firstWeekData} />
            </div>
            <div className="worktime-chart-weekly-barchart-container-wide">
              <WorktimeChartDateLabel weekTitle={secondWeekTitle} />
              <WorktimeChart
                data={secondWeekData}
                height={200}
                width={320}
                padding={30}
                margin={0}
                offset={24}
                barWidth={20}
              />
              <WorktimeChartBottomLabel weekData={secondWeekData} />
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

WorktimeChartContainer.propTypes = {
  firstWeekData: PropTypes.arrayOf(PropTypes.shape({})),
  firstWeekTitle: PropTypes.string,
  secondWeekData: PropTypes.arrayOf(PropTypes.shape({})),
  secondWeekTitle: PropTypes.string,
};

WorktimeChartContainer.defaultProps = {
  firstWeekData: [],
  firstWeekTitle: null,
  secondWeekData: null,
  secondWeekTitle: null,
};

export default WorktimeChartContainer;
