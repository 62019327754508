import { takeEvery, call, put, select } from 'redux-saga/effects';
import _ from 'lodash';
import {
  WORKTIME_GET_WORKTIME_REQUESTED,
  WORKTIME_GET_WORKTIME_SUCCEEDED,
  WORKTIME_GET_WORKTIME_FAILED,
  WORKTIME_GET_WORKTIMELIST_REQUESTED,
  WORKTIME_GET_WORKTIMELIST_SUCCEEDED,
  WORKTIME_GET_WORKTIMELIST_FAILED,
} from 'consts/actionTypes';
import { host } from 'services/config';
import * as API from 'services/api';

export const getUserReducer = (store) => store.userReducer;
export const getTasks = (userId, page) => API.getData(`${host}/api/user/${userId}/redmine_open_issues?_format=json&page=${page}`);

export const splitAndJoinData = (data) => (data ? data.split(':').join('.') : 0);

export const formatData = (data) => {
  data.forEach((e) => {
    e.work_time = e.work_time ? Number(e.work_time) : 0;
    e.pm_logged_time = e.pm_logged_time ? Number(e.pm_logged_time) : 0;
  });
  return data;
};

export const checkTimeCorrect = (days) => days.map((day) => {
  const requiredLoggedTime = day.is_home_office ? day.pm_required_logged_time_home_office : day.pm_required_logged_time;
  return ({
    ...day,
    isTimeCorrect: day.pm_required_logged_time && (requiredLoggedTime <= splitAndJoinData(day.pm_logged_time_formatted)),
    isOfficeTimeCorrect: day.is_enough,
  });
});

export const setChartTitle = (data, firstIndex, lastIndex) => {
  if (data) {
    const dates = Object.keys(data);
    const firstDay = dates[firstIndex] ? dates[firstIndex] : '-';
    const lastDay = dates[lastIndex] ? dates[lastIndex] : '-';
    return `${firstDay} - ${lastDay}`;
  }
  return '-';
};

export function* getWorktime() {
  try {
    const userReducer = yield select(getUserReducer);
    const { userId } = userReducer;
    const dailyWorktimeList = yield call(API.getData, `${host}/api/user/${userId}/worktime?_format=json`);
    const tasks = yield call(getTasks, userId, 0);
    const pmLoggedTime = yield call(API.getData, `${host}/api/user/${userId}/redmine_time_statistics?_format=json`);
    const pmWorkHours = yield call(API.getData, `${host}/api/user/${userId}/logged_time_statistics`);
    const loggedWorktimesByDay = dailyWorktimeList.table;
    const loggedPmWorktimesByDay = pmLoggedTime.logged_time_by_day;
    const loggedWorktimes = _.merge(loggedWorktimesByDay, loggedPmWorktimesByDay);
    const formattedLoggedWorktimes = Object.values(loggedWorktimes);
    const firstWeekData = checkTimeCorrect(formatData(formattedLoggedWorktimes.slice(0, 5)));
    const secondWeekData = checkTimeCorrect(formatData(formattedLoggedWorktimes.slice(5, 10)));
    const firstWeekTitle = setChartTitle(loggedWorktimes, 0, 4);
    const secondWeekTitle = setChartTitle(loggedWorktimes, 5, 9);
    const expectedHours = dailyWorktimeList.expected_worktime.total_worktime;
    const myWorkTimeFormatted = dailyWorktimeList.current_total_work_time_formatted;
    const todayFormatted = dailyWorktimeList.current_work_time_formatted;
    const myWorkTimeInHours = dailyWorktimeList.current_total_work_time / 3600;
    const monthlyWorktime = dailyWorktimeList.expected_worktime.monthly_worktime;

    yield put({
      type: WORKTIME_GET_WORKTIME_SUCCEEDED,
      payload: {
        pmWorkHours,
        dailyWorktimeList,
        tasks,
        pmLoggedTime,
        firstWeekData,
        firstWeekTitle,
        secondWeekData,
        secondWeekTitle,
        expectedHours,
        myWorkTimeFormatted,
        todayFormatted,
        myWorkTimeInHours,
        monthlyWorktime,
      },
    });
  } catch (error) {
    yield put({
      type: WORKTIME_GET_WORKTIME_FAILED,
      payload: {
        errorMessage: error.message,
      },
    });
  }
}

export function* getWorktimeList(action) {
  try {
    const userReducer = yield select(getUserReducer);
    const { userId } = userReducer;
    const { date } = action.payload;
    const worktimeList = yield call(API.getData, `${host}/api/user/${userId}/list/attendance_event/${date}?_format=json`);
    yield put({
      type: WORKTIME_GET_WORKTIMELIST_SUCCEEDED,
      payload: {
        worktimeList,
        date,
      },
    });
  } catch (error) {
    yield put({
      type: WORKTIME_GET_WORKTIMELIST_FAILED,
      payload: {
        errorMessage: error.message,
      },
    });
  }
}

export const worktimeSagas = [
  takeEvery(WORKTIME_GET_WORKTIME_REQUESTED, getWorktime),
  takeEvery(WORKTIME_GET_WORKTIMELIST_REQUESTED, getWorktimeList),
];
