import React, { useEffect } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import LanguageSelector from 'components/Wiki/LanguageSelector';
import './wiki-details-content.scss';

/* eslint-disable */

const WikiDetailsContent = ({ selectedTopic, currentLang, onSelectLanguage, selectedLang }) => {
  useEffect(() => {
    window.scrollTo(0,0);
  }, [])
  const currentTopic = selectedTopic[currentLang];
  const status = currentTopic.isOutdated ? 'outdated' : 'active';
  return (
    <div className="wiki-details-content-container">
      <div className="wiki-details-content-header">
        <div className="wiki-details-content-header-title-container">
          <div className="wiki-details-content-header-title">
            {currentTopic.title}
          </div>
          <div className="wiki-details-content-header-language-selector">
            <LanguageSelector lang="eng" onSelectLanguage={onSelectLanguage} current={currentLang} topic={selectedTopic} selectedLang={selectedLang} />
            <LanguageSelector lang="hun" onSelectLanguage={onSelectLanguage} current={currentLang} topic={selectedTopic} selectedLang={selectedLang} />
          </div>
        </div>
        <div className="wiki-details-content-header-tags">
          {selectedTopic.tags.map((tag) => (
            <div
              key={tag.id}
              className="wiki-details-content-header-tag"
              role="button"
              tabIndex={0}
              onKeyPress={() => { }}
            >
              {tag[currentLang]}
            </div>
          ))}
        </div>
        <div className="wiki-details-content-header-last-edit">
          {moment(currentTopic.lastEdit).format('DD MMM YYYY')}
        </div>
        <div className={`wiki-details-content-header-status ${status}`}>
          {status}
        </div>
      </div>
      <div
        tabIndex={0}
        role="button"
        className="wiki-detatils-content"
        dangerouslySetInnerHTML={{ __html: currentTopic.content }}
        onClick={(e) => {
          e.preventDefault();
          if (e.target.tagName === 'A') {
            window.open(e.target.href);
          }
        }}
      />
    </div>
  );
};

WikiDetailsContent.propTypes = {
  selectedTopic: PropTypes.shape({
    eng: PropTypes.shape({}),
    hun: PropTypes.shape({}),
    tags: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  currentLang: PropTypes.string.isRequired,
  selectedLang: PropTypes.string.isRequired,
  onSelectLanguage: PropTypes.func.isRequired,
};

export default WikiDetailsContent;
