import {
  EVENTS_GET_EVENTS_REQUESTED,
  EVENTS_GET_EVENTS_SUCCEEDED,
  EVENTS_GET_EVENTS_FAILED,
  USER_LOGOUT_SUCCEEDED,
} from 'consts/actionTypes';
import initialState from 'reducers/events/initialState';

const worktimeReducer = (state = initialState, action) => {
  switch (action.type) {
    case EVENTS_GET_EVENTS_REQUESTED:
      return {
        ...state,
        isLoading: true,
      };

    case EVENTS_GET_EVENTS_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        events: action.payload.events,
        hasLoadingError: false,
        errorMessage: '',
      };

    case EVENTS_GET_EVENTS_FAILED:
      return {
        ...state,
        isLoading: false,
        hasLoadingError: true,
        errorMessage: action.payload,
      };
    case USER_LOGOUT_SUCCEEDED:
      return initialState;

    default:
      return state;
  }
};

export default worktimeReducer;
