import React from 'react';
import PropTypes from 'prop-types';
import { Avatar, Dropdown, Menu } from 'antd';
import IosArrowDown from 'react-ionicons/lib/IosArrowDown';
import { history } from 'services/config';
import './header-profile.scss';


const avatarStyles = {
  marginRight: '12px',
};

export const HeaderProfile = ({ user, handleLogout, handleProfile, isMobile }) => (
  <div className="header-profile-container">
    <Dropdown
      overlay={(
        <Menu>
          <Menu.Item key="0">
            <div
              className="header-profile-button"
              role="button"
              tabIndex={0}
              type="button"
              onClick={() => { handleProfile({ key: '/profile', item: { props: {} } }); history.push('/profile'); }}
              onKeyDown={() => { }}
            >
              Profile
            </div>
          </Menu.Item>
          <Menu.Item key="1">
            <div
              className="header-profile-button"
              role="button"
              tabIndex={0}
              type="button"
              onClick={handleLogout}
              onKeyDown={() => { }}
            >
              Logout
            </div>
          </Menu.Item>
        </Menu>
      )}
      trigger={['click']}
    >
      <div className="ant-dropdown-link">
        <Avatar
          style={avatarStyles}
          size="32"
          src={user.avatar.url}
        />
        {
          isMobile
            ? null
            : (
              <>
                {user.name}
                <IosArrowDown fontSize="15px" style={{ marginLeft: '3px' }} />
              </>
            )
        }
      </div>
    </Dropdown>
  </div>
);

HeaderProfile.propTypes = {
  user: PropTypes.shape().isRequired,
  handleLogout: PropTypes.func.isRequired,
  handleProfile: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

export default HeaderProfile;
