import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Spin } from 'antd';
import { Link } from 'react-router-dom';
import MdFiling from 'react-ionicons/lib/MdFiling';
import WikiSearchTile from 'components/Wiki/WikiSearchTile';
import WikiSearchResultHeader from 'components/Wiki/WikiSearchResultHeader';
import WikiSearchResultTile from 'components/Wiki/WikiSearchResultTile';
import { getTags } from 'actions/wikiActions';
import './wiki-search.scss';


class WikiSearch extends Component {
  constructor(props) {
    super(props);
    const { searchResults } = this.props;
    this.state = {
      displayedItems: (searchResults ? searchResults.filter((searchItem, index) => index < 5) : []), // eslint-disable-line
      numberOfDisplayedItems: 5,
      hasMore: true,
    };
  }

  componentDidMount() {
    const { dispatchGetTags } = this.props;
    dispatchGetTags();
  }

  componentDidUpdate(prevProps) {
    const { searchResults } = this.props;
    if (prevProps.searchResults !== searchResults) {
      this.setState({ // eslint-disable-line
        displayedItems: (searchResults ? searchResults.filter((searchItem, index) => index < 5) : []),
        hasMore: true,
        numberOfDisplayedItems: 5,
      });
    }
  }

  getNumberOfRemainingItems = (displayedItems, allResults) => {
    const numberOfAllItems = allResults ? allResults.length : 0; // eslint-disable-line
    const remainingItems = numberOfAllItems - displayedItems;
    return remainingItems;
  }

  getMoreData = () => {
    let { numberOfDisplayedItems } = this.state;
    const { searchResults } = this.props;
    if (this.getNumberOfRemainingItems(numberOfDisplayedItems, searchResults) > 0) {
      setTimeout(async () => {
        numberOfDisplayedItems += 5;
        await this.setState({
          numberOfDisplayedItems,
        });
        this.setState({
          displayedItems: searchResults.filter((searchItem, index) => index < numberOfDisplayedItems), // eslint-disable-line
        });
      }, 1000);
    } else {
      this.setState({
        hasMore: false,
      });
    }
  }

  render() {
    const { tagList, searchResults, searchLoading, noResultMessage } = this.props;
    const { displayedItems, hasMore, numberOfDisplayedItems } = this.state;
    return (
      <div className="wiki-search-results-container" style={{ paddingRight: '20px' }}>
        <div className="wiki-change-log-title-container">
          <Link to="/wiki">
            <MdFiling />
            WIKI
          </Link>
          / SEARCH RESULTS
        </div>
        <WikiSearchTile />
        <WikiSearchResultHeader tagList={tagList} />
        {
          !searchLoading
            ? (
              <InfiniteScroll
                dataLength={numberOfDisplayedItems}
                next={this.getMoreData}
                hasMore={hasMore}
                loader={
                  searchResults && searchResults.length > 0
                    ? (
                      <div className="wiki-search-loading-spinner" style={{ textAlign: 'center' }}>
                        <Spin size="small" />
                      </div>
                    ) : <div className="wiki-search-no-result">{noResultMessage}</div>
                }
                endMessage={
                  searchResults && searchResults.length > 0
                    ? (
                      <p style={{ textAlign: 'center' }}>
                        <b>Yay! You have seen it all</b>
                      </p>
                    ) : <div className="wiki-search-no-result">{noResultMessage}</div>
                }
              >
                {
                  displayedItems && displayedItems.map((searchItem) => (
                    <WikiSearchResultTile
                      title={searchItem.title}
                      key={searchItem.id}
                      lastEdit={searchItem.last_edit}
                      tags={searchItem.tags}
                      content={searchItem.content}
                      langcode={searchItem.langcode}
                      isTranslated={searchItem.translated}
                      id={searchItem.id}
                      categoryLabel={searchItem.categoryLabel}
                      categoryId={searchItem.categoryId}
                      tagList={tagList}
                    />
                  ))
                }
              </InfiniteScroll>
            ) : (
              <div className="wiki-search-loading-spinner" style={{ textAlign: 'center' }}>
                <Spin size="small" />
              </div>
            )
        }
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  tagList: store.wikiReducer.tags,
  searchResults: store.wikiReducer.searchResults,
  searchLoading: store.wikiReducer.searchLoading,
  noResultMessage: store.wikiReducer.noResultMessage,
});

const mapDispatchToProps = {
  dispatchGetTags: getTags,
};

WikiSearch.propTypes = {
  tagList: PropTypes.arrayOf(PropTypes.shape({})),
  searchResults: PropTypes.arrayOf(PropTypes.shape({})),
  dispatchGetTags: PropTypes.func.isRequired,
  searchLoading: PropTypes.bool.isRequired,
  noResultMessage: PropTypes.string.isRequired,
};

WikiSearch.defaultProps = {
  tagList: null,
  searchResults: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(WikiSearch);
