import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ProgressBarContainer from 'components/Charts/ProgressBar/ProgressBarContainer';
import { getWorktime } from 'actions/worktimeActions';
import getEvents from 'actions/eventActions';
import getBookmarks from 'actions/bookmarkActions';
import { logout } from 'actions/userActions';
import { selectFilter } from 'actions/tableActions';
import BreadCrumb from 'components/BreadCrumb/BreadCrumb';
import WorktimeChartContainer from 'components/Charts/WorktimeChart/WorktimeChartContainer';
import CurrentMonthChartContainer from 'components/Charts/CurrentMonthChart/CurrentMonthChartContainer';
import MagicTable from 'containers/MagicTable/MagicTable';
import ChartTitle from 'components/Charts/ChartTitle/ChartTitle';
import EventsContainer from 'components/Events/EventsContainer';
import 'routes/Dashboard/dashboard.scss';
import BookmarkContainer from 'components/Bookmarks/BookmarkContainer';
import { getAbsences } from 'actions/absenceActions';
import { getPageTitle } from '../../utils/branding';
import { AbsencesSummary } from '../../components/AbsencesSummary/AbsencesSummary';


class Dashboard extends Component {
  componentDidMount() {
    document.title = getPageTitle('Dashboard');
    const { dispatchGetAbsences, dispatchGetWorktime, userId, dispatchFilterTable, dispatchGetEvents, dispatchGetBookmarks } = this.props;
    if (userId) {
      dispatchGetWorktime();
      dispatchGetAbsences();
      dispatchGetEvents();
      dispatchFilterTable('dashboard', 'date', '');
      dispatchGetBookmarks();
    }
  }

  componentDidUpdate(prevProps) {
    const { dispatchGetWorktime, userId } = this.props;
    if (userId !== prevProps.userId) {
      dispatchGetWorktime();
    }
  }

  handleClick = () => {
    const { dispatchLogout } = this.props;
    dispatchLogout();
  }

  render() {
    const {
      pmWorkHours, hasLoadingErrorWorktime, isLoadingWorktime,
      dispatchGetWorktime, hasLoadingErrorEvents,
      myWorkTimeInHours, expectedHours, myWorkTimeFormatted,
      todayFormatted, monthlyWorktime, firstWeekData, firstWeekTitle,
      secondWeekData, secondWeekTitle, events, bookmarks, absencesSummary,
    } = this.props;

    return (
      <div className="page page-dashboard">
        <BreadCrumb breadcrumbText="Dashboard" />
        <div className="dashboard-chart-container">
          <div className="dashboard-upper-charts-container">
            <CurrentMonthChartContainer
              retryButton={() => dispatchGetWorktime()}
              isLoading={isLoadingWorktime}
              hasLoadingError={hasLoadingErrorWorktime}
              expectedHours={expectedHours}
              myWorkTimeFormatted={myWorkTimeFormatted}
              todayFormatted={todayFormatted}
              myWorkTimeInHours={myWorkTimeInHours}
              monthlyWorktime={monthlyWorktime}
            />
            <ProgressBarContainer
              retryButton={() => dispatchGetWorktime()}
              hasLoadingError={hasLoadingErrorWorktime}
              pmWorkHours={pmWorkHours}
              isLoading={isLoadingWorktime}
            />
          </div>
          <WorktimeChartContainer
            retryButton={() => dispatchGetWorktime()}
            firstWeekData={firstWeekData}
            firstWeekTitle={firstWeekTitle}
            secondWeekData={secondWeekData}
            secondWeekTitle={secondWeekTitle}
            isLoading={isLoadingWorktime}
            hasLoadingError={hasLoadingErrorWorktime}
          />
        </div>
        <div className="table-and-events-container">
          <div className="dashboard-table">
            <ChartTitle
              chartTitle="my tasks"
              iconName="IosListBox"
            />
            <div className="dashboard-table-container">
              <MagicTable
                table="dashboard"
                render={{
                  taskSpentTimeFormatted: ({ taskSpentTime, taskEstTime, taskSpentTimeFormatted, taskEstTimeFormatted }) => [
                    (<span key="1" className={taskSpentTime > taskEstTime ? 'time-warning' : ''}>{`${taskSpentTimeFormatted} h`}</span>),
                    (<span key="2"> / </span>),
                    (<span key="3">{`${taskEstTimeFormatted} h`}</span>),
                  ],
                }}
              />
            </div>
          </div>
          <div className="events-container">
            <EventsContainer hasLoadingError={hasLoadingErrorEvents} events={events} />
          </div>
        </div>

        <div className="holidays-container">
          <div className="dashboard-table">
            <ChartTitle
              chartTitle="holidays"
              iconName="CelebrationOutlined"
            />
            <div className="dashboard-table-container">
              <AbsencesSummary absencesSummary={absencesSummary} />
            </div>
          </div>
        </div>

        <div>
          <BookmarkContainer bookmarks={bookmarks} />
        </div>
      </div>
    );
  }
}

Dashboard.propTypes = {
  absencesSummary: PropTypes.shape({}),
  dispatchLogout: PropTypes.func.isRequired,
  dispatchGetWorktime: PropTypes.func.isRequired,
  dispatchGetEvents: PropTypes.func.isRequired,
  dispatchGetBookmarks: PropTypes.func.isRequired,
  dispatchGetAbsences: PropTypes.func.isRequired,
  dispatchFilterTable: PropTypes.func.isRequired,
  userId: PropTypes.number,
  pmWorkHours: PropTypes.shape({}),
  hasLoadingErrorWorktime: PropTypes.bool.isRequired,
  hasLoadingErrorEvents: PropTypes.bool.isRequired,
  isLoadingWorktime: PropTypes.bool.isRequired,
  expectedHours: PropTypes.number,
  myWorkTimeFormatted: PropTypes.string,
  todayFormatted: PropTypes.string,
  myWorkTimeInHours: PropTypes.number,
  monthlyWorktime: PropTypes.number,
  firstWeekData: PropTypes.arrayOf(PropTypes.shape({})),
  firstWeekTitle: PropTypes.string,
  secondWeekData: PropTypes.arrayOf(PropTypes.shape({})),
  secondWeekTitle: PropTypes.string,
  events: PropTypes.arrayOf(PropTypes.shape({})),
  bookmarks: PropTypes.arrayOf(PropTypes.shape({})),
};

Dashboard.defaultProps = {
  absencesSummary: null,
  userId: null,
  pmWorkHours: null,
  expectedHours: null,
  myWorkTimeFormatted: null,
  todayFormatted: null,
  myWorkTimeInHours: null,
  monthlyWorktime: null,
  firstWeekData: [],
  firstWeekTitle: null,
  secondWeekData: [],
  secondWeekTitle: null,
  events: null,
  bookmarks: [],
};

const mapStateToProps = (store) => ({
  userId: store.userReducer.userId,
  absencesSummary: store.absenceReducer.summary,
  pmLoggedTime: store.worktimeReducer.pmLoggedTime,
  dailyWorktimeList: store.worktimeReducer.dailyWorktimeList,
  hasLoadingErrorWorktime: store.worktimeReducer.hasLoadingError,
  hasLoadingErrorEvents: store.eventsReducer.hasLoadingError,
  isLoadingWorktime: store.worktimeReducer.isLoading,
  isLoadingEvents: store.eventsReducer.isLoading,
  expectedHours: store.worktimeReducer.expectedHours,
  myWorkTimeFormatted: store.worktimeReducer.myWorkTimeFormatted,
  todayFormatted: store.worktimeReducer.todayFormatted,
  myWorkTimeInHours: store.worktimeReducer.myWorkTimeInHours,
  monthlyWorktime: store.worktimeReducer.monthlyWorktime,
  firstWeekData: store.worktimeReducer.firstWeekData,
  firstWeekTitle: store.worktimeReducer.firstWeekTitle,
  secondWeekTitle: store.worktimeReducer.secondWeekTitle,
  secondWeekData: store.worktimeReducer.secondWeekData,
  pmWorkHours: store.worktimeReducer.pmWorkHours,
  events: store.eventsReducer.events,
  bookmarks: store.bookmarksReducer.bookmarks,
});

const mapDispatchToProps = {
  dispatchLogout: logout,
  dispatchGetWorktime: getWorktime,
  dispatchFilterTable: selectFilter,
  dispatchGetEvents: getEvents,
  dispatchGetBookmarks: getBookmarks,
  dispatchGetAbsences: getAbsences,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
