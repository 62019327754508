const statusColors = {
  away: '#515356',
  homeOffice: '#62E1E3',
  inOfficeWorking: '#67E49C',
  inOfficeNotWorking: '#67E49C',
  workingFromHome: '#71B6F9',
  sickLeave: '#F08887',
  workingAtClient: '#F3B656',
  onHoliday: '#262d37',
};

const camelize = (string) => {
  const withDeletedHyphens = string.replace('-', '');
  return withDeletedHyphens.replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => (
    index === 0 ? word.toLowerCase() : word.toUpperCase()
  )).replace(/\s+/g, '');
};

export default (status) => statusColors[camelize(status)];
