import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Input, Form, Radio, DatePicker } from 'antd';
import MdCreate from 'react-ionicons/lib/MdCreate';
import MdTrash from 'react-ionicons/lib/MdTrash';
import MdCheckmarkCircleOutline from 'react-ionicons/lib/MdCheckmarkCircleOutline';
import MdTime from 'react-ionicons/lib/MdTime';
import MdCloseCircle from 'react-ionicons/lib/MdCloseCircle';
import moment from 'moment';
import ChartTitle from 'components/Charts/ChartTitle/ChartTitle';
import IntraModal from 'components/Modal/Modal';
import MagicTable from 'containers/MagicTable/MagicTable';
import SpinnerContainer from 'components/SpinnerContainer/SpinnerContainer';
import { getAbsences, getAbsenceTypes, newAbsence, getAbsenceDetails, deleteAbsence } from 'actions/absenceActions';
import { selectFilter } from 'actions/tableActions';
import './absence-page.scss';
import { getPageTitle } from '../../utils/branding';


const getStatusIndicator = (status) => {
  switch (status) {
    case 'pending':
      return (<MdTime key="1" fontSize="18px" color="#f3b657" />);

    case 'published':
      return (<MdCheckmarkCircleOutline key="1" fontSize="18px" color="#67e49c" />);

    default:
      return (<MdCloseCircle key="1" fontSize="18px" color="#f08887" />);
  }
};

class AbsencePage extends Component {
  state = {
    showModal: false,
    id: null,
  }

  componentDidMount() {
    document.title = getPageTitle('Absence');
    const { dispatchGetAbsences, dispatchGetAbsenceTypes, dispatchFilterTable } = this.props;
    dispatchGetAbsences();
    dispatchGetAbsenceTypes();
    dispatchFilterTable('absence', 'date', '');
  }

  handleSubmit = () => {
    const { form: { getFieldsValue }, dispatchNewAbsence } = this.props;
    const { id } = this.state;
    dispatchNewAbsence(getFieldsValue(), () => this.setState({ showModal: false }), id);
  }

  handleEdit = (id) => {
    const { dispatchGetAbsenceDetails } = this.props;
    dispatchGetAbsenceDetails(id, this.editCallback);
    this.setState({ id });
  }

  editCallback = () => {
    const { form, absence } = this.props;
    const { id } = this.state;
    this.setState({ showModal: true });
    form.setFieldsValue({
      range: [
        moment(absence.details[id].intra_interval[0].value),
        moment(absence.details[id].intra_interval[0].end_value),
      ],
      text: absence.details[id].intra_body[0].value,
      radio: absence.details[id].intra_absence_type[0].target_id.toString(),
    });
  }

  render() {
    const { TextArea } = Input;
    const { RangePicker } = DatePicker;
    const { showModal, id } = this.state;
    const {
      absence: {
        summary: {
          intraApprovedHolidays,
          intraYearlyHolidays,
          intraYearlySickLeaves,
          intraTakenSickLeaves,
        },
        types,
        isLoading,
      },
      form: {
        getFieldDecorator,
        getFieldsValue,
        resetFields,
      },
      dispatchDeleteAbsence,
    } = this.props;

    const leftoverHolidays = intraYearlyHolidays - intraApprovedHolidays;
    const holidayBalls = [];
    for (let i = 0; i < intraYearlyHolidays; i += 1) {
      holidayBalls.push(leftoverHolidays > i);
    }

    const leftoverSickLeaves = intraYearlySickLeaves - intraTakenSickLeaves;
    const sickLeaveBalls = [];
    for (let i = 0; i < intraYearlySickLeaves; i += 1) {
      sickLeaveBalls.push(leftoverSickLeaves > i);
    }

    return (
      <div className="page page-absence">
        <div className="absence-page-title">Absence</div>
        <div className="absence-page-container">
          {
            !isLoading
              ? (
                <>
                  <ChartTitle
                    chartTitle="absence"
                    iconName="IosSunny"
                  />
                  <div className="absence-page-summary">
                    <div className="absence-page-summary-title">Holiday</div>
                    <div className="absence-page-summary-indicator">
                      <span className="absence-page-green absence-page-number">{`${leftoverHolidays} `}</span>
                      <span className="absence-page-green">{`day${leftoverHolidays === 1 ? '' : 's'} `}</span>
                      <span className="absence-page-text">remaining</span>
                    </div>
                    <div className="absence-page-summary-circles">
                      {
                        holidayBalls.map((ball, i) => (<div key={i.toString()} className={ball ? 'absence-page-ball active-green' : 'absence-page-ball'} />))
                      }
                    </div>
                    <div>
                      <span className="absence-page-grey absence-page-number">{`${intraApprovedHolidays} `}</span>
                      <span className="absence-page-grey">{`day${intraApprovedHolidays === '1' ? '' : 's'} `}</span>
                      <span className="absence-page-text">taken</span>
                    </div>
                  </div>
                  <div className="absence-page-summary">
                    <div className="absence-page-summary-title">Sick leave</div>
                    <div className="absence-page-summary-indicator">
                      <span className="absence-page-yellow absence-page-number">{`${leftoverSickLeaves} `}</span>
                      <span className="absence-page-yellow">{`day${leftoverSickLeaves === 1 ? '' : 's'} `}</span>
                      <span className="absence-page-text">remaining</span>
                    </div>
                    <div className="absence-page-summary-circles">
                      {
                        sickLeaveBalls.map((ball, i) => (<div key={i.toString()} className={ball ? 'absence-page-ball active-yellow' : 'absence-page-ball'} />))
                      }
                    </div>
                    <div>
                      <span className="absence-page-grey absence-page-number">{`${intraTakenSickLeaves} `}</span>
                      <span className="absence-page-grey">{`day${intraTakenSickLeaves === '1' ? '' : 's'} `}</span>
                      <span className="absence-page-text">taken</span>
                    </div>
                  </div>
                  <div className="absence-page-button">
                    <Button type="primary" shape="round" onClick={() => this.setState({ showModal: true })}>new absence request</Button>
                  </div>
                  <div className="absence-page-table-container">
                    <MagicTable
                      table="absence"
                      render={{
                        intraAbsenceDateStart: ({ intraAbsenceDateStart, intraAbsenceDateEnd }) => [
                          (<span key="1">{`${moment(intraAbsenceDateStart).format('YYYY-MM-DD')} - `}</span>),
                          (<span key="2">{moment(intraAbsenceDateEnd).format('YYYY-MM-DD')}</span>),
                        ],
                        id: ({ id: eId, moderationState, intraAbsenceDateStart }) => [
                          (
                            <span
                              className={`absence-page-action yellow${moderationState !== 'pending' ? ' disabled' : ''}`}
                              key="1"
                            >
                              <MdCreate fontSize="18px" color="#fff" onClick={() => (moderationState !== 'pending' ? () => { } : this.handleEdit(eId))} />
                            </span>
                          ),
                          (
                            <span
                              className={
                                `absence-page-action red${
                                  moment(intraAbsenceDateStart).isBefore(moment()) || moderationState === 'cancelled' || moderationState === 'denied'
                                    ? ' disabled'
                                    : ''
                                }`
                              }
                              key="2"
                            >
                              <MdTrash
                                fontSize="18px"
                                color="#fff"
                                onClick={
                                  () => (moment(intraAbsenceDateStart).isBefore(moment()) || moderationState === 'cancelled' || moderationState === 'denied'
                                    ? () => { }
                                    : dispatchDeleteAbsence(eId))
                                }
                              />
                            </span>
                          ),
                        ],
                        moderationState: ({ moderationState }) => [
                          getStatusIndicator(moderationState),
                        ],
                      }}
                    />
                  </div>
                </>
              )
              : (
                <div>
                  <SpinnerContainer className="absence-page-spinner-container" />
                </div>
              )
          }

        </div>
        <IntraModal
          title="NEW ABSENCE REQUEST"
          open={showModal}
          close={() => this.setState({ showModal: false, id: null })}
          submit={this.handleSubmit}
          okButton="SEND A REQUEST"
          cancelButton="CANCEL"
          shouldntClose
          disabled={getFieldsValue().radio === undefined || getFieldsValue().range === undefined || getFieldsValue().range.length !== 2}
          afterClose={() => resetFields()}
          className="absence-modal"
        >
          <Form labelAlign="left">
            <Form.Item label="DATE" labelAlign="left">
              {
                getFieldDecorator(
                  'range',
                  {
                    rules: [{ required: true, message: 'Please choose date!' }],
                  },
                )(<RangePicker placeholder={['Select date', '']} />)
              }
            </Form.Item>
            <Form.Item label="TYPE">
              {
                getFieldDecorator(
                  'radio',
                  {
                    rules: [{ required: true, message: 'Please choose the type of your absance request!' }],
                  },
                )(
                  <Radio.Group disabled={id !== null}>
                    {
                      Object.entries(types).map(([key, value]) => (
                        <Radio.Button key={key} value={key.toString()}>{value}</Radio.Button>
                      ))
                    }
                  </Radio.Group>,
                )
              }
            </Form.Item>
            <Form.Item label="COMMENT">
              {
                getFieldDecorator(
                  'text',
                  {
                    rules: [{ required: false }],
                  },
                )(<TextArea placeholder="Enter something..." autoSize={{ minRows: 2, maxRows: 6 }} />)
              }
            </Form.Item>
          </Form>
        </IntraModal>
      </div>
    );
  }
}

AbsencePage.propTypes = {
  absence: PropTypes.shape({
    summary: PropTypes.shape().isRequired,
    types: PropTypes.shape().isRequired,
    details: PropTypes.shape().isRequired,
    isLoading: PropTypes.bool.isRequired,
  }).isRequired,
  dispatchGetAbsences: PropTypes.func.isRequired,
  dispatchGetAbsenceTypes: PropTypes.func.isRequired,
  dispatchGetAbsenceDetails: PropTypes.func.isRequired,
  dispatchFilterTable: PropTypes.func.isRequired,
  dispatchNewAbsence: PropTypes.func.isRequired,
  dispatchDeleteAbsence: PropTypes.func.isRequired,
  form: PropTypes.shape().isRequired,
};

const mapStateToProps = (state) => ({
  absence: state.absenceReducer,
});

const mapDispatchToProps = {
  dispatchGetAbsences: getAbsences,
  dispatchGetAbsenceTypes: getAbsenceTypes,
  dispatchGetAbsenceDetails: getAbsenceDetails,
  dispatchFilterTable: selectFilter,
  dispatchNewAbsence: newAbsence,
  dispatchDeleteAbsence: deleteAbsence,
};

export default Form.create({ name: 'Absence' })(connect(mapStateToProps, mapDispatchToProps)(AbsencePage));
