import {
  GET_MESSAGES_REQUESTED, GET_MESSAGE_DETAILS_REQUESTED,
  SELECT_MESSAGE, SELECT_MESSAGE_FILTER, CLEAR_MESSAGE_FILTER,
  GET_UNREAD_MESSAGES_REQUESTED, READ_MESSAGE_REQUESTED,
} from 'consts/actionTypes';

export const getMessages = () => ({
  type: GET_MESSAGES_REQUESTED,
});

export const getMessageDetails = (messageId, notification) => ({
  type: GET_MESSAGE_DETAILS_REQUESTED,
  payload: {
    messageId,
    notification,
  },
});

export const selectMessage = (messageId) => ({
  type: SELECT_MESSAGE,
  payload: {
    messageId,
  },
});

export const selectMessageFilter = (filterId) => ({
  type: SELECT_MESSAGE_FILTER,
  payload: {
    filterId,
  },
});

export const clearMessageFilter = () => ({
  type: CLEAR_MESSAGE_FILTER,
});

export const getUnreadMessages = () => ({
  type: GET_UNREAD_MESSAGES_REQUESTED,
});

export const readMessage = () => ({
  type: READ_MESSAGE_REQUESTED,
});
