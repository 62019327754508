import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Breadcrumb, Spin } from 'antd';
import MdFiling from 'react-ionicons/lib/MdFiling';
import IosBookmarks from 'react-ionicons/lib/IosBookmarks';
import { selectCategory, getCategories, getTags, getChangeLog } from 'actions/wikiActions';
import WikiChangeLog from 'components/Wiki/WikiChangeLog';
import WikiSearchTile from 'components/Wiki/WikiSearchTile';
import './wiki-main-page.scss';
import { getPageTitle } from '../../utils/branding';


class WikiMainPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCharacter: '',
    };
  }

  componentDidMount() {
    document.title = getPageTitle('Wiki');
    const { dispatchGetCategories, dispatchGetTags, dispatchGetChangelog } = this.props;
    dispatchGetCategories();
    dispatchGetTags();
    dispatchGetChangelog();
  }

  render() {
    const { categories, dispatchSelectCategory, isMobile, changeLog, categoryLoading } = this.props;
    const { selectedCharacter } = this.state;
    return (
      <div className="wiki-main-page">
        <div className="wiki-main-page-title-container">
          <Breadcrumb className="wiki-bread-crumb">
            <Breadcrumb.Item>
              <MdFiling />
              WIKI
            </Breadcrumb.Item>
          </Breadcrumb>
          <div className="wiki-change-log-title-container">
            <Link to="/wiki/change-log">
              <IosBookmarks color="#58adfc" />
              CHANGELOG
            </Link>
          </div>
        </div>
        <WikiSearchTile />
        <div className="wiki-main-title">CATEGORIES</div>
        <div className="wiki-category-container">
          {categories ? Object.keys(categories).map((category) => (
            <div
              key={categories[category].id.toString()}
              className="wiki-category-card-container"
              role="button"
              tabIndex={0}
              onClick={() => {
                this.setState({ selectedCharacter: category });
                dispatchSelectCategory(category, 0);
              }}
            >
              <div className="wiki-category-card-title">{categories[category].displayName}</div>
              {categoryLoading && (selectedCharacter === category) && <Spin size="small" />}
              <div className="wiki-category-card-subtitle">{categories[category].subTitle}</div>
            </div>
          )) : (
            <div className="wiki-main-change-log-container">
              <Spin size="small" />
            </div>
          )}
        </div>
        <div className="wiki-main-title">CHANGELOG</div>
        <div className="wiki-main-change-log-container">
          {
            changeLog && changeLog.length
              ? <WikiChangeLog isMobile={isMobile} changeLog={changeLog} />
              : <Spin size="small" />
          }
        </div>
      </div>
    );
  }
}


const mapStateToProps = (store) => ({
  categories: store.wikiReducer.categories,
  tags: store.wikiReducer.tags,
  changeLog: store.wikiReducer.changeLog,
  isMobile: store.appReducer.isMobile,
  categoryLoading: store.wikiReducer.categoryLoading,
});

const mapDispatchToProps = {
  dispatchSelectCategory: selectCategory,
  dispatchGetCategories: getCategories,
  dispatchGetTags: getTags,
  dispatchGetChangelog: getChangeLog,
};

WikiMainPage.propTypes = {
  categoryLoading: PropTypes.bool.isRequired,
  categories: PropTypes.shape({}),
  changeLog: PropTypes.arrayOf(PropTypes.shape({})),
  dispatchSelectCategory: PropTypes.func.isRequired,
  dispatchGetCategories: PropTypes.func.isRequired,
  dispatchGetTags: PropTypes.func.isRequired,
  dispatchGetChangelog: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

WikiMainPage.defaultProps = {
  categories: null,
  changeLog: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(WikiMainPage);
