import { takeEvery, call, put } from 'redux-saga/effects';
import {
  USER_LOGIN_REQUESTED, USER_LOGIN_SUCCEEDED, USER_LOGIN_FAILED,
  USER_LOGOUT_REQUESTED, USER_LOGOUT_SUCCEEDED,
  RESTORE_USER_BY_TOKEN_REQUESTED, RESTORE_USER_BY_TOKEN_SUCCEEDED, RESTORE_USER_BY_TOKEN_FAILED,
  USER_UPDATE_USERINFO_REQUESTED, USER_UPDATE_USERINFO_SUCCEEDED, USER_UPDATE_USERINFO_FAILED,
} from 'consts/actionTypes';
import * as config from 'services/config';
import { openNotificationWithIcon } from 'services/utils';
import * as API from 'services/api';

export function* login(action) {
  try {
    const { code } = action.payload;
    const postLoginData = {
      code,
    };
    const bodyFormData = new FormData();
    Object.entries(postLoginData).forEach((e) => bodyFormData.append(e[0], e[1]));
    const token = yield call(API.postLoginData, `${config.host}/oauth/token`, bodyFormData);
    localStorage.setItem('accessToken', token.access_token);
    localStorage.setItem('refreshToken', token.refresh_token);
    yield put({
      type: USER_LOGIN_SUCCEEDED,
    });
    yield put({
      type: RESTORE_USER_BY_TOKEN_REQUESTED,
    });
  } catch (error) {
    yield put({
      type: USER_LOGIN_FAILED,
      payload: {
        errorMessage: error.message,
      },
    });
    config.history.push('/login');
    console.log(error); // eslint-disable-line
  }
}

export function* logout() {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
  yield put({
    type: USER_LOGOUT_SUCCEEDED,
  });
  config.history.push('/login');
}

export function* updateUserInfo(action) {
  try {
    const { payload } = action;
    yield call(API.patchData, `${config.host}/user/${payload.userId}?_format=json`, payload.userData);
    yield put({
      type: USER_UPDATE_USERINFO_SUCCEEDED,
    });
    openNotificationWithIcon('success', 'data has been updated successfully', 'Your profile data has been changed');
  } catch (error) {
    yield put({
      type: USER_UPDATE_USERINFO_FAILED,
      payload: {
        errorMessage: error.message,
      },
    });
    openNotificationWithIcon('error', 'error', 'Data has not been sent, try again!');
  }
}

export function* restoreUserByToken(action) {
  try {
    const token = localStorage.getItem('accessToken');
    const user = yield call(API.getData, `${config.host}/api/user/current_user?_format=json`);
    if (user) {
      const userId = user.uid[0].value;
      const userData = {
        name: {},
        contacts: {},
        personalData: {},
      };
      userData.name.firstName = {
        value: user.intra_first_name[0] ? user.intra_first_name[0].value : '',
        placeHolder: 'first name',
        disabled: true,
        index: 0,
        key: 'firstName',
      };
      userData.name.lastName = {
        value: user.intra_last_name[0] ? user.intra_last_name[0].value : '',
        placeHolder: 'last name',
        disabled: true,
        index: 1,
        key: 'lastName',
      };
      userData.contacts.email = {
        value: user.mail[0] ? user.mail[0].value : '',
        placeHolder: 'email address',
        disabled: true,
        index: 0,
        key: 'email',
      };
      userData.contacts.phone = {
        value: user.intra_phone[0] ? user.intra_phone[0].value : '',
        placeHolder: 'telephone',
        disabled: false,
        index: 1,
        key: 'phone',
      };
      userData.contacts.slack = {
        value: user.intra_slack_name[0] ? user.intra_slack_name[0].value : '',
        placeHolder: 'slack',
        disabled: false,
        index: 2,
        key: 'slack',
      };
      userData.contacts.skype = {
        value: user.intra_skype[0] ? user.intra_skype[0].value : '',
        placeHolder: 'skype',
        disabled: false,
        index: 3,
        key: 'skype',
      };
      userData.contacts.twitter = {
        value: user.intra_twitter[0] ? user.intra_twitter[0].value : '',
        placeHolder: 'twitter',
        disabled: false,
        index: 4,
        key: 'twitter',
      };
      userData.personalData.entryId = {
        value: user.intra_entry_id[0] ? user.intra_entry_id[0].value : '',
        placeHolder: 'card id',
        disabled: true,
        index: 0,
        key: 'entryId',
      };
      userData.personalData.homeOffice = {
        value: user.intra_weekly_home_office[0] ? user.intra_weekly_home_office[0].value : '',
        placeHolder: 'home office',
        disabled: true,
        index: 1,
        key: 'homeOffice',
      };

      const next = action.payload ? action.payload.next : '/dashboard';
      yield put({
        type: RESTORE_USER_BY_TOKEN_SUCCEEDED,
        payload: {
          token,
          user,
          userId,
          userData,
          next,
        },
      });
      config.history.push(`${next}`);
    }
  } catch (error) {
    yield put({
      type: RESTORE_USER_BY_TOKEN_FAILED,
    });
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
  }
}

export const userSagas = [
  takeEvery(USER_LOGIN_REQUESTED, login),
  takeEvery(RESTORE_USER_BY_TOKEN_REQUESTED, restoreUserByToken),
  takeEvery(USER_LOGOUT_REQUESTED, logout),
  takeEvery(USER_UPDATE_USERINFO_REQUESTED, updateUserInfo),
];
