import React from 'react';
import PropTypes from 'prop-types';
import IosSearch from 'react-ionicons/lib/IosSearch';
import MdClose from 'react-ionicons/lib/MdClose';
import './basic-search.scss';

class BasicSearch extends React.Component {
  constructor(props) {
    super(props);
    const { searchField } = this.props;
    this.state = {
      value: searchField,
      placeholderVisible: true,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.searchField !== state.value) {
      return {
        value: props.searchField,
      };
    }
    return null;
  }

  handleFocus = () => {
    this.setState({
      placeholderVisible: false,
    });
  }

  handleBlur = () => {
    const { value } = this.state;
    if (value === '') {
      this.setState({
        placeholderVisible: true,
      });
    }
  }

  handleChange = (event) => {
    const { value: eventValue } = event.target;
    const { onChange } = this.props;
    this.setState({
      value: eventValue,
    });
    onChange(eventValue);
  }

  render() {
    const { value, placeholderVisible } = this.state;
    const { removeSearchField, searchField, placeholderText } = this.props;
    return (
      <div className="basic-search-container">
        <div className="basic-search">
          {
            value === ''
              ? <IosSearch className="basic-search-placeholder-question" fontSize="16px" />
              : (
                <MdClose
                  className="basic-search-placeholder-question"
                  fontSize="16px"
                  onClick={() => { removeSearchField(); this.setState({ placeholderVisible: true }); }}
                />
              )
          }
          <input
            className="basic-search-input"
            placeholder={placeholderVisible ? placeholderText : ''}
            name="search"
            type="text"
            value={searchField}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            onChange={this.handleChange}
          />
        </div>
      </div>
    );
  }
}

BasicSearch.propTypes = {
  onChange: PropTypes.func.isRequired,
  removeSearchField: PropTypes.func.isRequired,
  searchField: PropTypes.string.isRequired,
  placeholderText: PropTypes.string.isRequired,
};


export default BasicSearch;
