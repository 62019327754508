import moment from 'moment';
import _ from 'lodash';
import {
  SAVE_PROFILE_FORM_REQUESTED, SAVE_PROFILE_FORM_SUCCEEDED, SAVE_PROFILE_FORM_FAILED,
  ADD_EDUCATION_REQUESTED, ADD_EDUCATION_FAILED, ADD_EDUCATION_SUCCEEDED,
  ADD_DEPENDANT_REQUESTED, ADD_DEPENDANT_FAILED, ADD_DEPENDANT_SUCCEEDED,
  DELETE_EXAM_REQUESTED, DELETE_EXAM_FAILED, DELETE_EXAM_SUCCEEDED,
  DELETE_EXAM_PLAN_SUCCEEDED, DELETE_EXAM_PLAN_REQUESTED, DELETE_EXAM_PLAN_FAILED,
  COMPLETE_EXAM_PLAN_SUCCEEDED, COMPLETE_EXAM_PLAN_REQUESTED, COMPLETE_EXAM_PLAN_FAILED,
  ADD_EXAM_REQUESTED, ADD_EXAM_FAILED, ADD_EXAM_SUCCEEDED,
  ADD_EXAM_PLAN_REQUESTED, ADD_EXAM_PLAN_FAILED, ADD_EXAM_PLAN_SUCCEEDED,
  GET_PERSONAL_DATA_REQUESTED, GET_PERSONAL_DATA_SUCCEEDED, GET_PERSONAL_DATA_FAILED,
  CHANGE_PROFILE_PICTURE_FAILED, CHANGE_PROFILE_PICTURE_REQUESTED, CHANGE_PROFILE_PICTURE_SUCCEEDED,
  APP_GET_CURRENT_USER_SUCCEEDED,
} from 'consts/actionTypes';
import { host } from 'services/config';
import { put, select, takeEvery, call } from 'redux-saga/effects';
import { openNotificationWithIcon } from 'services/utils';
import * as API from 'services/api';

export const getProfileReducer = (store) => store.profileReducer;
export const getUserReducer = (store) => store.userReducer;
export const drupalMagic = (field) => (field && field !== '' ? [{ value: field }] : []);

export const getSessionToken = () => API.getData(`${host}/session/token`);

export function* saveProfile() {
  try {
    const profileReducer = yield select(getProfileReducer);
    const userReducer = yield select(getUserReducer);
    const { inputs: { permanentAddress, mailingAddress, skype, slack, email, phone, bankAccount, maritalStatus } } = profileReducer;
    const { userId } = userReducer;
    const data = {
      intra_permanent_address: drupalMagic(permanentAddress),
      intra_mailing_address: drupalMagic(mailingAddress),
      intra_skype: drupalMagic(skype),
      intra_slack_name: drupalMagic(slack),
      mail: drupalMagic(email),
      intra_phone: drupalMagic(phone),
      intra_bank_account_number: drupalMagic(bankAccount),
      intra_marital_status: drupalMagic(maritalStatus),
    };
    yield call(API.patchData, `${host}/api/user/${userId}/save-profile`, data);
    yield put({
      type: SAVE_PROFILE_FORM_SUCCEEDED,
    });
    yield put({
      type: GET_PERSONAL_DATA_REQUESTED,
    });
    openNotificationWithIcon('success', 'data has been updated successfully', 'Your profile data has been changed');
  } catch (error) {
    yield put({
      type: SAVE_PROFILE_FORM_FAILED,
      payload: {
        errorMessage: error.message,
      },
    });
    openNotificationWithIcon('error', 'error', 'Data has not been sent, try again!');
    console.log(error.message); // eslint-disable-line
  }
}

export function* addEducation({ payload }) {
  try {
    const { instituteName, fieldOfStudy, degree, serialNumber, node } = payload;
    const data = {
      type: [{
        target_id: 'education',
      }],
      title: drupalMagic(instituteName),
      intra_field_of_study: drupalMagic(fieldOfStudy),
      intra_degree: drupalMagic(degree),
      intra_serial_number: drupalMagic(serialNumber),
    };
    if (node) {
      yield call(API.patchData, `${host}/api/node/education/${node}?_format=json`, data);
    } else {
      yield call(API.postData, `${host}/api/node/education?_format=json`, data);
    }
    yield put({
      type: ADD_EDUCATION_SUCCEEDED,
    });
    yield put({
      type: GET_PERSONAL_DATA_REQUESTED,
    });
    openNotificationWithIcon('success', 'data has been updated successfully', 'Your education data has been changed');
  } catch (error) {
    yield put({
      type: ADD_EDUCATION_FAILED,
      payload: {
        errorMessage: error.message,
      },
    });
    openNotificationWithIcon('error', 'error', 'Data has not been sent, try again!');
    console.log(error.message); // eslint-disable-line
  }
}

export function* addDependant({ payload }) {
  try {
    const { childName, childTaxNumber, childTajNumber, childsMotherName, childsBirthday, node } = payload;
    const data = {
      type: [{
        target_id: 'dependent',
      }],
      title: drupalMagic(childName),
      intra_tax_number: drupalMagic(childTaxNumber),
      intra_social_security_number: drupalMagic(childTajNumber),
      intra_mother_name: drupalMagic(childsMotherName),
      intra_birthday: drupalMagic(moment(childsBirthday).format('YYYY-MM-DD')),
    };
    if (node) {
      yield call(API.patchData, `${host}/api/node/dependent/${node}?_format=json`, data);
    } else {
      yield call(API.postData, `${host}/api/node/dependent?_format=json`, data);
    }
    yield put({
      type: ADD_DEPENDANT_SUCCEEDED,
    });
    yield put({
      type: GET_PERSONAL_DATA_REQUESTED,
    });
    openNotificationWithIcon('success', 'data has been updated successfully', 'Your dependent data has been changed');
  } catch (error) {
    yield put({
      type: ADD_DEPENDANT_FAILED,
      payload: {
        errorMessage: error.message,
      },
    });
    openNotificationWithIcon('error', 'error', 'Data has not been sent, try again!');
    console.log(error.message); // eslint-disable-line
  }
}

export function* deleteExam({ payload }) {
  try {
    const { node } = payload;


    yield call(API.deleteData, `${host}/api/node/exam/${node}`);

    yield put({
      type: DELETE_EXAM_SUCCEEDED,
    });
    yield put({
      type: GET_PERSONAL_DATA_REQUESTED,
    });
    openNotificationWithIcon('success', 'Exam has been deleted successfully', 'The exam data has been removed');
  } catch (error) {
    yield put({
      type: DELETE_EXAM_FAILED,
      payload: {
        errorMessage: error.message,
      },
    });
    openNotificationWithIcon('error', 'Deletion failed', 'The exam could not be deleted, please try again!');
    console.log(error.message); // eslint-disable-line
  }
}

export function* deleteExamPlan({ payload }) {
  try {
    const { node } = payload;

    yield call(API.deleteData, `${host}/api/node/exam_plan/${node}`);

    yield put({
      type: DELETE_EXAM_PLAN_SUCCEEDED,
    });
    yield put({
      type: GET_PERSONAL_DATA_REQUESTED,
    });
    openNotificationWithIcon('success', 'Exam plan has been deleted successfully', 'The exam plan data has been removed');
  } catch (error) {
    yield put({
      type: DELETE_EXAM_PLAN_FAILED,
      payload: {
        errorMessage: error.message,
      },
    });
    openNotificationWithIcon('error', 'Deletion failed', 'The exam plan could not be deleted, please try again!');
    console.log(error.message); // eslint-disable-line
  }
}

export function* completeExamPlan({ payload }) {
  const userReducer = yield select(getUserReducer);
  const { acquiredAtDate, expiresAtDate, name, nodeId } = payload;
  const { userId } = userReducer;

  try {
    const data = {
      title: drupalMagic(name),
      intra_acquired_at: drupalMagic(moment(acquiredAtDate).format('YYYY-MM-DD')),
      intra_expires_at: drupalMagic(moment(expiresAtDate).format('YYYY-MM-DD')),
      intra_user_id: drupalMagic(Number(userId)),
      delete_exam_plan: Number(nodeId),
    };

    yield call(API.postData, `${host}/api/node/exam`, data);

    yield put({
      type: COMPLETE_EXAM_PLAN_SUCCEEDED,
    });
    yield put({
      type: GET_PERSONAL_DATA_REQUESTED,
    });
    openNotificationWithIcon('success', 'Exam plan has been completed successfully', 'The exam has been moved to the completed exams.');
  } catch (error) {
    yield put({
      type: COMPLETE_EXAM_PLAN_FAILED,
      payload: {
        errorMessage: error.message,
      },
    });
    openNotificationWithIcon('error', 'Submission failed', 'The exam plan could not be submitted, please try again!');
    console.log(error.message); // eslint-disable-line
  }
}

export function* addExam({ payload }) {
  try {
    const { name, acquiredAt, expiresAt, node } = payload;
    const data = {
      type: [{
        target_id: 'dependent',
      }],
      title: drupalMagic(name),
      intra_acquired_at: acquiredAt ? drupalMagic(moment(acquiredAt).format('YYYY-MM-DD')) : [],
      intra_expires_at: expiresAt ? drupalMagic(moment(expiresAt).format('YYYY-MM-DD')) : [],
    };
    if (node) {
      yield call(API.patchData, `${host}/api/node/exam/${node}?_format=json`, data);
    } else {
      yield call(API.postData, `${host}/api/node/exam?_format=json`, data);
    }
    yield put({
      type: ADD_EXAM_SUCCEEDED,
    });
    yield put({
      type: GET_PERSONAL_DATA_REQUESTED,
    });
    openNotificationWithIcon('success', 'data has been updated successfully', 'Your exam data has been changed');
  } catch (error) {
    yield put({
      type: ADD_EXAM_FAILED,
      payload: {
        errorMessage: error.message,
      },
    });
    openNotificationWithIcon('error', 'error', 'Data has not been sent, try again!');
    console.log(error.message); // eslint-disable-line
  }
}

export function* addExamPlan({ payload }) {
  try {
    const { name, deadlineDate, node } = payload;
    const data = {
      type: [{
        target_id: 'exam_plan',
      }],
      title: drupalMagic(name),
      intra_deadline_date: drupalMagic(moment(deadlineDate).format('YYYY-MM-DD')),
    };
    if (node) {
      yield call(API.patchData, `${host}/api/node/exam_plan/${node}?_format=json`, data);
    } else {
      yield call(API.postData, `${host}/api/node/exam_plan?_format=json`, data);
    }
    yield put({
      type: ADD_EXAM_PLAN_SUCCEEDED,
    });
    yield put({
      type: GET_PERSONAL_DATA_REQUESTED,
    });
    openNotificationWithIcon('success', 'data has been updated successfully', 'Your exam plan data has been changed');
  } catch (error) {
    yield put({
      type: ADD_EXAM_PLAN_FAILED,
      payload: {
        errorMessage: error.message,
      },
    });
    openNotificationWithIcon('error', 'error', 'Data has not been sent, try again!');
    console.log(error.message); // eslint-disable-line
  }
}

export function* getPersonalDetails() {
  try {
    const personal = yield call(API.getData, `${host}/api/user/current_user`);
    const maritalStatuses = yield call(API.getData, `${host}/api/list/marital_status`);
    const degrees = yield call(API.getData, `${host}/api/list/degree`);
    const personalDetails = {
      firstName: personal.intra_first_name[0] ? personal.intra_first_name[0].value : '',
      lastName: personal.intra_last_name[0] ? personal.intra_last_name[0].value : '',
      birthDay: personal.intra_birthday[0] ? personal.intra_birthday[0].value : '',
      birthPlace: personal.intra_birthplace[0] ? personal.intra_birthplace[0].value : '',
      motherName: personal.intra_mother_name[0] ? personal.intra_mother_name[0].value : '',
      idCard: personal.intra_id_card_number[0] ? personal.intra_id_card_number[0].value : '',
      taxNumber: personal.intra_tax_number[0] ? personal.intra_tax_number[0].value : '',
      tajNumber: personal.intra_social_security_number[0] ? personal.intra_social_security_number[0].value : '',
      changableData: {
        permanentAddress: personal.intra_permanent_address[0] ? personal.intra_permanent_address[0].value : '',
        mailingAddress: personal.intra_mailing_address[0] ? personal.intra_mailing_address[0].value : '',
        email: personal.mail[0] ? personal.mail[0].value : '',
        skype: personal.intra_skype[0] ? personal.intra_skype[0].value : '',
        slack: personal.intra_slack_name[0] ? personal.intra_slack_name[0].value : '',
        phone: personal.intra_phone[0] ? personal.intra_phone[0].value : '',
        bankAccount: personal.intra_bank_account_number[0] ? personal.intra_bank_account_number[0].value : '',
        maritalStatus: personal.intra_marital_status[0] ? personal.intra_marital_status[0].value : '',
      },
      dependants: personal.intra_dependents[0] ? personal.intra_dependents.map((dependant) => ({
        data: {
          childName: dependant.data.intra_name,
          childsMotherName: dependant.data.intra_mother_name,
          childsBirthday: dependant.data.intra_birthday,
          childTaxNumber: dependant.data.intra_tax_number,
          childTajNumber: dependant.data.intra_social_security_number,
          nodeId: dependant.target_id,
          action: '',
        },
      })) : [],
      educations: personal.intra_educations[0] ? personal.intra_educations.map((education) => ({
        data: {
          instituteName: education.data.intra_institute_name,
          fieldOfStudy: education.data.intra_field_of_study,
          degree: education.data.intra_degree,
          serialNumber: education.data.intra_serial_number,
          nodeId: education.target_id,
          action: '',
        },
      })) : [],
      exams: personal.intra_exams[0] ? personal.intra_exams.map((exam) => ({
        data: {
          name: exam.data.intra_name,
          acquiredAt: exam.data.intra_acquired_at,
          expiresAt: exam.data.intra_expires_at,
          nodeId: exam.target_id,
          action: '',
        },
      })) : [],
      examPlans: personal.intra_exam_plans[0] ? personal.intra_exam_plans.map((examPlan) => ({
        data: {
          name: examPlan.data.intra_name,
          deadlineDate: examPlan.data.intra_deadline_date,
          nodeId: examPlan.target_id,
          action: '',
        },
      })) : [],
    };
    yield put({
      type: GET_PERSONAL_DATA_SUCCEEDED,
      payload: {
        maritalStatuses: Object.keys(maritalStatuses).map((maritalStatus) => ({
          value: maritalStatus,
          display: maritalStatuses[maritalStatus],
        })),
        degrees: Object.keys(degrees).map((degree) => ({
          value: degree,
          display: degrees[degree],
        })),
        personalDetails: _.omit(personalDetails, ['educations', 'dependants', 'changableData']),
        educations: _.pick(personalDetails, ['educations']).educations,
        dependants: _.pick(personalDetails, ['dependants']).dependants,
        exams: _.pick(personalDetails, ['exams']).exams,
        examPlans: _.pick(personalDetails, ['examPlans']).examPlans,
        changableData: _.pick(personalDetails, ['changableData']).changableData,
      },
    });
    yield put({
      type: APP_GET_CURRENT_USER_SUCCEEDED,
      payload: {
        intra_first_name: personal.intra_first_name,
        intra_last_name: personal.intra_last_name,
        intra_profile_picture: personal.intra_profile_picture,
      },
    });
  } catch (error) {
    yield put({
      type: GET_PERSONAL_DATA_FAILED,
      payload: {
        errorMessage: error.message,
      },
    });
    console.log(error.message); // eslint-disable-line
  }
}

export function* changeProfilePicture({ payload }) {
  try {
    const userReducer = yield select(getUserReducer);
    const { file, fileName } = payload;
    const { userId } = userReducer;
    const response = yield call(API.uploadFile, `${host}/api/file/upload/user/user/intra_profile_picture?_format=json`, file, fileName);
    const userData = {
      intra_profile_picture: [{
        target_id: response.data.fid[0].value,
      }],
    };
    yield call(API.patchData, `${host}/api/user/${userId}/save-profile?_format=json`, userData);
    yield put({
      type: CHANGE_PROFILE_PICTURE_SUCCEEDED,
    });
    yield put({
      type: GET_PERSONAL_DATA_REQUESTED,
    });
    openNotificationWithIcon('success', 'data has been updated successfully', 'Your profile picture has been changed');
  } catch (error) {
    yield put({
      type: CHANGE_PROFILE_PICTURE_FAILED,
      payload: {
        errorMessage: error.message,
      },
    });
    openNotificationWithIcon('error', 'error', 'Data has not been sent, try again!');
    console.log(error.message); // eslint-disable-line
  }
}

export const profileSagas = [
  takeEvery(SAVE_PROFILE_FORM_REQUESTED, saveProfile),
  takeEvery(ADD_EDUCATION_REQUESTED, addEducation),
  takeEvery(ADD_DEPENDANT_REQUESTED, addDependant),
  takeEvery(DELETE_EXAM_REQUESTED, deleteExam),
  takeEvery(DELETE_EXAM_PLAN_REQUESTED, deleteExamPlan),
  takeEvery(COMPLETE_EXAM_PLAN_REQUESTED, completeExamPlan),
  takeEvery(ADD_EXAM_REQUESTED, addExam),
  takeEvery(ADD_EXAM_PLAN_REQUESTED, addExamPlan),
  takeEvery(GET_PERSONAL_DATA_REQUESTED, getPersonalDetails),
  takeEvery(CHANGE_PROFILE_PICTURE_REQUESTED, changeProfilePicture),
];
