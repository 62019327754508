import React from 'react';
import PropTypes from 'prop-types';
import SpinnerContainer from '../SpinnerContainer/SpinnerContainer';
import { AbsenceDots } from './AbsenceDots';
import { AbsenceSummaryBlock } from './AbsenceSummaryBlock';

export const AbsencesSummary = ({ absencesSummary }) => {
  if (!absencesSummary.intraYearlyHolidays) {
    return (
      <SpinnerContainer className="absences-spinner-container" />
    );
  }

  return (
    <div className="absence-summary-container">
      <p className="absence-summary-row">
        <div>
          <span className={absencesSummary.intraYearlyHolidays - absencesSummary.intraApprovedHolidays > 0
            ? 'absence-row-text-green'
            : 'absence-row-text-gray'}
          >
            {' '}
            {absencesSummary.intraYearlyHolidays - absencesSummary.intraApprovedHolidays}
            {' '}
            day(s)
          </span>
          {' '}
          remaining
          {' '}
          <span className="absence-row-text-gray">
          of
            {' '}
            {absencesSummary.intraYearlyHolidays}
            {' '}
          days
          </span>
        </div>
        <AbsenceDots
          greenCount={absencesSummary.intraYearlyHolidays - absencesSummary.intraApprovedHolidays}
          grayDotCount={absencesSummary.intraApprovedHolidays}
        />
      </p>

      <AbsenceSummaryBlock
        takenDaysCount={absencesSummary.absenceStats.q1q2}
        expectedDaysCount={absencesSummary.absenceStats.expected_q1q2}
        third={1}
      />
      <AbsenceSummaryBlock
        takenDaysCount={absencesSummary.absenceStats.q3}
        expectedDaysCount={absencesSummary.absenceStats.expected_q3}
        third={2}
      />
      <AbsenceSummaryBlock
        takenDaysCount={absencesSummary.absenceStats.q4}
        expectedDaysCount={absencesSummary.absenceStats.expected_q4}
        third={3}
      />

    </div>
  );
};

AbsencesSummary.propTypes = {
  absencesSummary: PropTypes.shape({
    intraApprovedHolidays: PropTypes.number.isRequired,
    intraYearlyHolidays: PropTypes.number.isRequired,
    absenceStats: PropTypes.shape({
      expected_q1q2: PropTypes.number.isRequired,
      q1q2: PropTypes.number.isRequired,
      expected_q3: PropTypes.number.isRequired,
      q3: PropTypes.number.isRequired,
      expected_q4: PropTypes.number.isRequired,
      q4: PropTypes.number.isRequired,
    }),
  }).isRequired,
};
