import React from 'react';
import PropTypes from 'prop-types';
import ChartTitle from 'components/Charts/ChartTitle/ChartTitle';
import DonutChart from 'components/Charts/DonutChart/DonutChart';
import SpinnerContainer from 'components/SpinnerContainer/SpinnerContainer';
import InfoHover from 'components/InfoHover/InfoHover';
import './currentMonthChart.scss';

const CurrentMonthChartContainer = ({ expectedHours, myWorkTimeFormatted, todayFormatted, myWorkTimeInHours, monthlyWorktime }) => {
  if (monthlyWorktime === null) {
    return (
      <div className="current-month-chart-main-container">
        <SpinnerContainer className="piechart-spinner-container" />
      </div>
    );
  }
  const currentMonthChartData = [
    { y: expectedHours, label: ' ' },
    { y: monthlyWorktime - expectedHours, label: ' ' },
  ];
  const myWorktimeChartData = [
    { y: myWorkTimeInHours, label: ' ' },
    { y: monthlyWorktime - myWorkTimeInHours, label: ' ' },
  ];
  return (
    <div className="current-month-chart-main-container">
      <div className="current-month-chart-header-container">
        <ChartTitle
          chartTitle="monthly office time"
          iconName="IosPie"
        />
        <InfoHover
          content={
            (
              <div className="popover-content">
                The amount of time you are expected to spend and actually have spent in the office or
                in home office or at a client up till now in the current month and today.
              </div>
            )
          }
          title="ABOUT MONTHLY OFFICE TIME"
        />
      </div>
      <div className="current-month-chart-piechart-container">
        <div className="current-month-chart-expected-hours-pie">
          <DonutChart
            wrapperSize={338}
            innerRadius={115}
            width={340}
            height={325}
            padAngle={1}
            padding={30}
            data={currentMonthChartData}
            style={{ data: { fill: (d) => (d.index === 0 ? '#62E2E3' : '#EEEFF3') } }}
          />
        </div>
        <div className="current-month-chart-my-worktime-pie">
          <DonutChart
            wrapperSize={338}
            innerRadius={110}
            width={340}
            height={325}
            padAngle={1}
            padding={70}
            data={myWorktimeChartData}
            style={{
              data: {
                fill: (d) => (d.index === 0 ? '#6796E4' : '#EEEFF3'),
              },
            }}
          />
        </div>
        <div className="current-month-chart-data">
          <p>
            Expected hours
            <span className="current-month-chart-expected-hours">{expectedHours}</span>
          </p>
          <p>
            My worktime
            <span className="current-month-chart-real-hours">{myWorkTimeFormatted}</span>
          </p>
          <p>
            Today
            <span className="current-month-chart-real-hours">{todayFormatted}</span>
          </p>
        </div>
      </div>
    </div>
  );
};

CurrentMonthChartContainer.propTypes = {
  expectedHours: PropTypes.number,
  myWorkTimeFormatted: PropTypes.string,
  todayFormatted: PropTypes.string,
  myWorkTimeInHours: PropTypes.number,
  monthlyWorktime: PropTypes.number,
};

CurrentMonthChartContainer.defaultProps = {
  expectedHours: null,
  myWorkTimeFormatted: null,
  todayFormatted: null,
  myWorkTimeInHours: null,
  monthlyWorktime: null,
};

export default CurrentMonthChartContainer;
