import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import SpinnerContainer from 'components/SpinnerContainer/SpinnerContainer';
import IosSadOutline from 'react-ionicons/lib/IosSadOutline';
import Events from './Events';
import './events.scss';


const EventsContainer = ({ events, hasLoadingError }) => {
  if (hasLoadingError) {
    return (
      <div className="events-main-container">
        <IosSadOutline fontSize="30px" />
        <div>Cannot get google calendar.</div>
      </div>
    );
  }

  if (events === null) {
    return (
      <div className="events-main-container">
        <SpinnerContainer className="events-spinner-container" />
      </div>
    );
  }

  const today = moment().format('D MMMM, dddd');
  return (
    <Events today={today} events={events} />
  );
};

EventsContainer.propTypes = {
  events: PropTypes.arrayOf(PropTypes.shape({})),
  hasLoadingError: PropTypes.bool.isRequired,
};

EventsContainer.defaultProps = {
  events: null,
};

export default EventsContainer;
