import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import IosSearch from 'react-ionicons/lib/IosSearch';
import { setSearchValue, setNoResultMessage } from 'actions/wikiActions';
import { history } from 'services/config';
import { openNotificationWithIcon } from 'services/utils';
import './wiki-search-input.scss';

class WikiSearchInput extends Component {
  state = {
    searchText: '',
    placeholderVisible: true,
  }

  handleChange = (event) => {
    this.setState({ searchText: event.target.value });
  }

  handleSubmit = () => {
    this.sendSearchText();
  }

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.sendSearchText();
    }
  }

  sendSearchText = () => {
    const { searchText } = this.state;
    const { dispatchSetSearchValue, dispatchSetNoResultMessage } = this.props;
    if (searchText.length < 3) {
      openNotificationWithIcon('warning', 'warning', 'Please enter min. 3 characters');
    } else {
      dispatchSetNoResultMessage('No results found');
      dispatchSetSearchValue(searchText);
      if (!window.location.pathname.includes('/wiki/searchresults')) {
        history.push('/wiki/searchresults');
      }
      this.setState({ searchText: '' });
    }
  }

  handleFocus = () => {
    this.setState({
      placeholderVisible: false,
    });
  }

  handleBlur = () => {
    this.setState({
      placeholderVisible: true,
    });
  }

  render() {
    const { searchText, placeholderVisible } = this.state;
    const { placeholderText } = this.props;
    return (
      <div className="wiki-search-input-container">
        <input
          className="wiki-search-input"
          placeholder={placeholderVisible ? placeholderText : ''}
          name="search"
          type="text"
          value={searchText}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          onChange={this.handleChange}
          onKeyPress={this.handleKeyPress}
        />
        <button
          type="button"
          className="wiki-search-button"
          onClick={() => this.handleSubmit()}
        >
          <IosSearch fontSize="18px" />
        </button>
      </div>
    );
  }
}

WikiSearchInput.propTypes = {
  placeholderText: PropTypes.string,
  dispatchSetSearchValue: PropTypes.func.isRequired,
  dispatchSetNoResultMessage: PropTypes.func.isRequired,
};

WikiSearchInput.defaultProps = {
  placeholderText: '',
};

const mapDispatchToProps = {
  dispatchSetSearchValue: setSearchValue,
  dispatchSetNoResultMessage: setNoResultMessage,
};

const mapStateToProps = (store) => ({
  searchValue: store.wikiReducer.searchValue,
});

export default connect(mapStateToProps, mapDispatchToProps)(WikiSearchInput);
