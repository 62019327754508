/* eslint-disable */

module.exports = function findById(subKey, idKey) {

  function finder(items, key, value) {
    const id = idKey || key;
    const val = idKey ? key : value;
    let foundValue;

    for (let item of items) {
      if (item[id] === val) {
        foundValue = item;
        break;
      }
    }

    if (typeof foundValue === 'undefined') {
      for (let item of items) {
        if (item[subKey] && item[subKey].length > 0) {
          foundValue = finder(item[subKey], id, val);
          if(typeof foundValue !== 'undefined') break;
        }
      }
    }

    return foundValue;
  }

  return finder;
}
