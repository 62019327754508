import {
  USER_LOGIN_REQUESTED, USER_LOGIN_FAILED, USER_LOGOUT_REQUESTED,
  RESTORE_USER_BY_TOKEN_REQUESTED, USER_UPDATE_USERINFO_REQUESTED,
  USER_COGNITO_LOGIN_REQUESTED, USER_COGNITO_CODE,
} from 'consts/actionTypes';

export const login = (code) => ({
  type: USER_LOGIN_REQUESTED,
  payload: {
    code,
  },
});

export const cognitoLogin = () => ({
  type: USER_COGNITO_LOGIN_REQUESTED,
});

export const setCognitoCode = (code) => ({
  type: USER_COGNITO_CODE,
  payload: code,
});

export const handleFailedCognitoLogin = () => ({
  type: USER_LOGIN_FAILED,
});

export const logout = () => ({
  type: USER_LOGOUT_REQUESTED,
});

export const restoreUserByToken = (next) => ({
  type: RESTORE_USER_BY_TOKEN_REQUESTED,
  payload: {
    next,
  },
});

export const updateUserInfo = (userData, userId) => ({
  type: USER_UPDATE_USERINFO_REQUESTED,
  payload: {
    userData,
    userId,
  },
});
