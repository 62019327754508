import React from 'react';
import ColleaguesPage from './ColleaguesPage';


const ColleaguesPageContainer = () => (
  <div style={{ paddingBottom: '20px' }}>
    <div className="colleagues-title">COLLEAGUES</div>
    <div className="colleagues-container">
      <ColleaguesPage />
    </div>
  </div>
);

export default ColleaguesPageContainer;
