import { call, takeEvery, put } from 'redux-saga/effects';
import * as config from 'services/config';
import * as API from 'services/api';
import {
  BOOKMARKS_GET_BOOKMARKS_FAILED,
  BOOKMARKS_GET_BOOKMARKS_REQUESTED,
  BOOKMARKS_GET_BOOKMARKS_SUCCEEDED,
} from 'consts/actionTypes';
import { openNotificationWithIcon } from 'services/utils';

export function* getBookmarks() {
  try {
    const response = yield call(API.getData, `${config.host}/api/bookmarks`);
    const bookmarks = response.data;
    yield put({
      type: BOOKMARKS_GET_BOOKMARKS_SUCCEEDED,
      payload: {
        bookmarks,
      },
    });
  } catch (error) {
    yield put({
      type: BOOKMARKS_GET_BOOKMARKS_FAILED,
      payload: {
        errorMessage: error.message,
      },
    });
    console.log(error); // eslint-disable-line
    openNotificationWithIcon('error', 'error', 'Cannot get bookmarks!');
  }
}

export const bookmarkSagas = [
  takeEvery(BOOKMARKS_GET_BOOKMARKS_REQUESTED, getBookmarks),
];
