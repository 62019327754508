import React from 'react';
import PropTypes from 'prop-types';
import { Menu, Dropdown, Icon } from 'antd';
import IosArrowBack from 'react-ionicons/lib/IosArrowBack';
import IosArrowForward from 'react-ionicons/lib/IosArrowForward';
import IosMore from 'react-ionicons/lib/IosMore';
import MdArrowDropup from 'react-ionicons/lib/MdArrowDropup';
import MdArrowDropdown from 'react-ionicons/lib/MdArrowDropdown';
import SpinnerContainer from 'components/SpinnerContainer/SpinnerContainer';
import './table.scss';


export const Table = ({ dataSource, columns, table, filters, pager, handlePagerClick, children, customStyle, sort, isLoading }) => (
  <>
    <div className="custom-table ivu-table-wrapper">
      <div className={customStyle ? 'ivu-table custom' : 'ivu-table'}>
        <div className={customStyle ? 'ivu-table-header custom' : 'ivu-table-header'}>
          {
            isLoading
              ? (
                <SpinnerContainer className="spin-position" />
              ) : (
                <table cellSpacing="0" cellPadding="0" border="0" style={{ width: '100%' }}>
                  <thead>
                    <tr>
                      {
                        columns.map((column) => (
                          <th
                            key={column.key}
                          >
                            <div
                              className={`ivu-table-cell${table === 'contribBonus' ? ' contrib' : ''}`}
                              onClick={() => column.handleClick(table, column)}
                              role="button"
                              tabIndex={0}
                              onKeyDown={() => { }}
                            >
                              <span>
                                {
                                  column.filters
                                    ? (
                                      <Dropdown
                                        overlay={(
                                          <Menu
                                            style={{ textTransform: 'capitalize' }}
                                            selectedKeys={[filters.reduce((prev, next) => (
                                              next.dataIndex === column.dataIndex ? next.value : prev), 'default')]}
                                            onClick={({ key }) => column.handleSelect(table, column, key)}
                                          >
                                            <Menu.Item key="default">
                                              All
                                            </Menu.Item>
                                            {column.filters.map((filter) => (<Menu.Item key={filter.toLowerCase()}>{filter}</Menu.Item>))}
                                          </Menu>
                                            )}
                                        trigger={['click']}
                                      >
                                        <span>
                                          {column.title}
                                          <Icon type="down" />
                                        </span>
                                      </Dropdown>
                                    )
                                    : (
                                      <>
                                        {
                                          column.sortable
                                            ? (
                                              <>
                                                {column.title}
                                                <span className="table-sortable">
                                                  <MdArrowDropup fontSize="14px" color={sort.asc === false ? '#71b6f9' : '#bbbec4'} />
                                                  <MdArrowDropdown fontSize="14px" color={sort.asc === true ? '#71b6f9' : '#bbbec4'} />
                                                </span>
                                              </>
                                            )
                                            : column.title
                                        }
                                      </>
                                    )
                                }
                              </span>
                            </div>
                          </th>
                        ))
                      }
                    </tr>
                  </thead>
                  <tbody className="ivu-table-body">
                    {
                      dataSource.map((data, index) => (
                        <tr
                          key={index.toString()}
                          className={data.handleRowClick
                            ? `${data.selected ? 'ivu-table-row clickable selected' : 'ivu-table-row clickable'}`
                            : 'ivu-table-row'}
                          onClick={() => (data.handleRowClick ? data.handleRowClick(table, data) : () => { })}
                        >
                          {
                            columns.map(({ dataIndex }) => (
                              <td key={dataIndex} className={customStyle ? 'custom' : ''}>
                                <div className="ivu-table-cell">
                                  {
                                    dataIndex === 'taskId' || dataIndex === 'taskSubject'
                                      ? (
                                        <a href={data.data.taskUrl} target="_blank" rel="noopener noreferrer">
                                          <div className="cell-wrap">
                                            {
                                              typeof data.data[dataIndex] === 'object' && data.data[dataIndex] !== null
                                                ? data.data[dataIndex].map((node) => (node))
                                                : data.data[dataIndex]
                                            }
                                          </div>
                                        </a>
                                      ) : (
                                        <div className="cell-wrap">
                                          {
                                            typeof data.data[dataIndex] === 'object' && data.data[dataIndex] !== null
                                              ? data.data[dataIndex].map((node) => (node))
                                              : data.data[dataIndex]
                                          }
                                        </div>
                                      )
                                  }
                                </div>
                              </td>
                            ))
                          }
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              )
          }
        </div>
      </div>
    </div>
    {
      children && children
    }
    {pager
      && (
        <div className="table-pager-container">
          <div className={pager.currentPage - 1 < 0 ? 'table-svg-hover disabled' : 'table-svg-hover'}>
            <IosArrowBack onClick={() => handlePagerClick(table, pager.currentPage - 1)} />
          </div>
          {
            pager.totalRows.map(({ value, type }) => (
              type === 'normal'
                ? (
                  <button
                    key={value + type}
                    type="button"
                    className={`${value === pager.currentPage ? 'active ' : ''}table-pager-button`}
                    onClick={() => handlePagerClick(table, value)}
                  >
                    {value + 1}
                  </button>
                )
                : (
                  <div key={value + type} className="table-svg-hover">
                    <IosMore onClick={() => handlePagerClick(table, value)} />
                  </div>
                )
            ))
          }
          <div className={pager.currentPage + 1 > pager.totalRows[pager.totalRows.length - 1].value ? 'table-svg-hover disabled' : 'table-svg-hover'}>
            <IosArrowForward onClick={() => handlePagerClick(table, pager.currentPage + 1)} />
          </div>
        </div>
      )}
  </>
);

Table.propTypes = {
  children: PropTypes.node,
  customStyle: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({}),
  ]),
  dataSource: PropTypes.arrayOf(PropTypes.shape({
    handleRowClick: PropTypes.func,
    data: PropTypes.shape(),
  })).isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.node),
    ]).isRequired,
    key: PropTypes.string.isRequired,
    dataIndex: PropTypes.string.isRequired,
    filters: PropTypes.arrayOf(PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ])),
    render: PropTypes.node,
    handleClick: PropTypes.func,
    handleSelect: PropTypes.func,
  })).isRequired,
  table: PropTypes.string.isRequired,
  filters: PropTypes.arrayOf(PropTypes.shape({
    dataIndex: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  })).isRequired,
  pager: PropTypes.shape(),
  sort: PropTypes.shape(),
  handlePagerClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

Table.defaultProps = {
  pager: null,
  children: null,
  customStyle: null,
  isLoading: false,
  sort: {},
};

export default Table;
