import React from 'react';
import PropTypes from 'prop-types';
import MdCreate from 'react-ionicons/lib/MdCreate';
import MagicTable from 'containers/MagicTable/MagicTable';
import MdRemove from 'react-ionicons/lib/MdTrash';
import MdCheckmarkCircle from 'react-ionicons/lib/MdCheckmark';


const ExamPlanList = ({ examPlans, isMobile, onModalOpen, onDeleteModalOpen, onSubmitModalOpen, isLoading }) => (
  <>
    <div className="profile-page-main-title">EXAM, ACCREDITATION PLAN</div>
    <MagicTable
      table="examPlans"
      isMobile={isMobile}
      isLoading={isLoading}
      outerColumns={[
        { title: 'name', key: 'name', dataIndex: 'name' },
        { title: 'deadline', key: 'deadlineDate', dataIndex: 'deadlineDate' },
        { title: '', key: 'action', dataIndex: 'action' },
      ]}
      render={{
        action: (data) => [
          <MdCreate key="1" className="cursor-pointer" fontSize="15px" onClick={() => onModalOpen(data)} />,
          <MdCheckmarkCircle key="2" className="cursor-pointer" fontSize="15px" color="seagreen" onClick={() => onSubmitModalOpen(data)} />,
          <MdRemove key="3" className="cursor-pointer" fontSize="15px" color="indianred" onClick={() => onDeleteModalOpen(data)} />,
        ],
      }}
      outerDataSource={examPlans}
    />
  </>
);

ExamPlanList.propTypes = {
  examPlans: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isMobile: PropTypes.bool.isRequired,
  onModalOpen: PropTypes.func.isRequired,
  onDeleteModalOpen: PropTypes.func.isRequired,
  onSubmitModalOpen: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default ExamPlanList;
