import { camelCase } from 'lodash';
import {
  COMMON_TABLE_ROW_CLICK,
  APP_MENU_CLICK,
  COMMON_TABLE_CHANGE_TABLE,
  COMMON_TABLE_SUCCEEDED,
  RESTORE_USER_BY_TOKEN_SUCCEEDED,
  USER_LOGOUT_SUCCEEDED,
} from 'consts/actionTypes';
import initialState from './initialState';


export const transformData = (data, index) => Object.entries(data)
  .reduce((prev, [innerKey, value]) => ({
    ...prev,
    data: {
      nr: index + 1,
      ...prev.data,
      [camelCase(innerKey)]: value,
    },
  }), {});

export const tableReducer = (state = initialState, action) => {
  switch (action.type) {
    case COMMON_TABLE_ROW_CLICK: {
      const { table, data } = action.payload;

      return {
        ...state,
        [table]: {
          ...state[table],
          selectedRow: {
            ...data,
          },
        },
      };
    }

    case COMMON_TABLE_SUCCEEDED: {
      const { data, newState } = action.payload;

      return {
        ...state,
        [newState.table]: {
          ...state[newState.table],
          ...newState[newState.table],
          pager: {
            ...data.pager,
          },
          dataSource: [
            ...data.data.map(transformData),
          ],
        },
      };
    }

    case COMMON_TABLE_CHANGE_TABLE:
      return {
        ...state,
        currentTable: action.payload.table,
      };

    case APP_MENU_CLICK:
      return {
        ...state,
        currentTable: action.payload.table.slice(1),
      };

    case RESTORE_USER_BY_TOKEN_SUCCEEDED: {
      return {
        ...state,
        currentTable: action.payload.next.split('-')[0].slice(1),
      };
    }

    // todo: when backend implements filter for this

    // case GET_ABSENCES_SUCCEEDED:
    //   return {
    //     ...state,
    //     absence: {
    //       ...state.absence,
    //       columns: state.absence.columns.map((column) => (column.key !== 'intraAbsenceType'
    //         ? column
    //         : {
    //           ...column,
    //           filters: Object.keys(action.payload.types).map((key) => action.payload.types[key]),
    //         })),
    //     },
    //   };

    case USER_LOGOUT_SUCCEEDED: return initialState;

    default:
      return state;
  }
};

export default tableReducer;
