import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { ReactComponent as AddMore } from 'assets/images/add_new.svg';
import { DatePicker } from 'antd';
import IntraModal from 'components/Modal/Modal';
import ProfileInputField from 'components/ProfileCard/ProfileInputField';
import './exam-plan-modal.scss';


const ExamPlanModal = ({
  onModalOpen, name, deadlineDate,
  showModal, handleClose,
  handleSubmit, onChange, onDateChange,
  nodeId,
}) => {
  const disabledSubmit = (!name || name === '')
    || (!deadlineDate || deadlineDate === '');
  return (
    <>
      <div
        className="add-new-button"
        tabIndex={0}
        role="button"
        onKeyPress={() => { }}
        onClick={onModalOpen}
      >
        <AddMore width={15} height={15} />
        <div>ADD NEW EXAM PLAN</div>
      </div>
      <IntraModal
        title="EXAM PLANS"
        open={showModal}
        close={handleClose}
        submit={() => { handleSubmit(nodeId); }}
        disabled={disabledSubmit}
        okButton={nodeId ? 'CHANGE EXAM PLAN DATA' : 'ADD NEW EXAM PLAN'}
        cancelButton="CANCEL"
        className="exam-plans-modal"
      >
        <div className="profile-page-inputs">
          <ProfileInputField
            name="name"
            title="EXAM PLAN NAME"
            value={name}
            onChange={onChange}
            placeholder="Enter name"
          />
        </div>
        <div className="profile-page-inputs">
          <div className="profile-card-input-container">
            <div className="profile-card-input-label">DEADLINE</div>
            <DatePicker
              value={!deadlineDate ? deadlineDate : moment(deadlineDate)}
              onChange={(date, dateString) => onDateChange(date, dateString, 'deadlineDate')}
              placeholder="Select date"
            />
          </div>
        </div>
      </IntraModal>
    </>
  );
};

ExamPlanModal.propTypes = {
  onModalOpen: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  deadlineDate: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  showModal: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onDateChange: PropTypes.func.isRequired,
  nodeId: PropTypes.number,
};

ExamPlanModal.defaultProps = {
  deadlineDate: null,
  nodeId: undefined,
};

export default ExamPlanModal;
