import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { ReactComponent as AddMore } from 'assets/images/add_new.svg';
import { DatePicker } from 'antd';
import IntraModal from 'components/Modal/Modal';
import ProfileInputField from 'components/ProfileCard/ProfileInputField';
import './dependants-modal.scss';


const DependantsModal = ({
  onModalOpen, childName, childTaxNumber, childTajNumber,
  childsMotherName, childsBirthday, showModal, handleClose,
  handleSubmit, onChange, onDateChange, isTajNumberValid,
  validateTajNumber, validateTaxNumber, isTaxNumberValid,
  nodeId,
}) => {
  const disabledSubmit = (!childName || childName === '')
    || (!childTaxNumber || childTaxNumber === '')
    || (!childsMotherName || childsMotherName === '')
    || (!childTajNumber || childTajNumber === '')
    || (!childsBirthday || childsBirthday === '');
  return (
    <>
      <div
        className="add-new-button"
        tabIndex={0}
        role="button"
        onKeyPress={() => { }}
        onClick={onModalOpen}
      >
        <AddMore width={15} height={15} />
        <div>ADD NEW CHILD</div>
      </div>
      <IntraModal
        title="DEPENDANTS"
        open={showModal}
        close={handleClose}
        submit={() => { handleSubmit(nodeId); }}
        disabled={disabledSubmit}
        shouldntClose
        okButton={nodeId ? 'CHANGE CHILD DATA' : 'ADD NEW CHILD'}
        cancelButton="CANCEL"
        className="dependants-modal"
      >
        <div className="profile-page-inputs">
          <ProfileInputField
            name="childName"
            title="CHILD'S NAME"
            value={childName}
            onChange={onChange}
            placeholder="Enter name"
          />
          <ProfileInputField
            name="childTaxNumber"
            title="TAX NUMBER"
            value={childTaxNumber}
            onChange={onChange}
            isInvalid={isTaxNumberValid}
            handleBlur={validateTaxNumber}
            placeholder="Enter tax number"
          />
        </div>
        <div className="profile-page-inputs">
          <div className="profile-card-input-container">
            <div className="profile-card-input-label">BIRTHDAY</div>
            <DatePicker
              value={!childsBirthday ? childsBirthday : moment(childsBirthday)}
              onChange={(date, dateString) => onDateChange(date, dateString, 'childsBirthday')}
              placeholder="Select date"
            />
          </div>
          <ProfileInputField
            name="childTajNumber"
            title="SOCIAL SECURITY NUMBER"
            value={childTajNumber}
            onChange={onChange}
            isInvalid={isTajNumberValid}
            handleBlur={validateTajNumber}
            placeholder="Enter social security number"
          />
        </div>
        <div className="profile-page-inputs">
          <ProfileInputField
            name="childsMotherName"
            title="MOTHER'S NAME"
            value={childsMotherName}
            onChange={onChange}
            placeholder="Enter mother's name"
          />
          <div className="profile-card-input-container" />
        </div>
      </IntraModal>
    </>
  );
};

DependantsModal.propTypes = {
  onModalOpen: PropTypes.func.isRequired,
  childName: PropTypes.string.isRequired,
  childTaxNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  childTajNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  childsMotherName: PropTypes.string.isRequired,
  childsBirthday: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  showModal: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onDateChange: PropTypes.func.isRequired,
  isTajNumberValid: PropTypes.bool.isRequired,
  validateTajNumber: PropTypes.func.isRequired,
  isTaxNumberValid: PropTypes.bool.isRequired,
  validateTaxNumber: PropTypes.func.isRequired,
  nodeId: PropTypes.number,
};

DependantsModal.defaultProps = {
  childsBirthday: null,
  childTaxNumber: null,
  childTajNumber: null,
  nodeId: undefined,
};

export default DependantsModal;
