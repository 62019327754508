import React from 'react';
import PropTypes from 'prop-types';
import ProfileInputField from 'components/ProfileCard/ProfileInputField';
import { Select, Button } from 'antd';


const ProfileForm = ({
  handleChange, permanentAddress, mailingAddress, skype,
  slack, email, phone, maritalStatus, bankAccount,
  maritalStatuses, handleSelectChange, saveChanges,
  validatePhone, isPhoneValid, validateEmail, isEmailValid,
  validateBankAccount, isBankAccountValid,
}) => {
  const selectPlaceholder = !maritalStatus ? 'Enter marital status' : '';
  return (
    <>
      <div className="button-container">
        <Button type="primary" shape="round" onClick={saveChanges}>SAVE CHANGES</Button>
      </div>
      <div className="profile-page-main-title">ADDRESS</div>
      <div className="profile-page-inputs">
        <ProfileInputField
          name="permanentAddress"
          title="PERMANENT ADDRESS"
          value={permanentAddress}
          onChange={handleChange}
          placeholder="Enter permanent address"
        />
        <ProfileInputField
          name="mailingAddress"
          title="MAILING ADDRESS"
          value={mailingAddress}
          onChange={handleChange}
          placeholder="Enter mailing address"
        />
      </div>
      <div className="profile-page-main-title">CONTACT</div>
      <div className="profile-page-inputs">
        <ProfileInputField
          name="skype"
          title="SKYPE"
          value={skype}
          onChange={handleChange}
          placeholder="Enter skype name"
        />
        <ProfileInputField
          name="phone"
          title="PHONE"
          value={phone}
          onChange={handleChange}
          placeholder="Enter phone number"
          handleBlur={validatePhone}
          isInvalid={isPhoneValid}
          errorMessage="Format: +36301234567"
        />
      </div>
      <div className="profile-page-inputs">
        <ProfileInputField
          name="slack"
          title="SLACK"
          value={slack}
          onChange={handleChange}
          placeholder="Enter slack name"
        />
        <ProfileInputField
          name="email"
          title="EMAIL"
          value={email}
          onChange={handleChange}
          handleBlur={validateEmail}
          isInvalid={isEmailValid}
          placeholder="Enter email"
        />
      </div>
      <div className="profile-page-main-title">OTHER</div>
      <div className="profile-page-inputs">
        <ProfileInputField
          name="bankAccount"
          title="BANK ACCOUNT NUMBER"
          value={bankAccount}
          onChange={handleChange}
          handleBlur={validateBankAccount}
          isInvalid={isBankAccountValid}
          placeholder="Enter bank account number"
        />
        <div className="profile-card-input-container">
          <div className="profile-card-input-label">MARITAL STATUS</div>
          <Select
            showArrow
            placeholder={selectPlaceholder}
            onChange={handleSelectChange}
            value={maritalStatus}
          >
            {maritalStatuses.map((item, index) => (
              <Select.Option value={item.value} key={index.toString()}>{item.display}</Select.Option>
            ))}
          </Select>
        </div>
      </div>
    </>
  );
};

ProfileForm.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleSelectChange: PropTypes.func.isRequired,
  permanentAddress: PropTypes.string.isRequired,
  mailingAddress: PropTypes.string.isRequired,
  skype: PropTypes.string.isRequired,
  slack: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  maritalStatus: PropTypes.string.isRequired,
  bankAccount: PropTypes.string.isRequired,
  maritalStatuses: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  saveChanges: PropTypes.func.isRequired,
  validatePhone: PropTypes.func.isRequired,
  isPhoneValid: PropTypes.bool.isRequired,
  validateEmail: PropTypes.func.isRequired,
  isEmailValid: PropTypes.bool.isRequired,
  validateBankAccount: PropTypes.func.isRequired,
  isBankAccountValid: PropTypes.bool.isRequired,
};

export default ProfileForm;
