import React from 'react';
import 'material-icons/iconfont/outlined.css';
import PropTypes from 'prop-types';

const CelebrationOutlined = ({ fontSize }) => {
  const style = {
    fontSize,
  };
  return (
    <span style={style} className="material-icons-outlined">celebration</span>
  );
};

CelebrationOutlined.propTypes = {
  fontSize: PropTypes.string.isRequired,
};

export default CelebrationOutlined;
