import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'antd';


class IntraModal extends Component {
  handleOk = () => {
    const { submit, close, shouldntClose } = this.props;
    submit();
    if (!shouldntClose) {
      close();
    }
  };

  handleCancel = () => {
    const { close } = this.props;
    close();
  };

  render() {
    const {
      children, open, title, okButton, cancelButton, okButtonDisabled, afterClose, className, notEditable,
    } = this.props;

    return (
      <div>
        <Modal
          title={title}
          visible={open}
          className={className}
          afterClose={afterClose}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              {cancelButton}
            </Button>,
            ...(!notEditable
              ? [
                <Button key="submit" type="primary" onClick={this.handleOk} disabled={okButtonDisabled}>
                  {okButton}
                </Button>,
              ] : []),
          ]}
        >
          <div style={{ textAlign: 'center', fontSize: '12px', lineHeight: 1.5 }}>
            {children}
          </div>
        </Modal>
      </div>
    );
  }
}

IntraModal.propTypes = {
  children: PropTypes.node.isRequired,
  open: PropTypes.bool,
  notEditable: PropTypes.bool,
  title: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  okButton: PropTypes.string.isRequired,
  cancelButton: PropTypes.string.isRequired,
  shouldntClose: PropTypes.bool,
  okButtonDisabled: PropTypes.bool,
  afterClose: PropTypes.func,
  className: PropTypes.string,
};

IntraModal.defaultProps = {
  className: '',
  open: false,
  shouldntClose: false,
  okButtonDisabled: false,
  afterClose: () => { },
  notEditable: false,
};

export default IntraModal;
