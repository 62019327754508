import React from 'react';
import PropTypes from 'prop-types';

const StatusCard = ({ array, title }) => (array.length ? (
  <div>
    {title}
    {array.map((filtered) => (
      <div key={filtered} className="selected-value">{filtered}</div>
    ))}
  </div>
) : null
);

StatusCard.propTypes = {
  array: PropTypes.arrayOf(PropTypes.string).isRequired,
  title: PropTypes.string.isRequired,
};

export default StatusCard;
