import React, { useState } from 'react';
import PropTypes from 'prop-types';
import IntraModal from 'components/Modal/Modal';
import { DatePicker } from 'antd';
import moment from 'moment';
import ProfileInputField from '../ProfileCard/ProfileInputField';

const CompleteExamPlanModal = ({
  name, showModal, handleClose, onSubmit, nodeId,
}) => {
  const [acquiredAtDate, setAcquiredAtDate] = useState(moment(new Date()));
  const [expiresAtDate, setExpiresAtDate] = useState(moment(new Date()).add(3, 'y'));

  const handleSubmit = () => {
    onSubmit({ acquiredAtDate, expiresAtDate, name, nodeId });
  };

  if (!nodeId || !name) return null;

  return (
    <>
      <IntraModal
        title="COMPLETE EXAM PLAN"
        open={showModal}
        close={handleClose}
        submit={handleSubmit}
        okButton="COMPLETE"
        cancelButton="CANCEL"
      >
        <div className="profile-page-inputs">
          <ProfileInputField
            name="name"
            title="EXAM PLAN NAME"
            placeholder=""
            value={name}
            disabled
          />
        </div>
        <div className="profile-page-inputs">
          <div className="profile-card-input-container">
            <div className="profile-card-input-label">ACQUIRED AT</div>
            <DatePicker
              value={acquiredAtDate}
              onChange={(date) => setAcquiredAtDate(date)}
              placeholder="Select date"
            />
          </div>
          <div className="profile-card-input-container">
            <div className="profile-card-input-label">EXPIRES AT</div>
            <DatePicker
              value={expiresAtDate}
              onChange={(date) => setExpiresAtDate(date)}
              placeholder="Select date"
            />
          </div>
        </div>
      </IntraModal>
    </>
  );
};

CompleteExamPlanModal.propTypes = {
  name: PropTypes.string,
  showModal: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  nodeId: PropTypes.string,
};

CompleteExamPlanModal.defaultProps = {
  name: undefined,
  nodeId: undefined,
};

export default CompleteExamPlanModal;
