
import axios from 'axios';
import { snakeCase, camelCase, mapKeys } from 'lodash';
import { requestInterceptor, responseInterceptor } from 'services/interceptor';

export const convertRequestAttributes = (data) => {
  try {
    if (data && data.data && data.data.attributes) {
      const transformedData = data;
      transformedData.data.attributes = mapKeys(transformedData.data.attributes,
        (value, key) => snakeCase(key));
      return JSON.stringify(transformedData);
    }
    return JSON.stringify(data);
  } catch (e) {
    return data;
  }
};

export const convertResponseAttributes = (response) => {
  try {
    const data = JSON.parse(response);
    if (data.data && data.data.attributes) {
      const transformedData = data;
      transformedData.data.attributes = mapKeys(transformedData.data.attributes,
        (value, key) => camelCase(key));
      return transformedData;
    }
    return data;
  } catch (e) {
    return response;
  }
};

const api = axios.create({
  transformRequest: [convertRequestAttributes],
  transformResponse: [convertResponseAttributes],
  headers: {
    'Content-Type': 'application/json; charset=UTF-8',
    Accept: 'application/vnd.api+json',
    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
  },
});

export const postLoginData = (url, formData) => axios({
  method: 'POST',
  url,
  headers: {
    'Content-Type': 'multipart/form-data',
    Accept: 'application/vnd.api+json',
  },
  data: formData,
}).then((response) => {
  api.defaults.headers.Authorization = `Bearer ${response.data.access_token}`;
  return response.data;
});

export const uploadFile = (url, buffer, fileName) => axios({
  method: 'POST',
  url,
  data: buffer,
  headers: {
    'Content-Type': 'application/octet-stream',
    'Content-Disposition': `file; filename="${fileName}"`,
    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
  },
});

export const getData = (url) => api.get(url).then((response) => response.data);

export const postData = (url, data, config) => (
  api.post(url, data).then((response) => (response.data.data), config)
);

export const patchData = (url, data, config) => (
  api.patch(url, data).then((response) => (response.data.data), config)
);

export const deleteData = (url, config) => (
  api.delete(url).then((response) => (response.data.data), config)
);

export const updateData = (url, type, attributes) => (
  api.update(url, {
    data: {
      type,
      attributes,
    },
  }).then((response) => (response.data.data))
);

const setHeader = (token) => {
  api.defaults.headers.Authorization = `Bearer ${token}`;
};

responseInterceptor.setupInterceptors(api, setHeader);
requestInterceptor.setupInterceptors(api, setHeader);
