const initialState = {
  types: {},
  summary: {},
  details: {},
  isLoading: false,
  hasLoadingError: false,
  errorMessage: '',
};

export default initialState;
