import React from 'react';
import PropTypes from 'prop-types';
import MdClose from 'react-ionicons/lib/MdClose';
import { Popover, Collapse } from 'antd';
import IntraAvatar from 'components/IntraAvatar/IntraAvatar';
import ColleagueCard from 'components/ColleagueCard/ColleagueCard';
import getStatusColor from 'styles/statusColors';

const { Panel } = Collapse;


class ColleagueModal extends React.Component {
  state = {
    visible: false,
  };

  hide = () => {
    this.setState({
      visible: false,
    });
  };

  handleVisibleChange = (visible) => {
    this.setState({ visible });
  };

  render() {
    const { profilePicture, lastStatus, firstName, lastName, role, phone, email, width } = this.props;
    const { visible } = this.state;
    return width > 768
      ? (
        <Popover
          trigger="click"
          placement="rightTop"
          onVisibleChange={this.handleVisibleChange}
          visible={visible}
          content={(
            <div className="colleague-modal-wrapper">
              <MdClose
                className="colleague-modal-close"
                fontSize="16px"
                onClick={this.hide}
              />
              <IntraAvatar
                size={145}
                src={profilePicture}
                status={lastStatus}
                firstName={firstName}
                lastName={lastName}
                isDisabled
              />
              <div className="colleague-modal-name">{`${firstName} ${lastName}`}</div>
              <div className="colleague-modal-role">{role}</div>
              <div className="colleague-modal-status" style={{ color: getStatusColor(lastStatus) }}>{lastStatus}</div>
              <div className="colleague-modal-info-box">
                {
                  phone !== ''
                    ? (
                      <div className="colleague-modal-info-box-section phone">
                        <div className="colleague-modal-info-box-title">PHONE</div>
                        <a className="colleague-modal-info-box-value" href={`tel:${phone}`}>{phone}</a>
                      </div>
                    ) : null
                }
                <div className="colleague-modal-info-box-section">
                  <div className="colleague-modal-info-box-title">EMAIL</div>
                  <div className="colleague-modal-info-box-value">{email}</div>
                </div>
              </div>
            </div>
          )}
        >
          <div className="colleague-card-container">
            <ColleagueCard
              profilePicture={profilePicture}
              lastStatus={lastStatus}
              firstName={firstName}
              lastName={lastName}
              role={role}
            />
          </div>
        </Popover>
      )
      : (
        <Collapse>
          <Panel
            header={(
              <div className="colleague-card-container">
                <ColleagueCard
                  profilePicture={profilePicture}
                  lastStatus={lastStatus}
                  firstName={firstName}
                  lastName={lastName}
                  role={role}
                />
              </div>
            )}
            key="1"
            showArrow={false}
          >
            <div className="colleague-modal-info-box">
              {
                phone !== ''
                  ? (
                    <div className="colleague-modal-info-box-section phone">
                      <div className="colleague-modal-info-box-title">PHONE</div>
                      <a className="colleague-modal-info-box-value" href={`tel:${phone}`}>{phone}</a>
                    </div>
                  ) : null
              }
              <div className="colleague-modal-info-box-section">
                <div className="colleague-modal-info-box-title">EMAIL</div>
                <div className="colleague-modal-info-box-value">{email}</div>
              </div>
            </div>
          </Panel>
        </Collapse>
      );
  }
}

ColleagueModal.propTypes = {
  profilePicture: PropTypes.string.isRequired,
  lastStatus: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
};


export default ColleagueModal;
