import React from 'react';
import PropTypes from 'prop-types';
import IosArrowBack from 'react-ionicons/lib/IosArrowBack';
import IosArrowForward from 'react-ionicons/lib/IosArrowForward';
import IosMore from 'react-ionicons/lib/IosMore';
import IosArrowUp from 'react-ionicons/lib/IosArrowUp';
import { Collapse } from 'antd';
import './mobile-table.scss';


export const MobileTable = ({ dataSource, columns, table, pager, handlePagerClick, children }) => {
  const { Panel } = Collapse;
  const tableContent = dataSource.map((data, index) => (
    <tr
      key={index.toString()}
      className="ivu-table-row ivu-card-body"
      onClick={() => (data.handleRowClick ? data.handleRowClick(table, data) : () => { })}
    >
      {
        columns.map(({ dataIndex, title }) => (
          <td key={dataIndex}>
            <div className="ivu-table-cell">
              <div className="cell-wrap">
                <p className={`column-head-title${table === 'contribBonus' ? ' contrib' : ''}`}>
                  {title}
                </p>
                {
                  dataIndex === 'taskId' || dataIndex === 'taskSubject'
                    ? (
                      <a href={data.data.taskUrl} target="_blank" rel="noopener noreferrer">
                        <p>
                          {
                            typeof data.data[dataIndex] === 'object'
                              ? (
                                <span className="table-actions">{data.data[dataIndex].map((node) => (node))}</span>
                              )
                              : data.data[dataIndex]
                          }
                        </p>
                      </a>
                    ) : (
                      <p>
                        {
                          typeof data.data[dataIndex] === 'object'
                            ? (
                              <span className="table-actions">{data.data[dataIndex] && data.data[dataIndex].map((node) => (node))}</span>
                            )
                            : data.data[dataIndex]
                        }
                      </p>
                    )
                }
              </div>
            </div>
          </td>
        ))
      }
      {
        data.handleRowClick && (
          <td className="expand-icon">
            <div className="ivu-table-cell">
              <div className="cell-wrap">
                <p className="column-head-title">details</p>
                <IosArrowUp className="arrow-transform" />
              </div>
            </div>
          </td>
        )
      }
    </tr>
  ));

  return (
    <div className="custom-table ivu-table-wrapper mobile">
      <div className="ivu-table">
        <table cellSpacing="0" cellPadding="0" border="0">
          <tbody className="ivu-table-body">
            {
              children
                ? (
                  <Collapse accordion>
                    {
                      tableContent.map((content, index) => (
                        <Panel showArrow={false} header={content} key={index.toString()}>
                          {children}
                        </Panel>
                      ))
                    }
                  </Collapse>
                )
                : tableContent
            }
          </tbody>
        </table>
      </div>
      {pager
        && (
          <div className="table-pager-container">
            <div className={pager.currentPage - 1 < 0 ? 'table-svg-hover disabled' : 'table-svg-hover'}>
              <IosArrowBack onClick={() => handlePagerClick(table, pager.currentPage - 1)} />
            </div>
            {
              pager.totalRows.map(({ value, type }) => (
                type === 'normal'
                  ? (
                    <button
                      key={value + type}
                      type="button"
                      className={`${value === pager.currentPage ? 'active ' : ''}table-pager-button`}
                      onClick={() => handlePagerClick(table, value)}
                    >
                      {value + 1}
                    </button>
                  )
                  : (
                    <div key={value + type} className="table-svg-hover">
                      <IosMore onClick={() => handlePagerClick(table, value)} />
                    </div>
                  )
              ))
            }
            <div className={pager.currentPage + 1 > pager.totalRows[pager.totalRows.length - 1].value ? 'table-svg-hover disabled' : 'table-svg-hover'}>
              <IosArrowForward onClick={() => handlePagerClick(table, pager.currentPage + 1)} />
            </div>
          </div>
        )}
    </div>
  );
};

MobileTable.propTypes = {
  dataSource: PropTypes.arrayOf(PropTypes.shape({
    handleRowClick: PropTypes.func,
    data: PropTypes.shape(),
  })).isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.node),
    ]).isRequired,
    key: PropTypes.string.isRequired,
    dataIndex: PropTypes.string.isRequired,
    filters: PropTypes.arrayOf(PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ])),
    render: PropTypes.node,
    handleClick: PropTypes.func,
    handleSelect: PropTypes.func,
  })).isRequired,
  table: PropTypes.string.isRequired,
  handlePagerClick: PropTypes.func.isRequired,
  pager: PropTypes.shape(),
  children: PropTypes.node,
};

MobileTable.defaultProps = {
  pager: null,
  children: null,
};

export default MobileTable;
