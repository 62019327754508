import { takeEvery, call, put, select, all, take } from 'redux-saga/effects';
import { notification } from 'antd';
import {
  WIKI_GET_TOPICS_BY_CATEGORY_REQUESTED, WIKI_GET_TOPICS_BY_CATEGORY_FAILED, WIKI_GET_TOPICS_BY_CATEGORY_SUCCEEDED,
  WIKI_GET_CHANGELOG_SUCCEEDED, WIKI_GET_CHANGELOG_FAILED, WIKI_GET_CATEGORIES_SUCCEEDED,
  WIKI_GET_CATEGORIES_FAILED, WIKI_GET_HISTORIES_REQUESTED, WIKI_GET_HISTORIES_SUCCEEDED,
  WIKI_GET_HISTORIES_FAILED, WIKI_SEARCH_SUCCEEDED, WIKI_SEARCH_FAILED, WIKI_EMPTY_SEARCH_VALUES,
  WIKI_SEARCH_REQUESTED, WIKI_GET_CHANGELOG_REQUESTED, WIKI_GET_CATEGORIES_REQUESTED,
  WIKI_GET_TAGS_SUCCEEDED, WIKI_GET_TAGS_FAILED, WIKI_GET_TAGS_REQUESTED,
  WIKI_SET_SEARCH_VALUE, WIKI_SET_SELECTED_TAGS, WIKI_CLOSE_SELECTED_TAG, WIKI_EMPTY_SELECTED_TAGS,
  WIKI_GET_TOPIC_REQUESTED, WIKI_GET_TOPIC_FAILED,
  SELECT_WIKI_CATEGORY, SELECT_TOPIC, WIKI_CATEGORY_SELECT_FAILED,
  WIKI_TOPIC_SELECT_FAILED, SELECT_WIKI_HISTORY,
} from 'consts/actionTypes';
import * as config from 'services/config';
import * as API from 'services/api';
import treeSearch from 'services/tree-search';

const { history } = config;

const openNotificationWithIcon = (type, message, description) => {
  notification[type]({
    duration: 3,
    message,
    description,
  });
};

const getWikiReducer = (state) => state.wikiReducer;

export function* getTopicsByCategory(action) {
  try {
    const { categoryName, id } = action.payload;
    const data = yield call(API.getData, `${config.host}/api/wiki/${id}/topics`);
    yield put({
      type: WIKI_GET_TOPICS_BY_CATEGORY_SUCCEEDED,
      payload: {
        data: data.data
          ? data.data
          : data,
        categoryName,
      },
    });
  } catch (error) {
    yield put({
      type: WIKI_GET_TOPICS_BY_CATEGORY_FAILED,
      payload: {
        errorMessage: error.message,
      },
    });
    console.log(error); // eslint-disable-line
  }
}

export function* getChangelog() {
  try {
    const data = yield call(API.getData, `${config.host}/api/wiki-topics/changelog`);
    yield put({
      type: WIKI_GET_CHANGELOG_SUCCEEDED,
      payload: {
        data: data.data
          ? data.data
          : data,
      },
    });
  } catch (error) {
    yield put({
      type: WIKI_GET_CHANGELOG_FAILED,
      payload: {
        errorMessage: error.message,
      },
    });
    console.log(error); // eslint-disable-line
  }
}

export function* getCategories() {
  try {
    const { categories } = yield select(getWikiReducer);
    if (!categories) {
      const data = yield call(API.getData, `${config.host}/api/list/taxonomy_term/wiki_category`);
      yield put({
        type: WIKI_GET_CATEGORIES_SUCCEEDED,
        payload: {
          data: data.data
            ? data.data
            : data,
        },
      });
    }
  } catch (error) {
    yield put({
      type: WIKI_GET_CATEGORIES_FAILED,
      payload: {
        errorMessage: error.message,
      },
    });
    console.log(error); // eslint-disable-line
  }
}

export function* getHistory(action) {
  try {
    const { ids } = action.payload;
    const { histories } = yield select(getWikiReducer);
    if (ids && ids.length) {
      if (!histories || ids.filter((item) => Object.keys(histories).indexOf(item) === -1).length) {
        const data = yield all(ids.map((id) => call(API.getData, `${config.host}/api/wiki/topic/${id}`)));
        yield put({
          type: WIKI_GET_HISTORIES_SUCCEEDED,
          payload: {
            data: data.map((his) => (his.data ? his.data : his)),
          },
        });
      } else {
        yield put({ type: SELECT_WIKI_HISTORY });
      }
    }
  } catch (error) {
    yield put({
      type: WIKI_GET_HISTORIES_FAILED,
      payload: {
        errorMessage: error.message,
      },
    });
    console.log(error); // eslint-disable-line
  }
}

export function* requestSearch() {
  const { selectedTags, searchValue } = yield select(getWikiReducer);
  yield put({
    type: WIKI_EMPTY_SEARCH_VALUES,
  });
  if ((searchValue.length > 0) || selectedTags.length > 0) {
    yield put({
      type: WIKI_SEARCH_REQUESTED,
    });
  }
}

export function* search() {
  try {
    const { selectedTags, searchValue } = yield select(getWikiReducer);
    const data = yield call(
      API.getData,
      `${config.host}/api/wiki/search?${searchValue && `keyword=${searchValue}&`}${selectedTags.map((tag) => `tag_id[]=${tag}`).join('&')}`,
    );
    yield put({
      type: WIKI_SEARCH_SUCCEEDED,
      payload: {
        data: data.data
          ? data.data
          : data,
      },
    });
  } catch (error) {
    yield put({
      type: WIKI_SEARCH_FAILED,
      payload: {
        errorMessage: error.message,
      },
    });
    console.log(error); // eslint-disable-line
  }
}

export function* getTopic(action) {
  try {
    const { categoryName, categoryId, topicId } = action.payload;
    const data = yield call(API.getData, `${config.host}/api/wiki/${categoryId}/topics`);
    const findTopic = (id, list) => {
      let result = list.find((topic) => topic.id === id);
      if (result) {
        return result;
      }
      list.forEach((topic) => {
        if (topic.subTopics.length > 0) {
          const match = findTopic(id, topic.subTopics);
          if (match) {
            result = match;
          }
        }
      });
      return result;
    };
    yield put({
      type: WIKI_GET_TOPICS_BY_CATEGORY_SUCCEEDED,
      payload: {
        data: data.data
          ? data.data
          : data,
        categoryName,
      },
    });
    const topic = findTopic(topicId, data);
    yield put({ type: SELECT_TOPIC, payload: { topic } });
    history.push(`/wiki/${categoryName.replace(' ', '-')}/${topic.id}`);
  } catch {
    yield put({
      type: WIKI_GET_TOPIC_FAILED,
    });
  }
}

export function* getTags() {
  try {
    const { tags } = yield select(getWikiReducer);
    if (!tags) {
      const data = yield call(API.getData, `${config.host}/api/wiki/tags`);
      yield put({
        type: WIKI_GET_TAGS_SUCCEEDED,
        payload: {
          data: data.data
            ? data.data
            : data,
        },
      });
    }
  } catch (error) {
    yield put({
      type: WIKI_GET_TAGS_FAILED,
      payload: {
        errorMessage: error.message,
      },
    });
    console.log(error); // eslint-disable-line
  }
}

export function* selectCategory({ payload }) {
  try {
    const { category, id } = payload;
    if (id !== 0) {
      yield take(WIKI_GET_CATEGORIES_SUCCEEDED);
    }
    const { categories, topics } = yield select(getWikiReducer);
    let topic = null;
    if (category === 'topic') {
      const data = yield call(API.getData, `${config.host}/api/wiki/topic-by-id/${id}`);
      const categoryName = Object.keys(categories).find((item) => categories[item].id === data.category_id);
      if (categoryName) {
        window.location.href = `/wiki/${categoryName}/${id}`;
        return;
      }
    }
    if (categories[category]) {
      if (!topics || !topics[category]) {
        const categoryId = categories[category].id;
        const data = yield call(API.getData, `${config.host}/api/wiki/${categoryId}/topics`);
        topic = data.data || data;
        if (!topic.length) {
          openNotificationWithIcon('warning', 'warning', `${category} category topics has not been uploaded yet.`);
        }
        yield put({
          type: WIKI_GET_TOPICS_BY_CATEGORY_SUCCEEDED,
          payload: {
            data: topic,
            categoryName: category,
          },
        });
      } else {
        topic = topics[category];
      }
      if (id === 0) {
        yield put({ type: SELECT_TOPIC, payload: { topic: topic[0] } });
        history.push(`/wiki/${category}/${topic[0].id}`);
      } else {
        const find = treeSearch('subTopics');
        const selectedTopic = find(topic, 'id', id.toString());
        if (selectedTopic) {
          yield put({ type: SELECT_TOPIC, payload: { topic: selectedTopic } });
        } else {
          history.push('/wiki');
        }
      }
    } else {
      history.push('/wiki');
    }
  } catch (error) {
    yield put({
      type: WIKI_CATEGORY_SELECT_FAILED,
      payload: {
        errorMessage: error.message,
      },
    });
    console.log(error); // eslint-disable-line
  }
}

export function* selectTopic({ payload }) {
  try {
    const { topic } = payload;
    const { selectedLang, backupLang } = yield select(getWikiReducer);
    const currentLang = topic[selectedLang] ? selectedLang : backupLang;
    yield call(getHistory, { payload: { ids: topic[currentLang].history } });
  } catch (error) {
    yield put({
      type: WIKI_TOPIC_SELECT_FAILED,
      payload: {
        errorMessage: error.message,
      },
    });
    console.log(error); // eslint-disable-line
  }
}

export const wikiSagas = [
  takeEvery(WIKI_GET_TOPICS_BY_CATEGORY_REQUESTED, getTopicsByCategory),
  takeEvery(WIKI_GET_CHANGELOG_REQUESTED, getChangelog),
  takeEvery(WIKI_GET_CATEGORIES_REQUESTED, getCategories),
  takeEvery(WIKI_GET_HISTORIES_REQUESTED, getHistory),
  takeEvery(WIKI_SEARCH_REQUESTED, search),
  takeEvery(WIKI_SET_SEARCH_VALUE, requestSearch),
  takeEvery(WIKI_SET_SELECTED_TAGS, requestSearch),
  takeEvery(WIKI_CLOSE_SELECTED_TAG, requestSearch),
  takeEvery(WIKI_EMPTY_SELECTED_TAGS, requestSearch),
  takeEvery(WIKI_GET_TAGS_REQUESTED, getTags),
  takeEvery(SELECT_WIKI_CATEGORY, selectCategory),
  takeEvery(SELECT_TOPIC, selectTopic),
  takeEvery(WIKI_GET_TOPIC_REQUESTED, getTopic),
];
