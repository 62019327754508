import React, { Children } from 'react';
import PropTypes from 'prop-types';

export const AbsenceDots = ({ greenCount, yellowCount, redCount, grayDotCount }) => (
  <div className="absence-dots-container">
    {Children.toArray(Array.from({ length: greenCount }, () => (
      <span className="dot dot-green" />
    )))}
    {Children.toArray(Array.from({ length: yellowCount }, () => (
      <span className="dot dot-yellow" />
    )))}
    {Children.toArray(Array.from({ length: redCount }, () => (
      <span className="dot dot-red" />
    )))}
    {Children.toArray(Array.from({ length: grayDotCount }, () => (
      <span className="dot dot-gray" />
    )))}
  </div>
);

AbsenceDots.propTypes = {
  greenCount: PropTypes.number,
  yellowCount: PropTypes.number,
  redCount: PropTypes.number,
  grayDotCount: PropTypes.number,
};

AbsenceDots.defaultProps = {
  greenCount: 0,
  yellowCount: 0,
  redCount: 0,
  grayDotCount: 0,
};
