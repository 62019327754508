
import {
  GET_COLLEAGUES_REQUESTED, GET_COLLEAGUES_SUCCEEDED, GET_COLLEAGUES_FAILED, EMPTY_COLLEAGUES,
  USER_LOGOUT_SUCCEEDED, SEARCH_COLLEAGUE, REMOVE_COLLEAGUE_SEARCH_FIELD,
  ROLE_FILTER, STATUS_FILTER, CLEAR_COLLEAGUE_FILTER,
} from 'consts/actionTypes';
import initialState from 'reducers/colleagues/initialState';

export const filterColleagues = ({ roleArray, statusArray, searchField, state }) => {
  let filteredArray = state.currentStatus
    .filter((colleague) => roleArray.find((option) => colleague.role === option))
    .filter((colleague) => statusArray.find((option) => colleague.last_status === option));
  if (searchField !== '') {
    const normalizedSearchField = searchField.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    filteredArray = filteredArray
      .filter((colleague) => {
        const intraLastName = colleague.intra_last_name.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        const intraFirstName = colleague.intra_first_name.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        return intraLastName.toUpperCase().includes(normalizedSearchField.toUpperCase())
          || intraFirstName.toUpperCase().includes(normalizedSearchField.toUpperCase());
      });
  }
  return filteredArray;
};

export const filterRoles = (filterArray, state) => (filterArray.length > 0
  ? state.roles.filter((role) => filterArray.find((filteredRole) => (filteredRole.value ? filteredRole.value : filteredRole) === role.value))
  : state.roles).map((role) => role.value);

export const filterStatus = (filterArray, state) => (filterArray.length > 0
  ? state.attendanceStatus
    .filter((status) => filterArray.find((filteredStatus) => (filteredStatus.value ? filteredStatus.value : filteredStatus) === status.value))
  : state.attendanceStatus).map((status) => status.value);

export const colleaguesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_COLLEAGUES_SUCCEEDED: {
      const { currentStatus, attendanceStatus, roles } = action.payload;
      const attendanceStatuses = Object.keys(attendanceStatus).map((attendance) => ({
        value: attendanceStatus[attendance],
        label: attendanceStatus[attendance],
      }));
      const allRoles = Object.keys(roles).map((role) => ({
        value: roles[role],
        label: roles[role],
      }));
      const currentStatuses = Object.keys(currentStatus).map((status) => currentStatus[status]);
      return {
        ...state,
        currentStatus: currentStatuses,
        attendanceStatus: attendanceStatuses,
        filteredColleagues: currentStatuses,
        filteredRoles: [],
        filteredStatuses: [],
        roles: allRoles,
        isLoading: false,
        errorMessage: '',
        hasLoadingError: false,
      };
    }

    case EMPTY_COLLEAGUES: return initialState;

    case ROLE_FILTER: {
      const { filteredRoles } = action.payload;
      return {
        ...state,
        filteredRoles: filteredRoles.length > 0 ? filterRoles(filteredRoles, state) : [],
        filteredColleagues: filterColleagues({
          roleArray: filterRoles(filteredRoles, state),
          statusArray: filterStatus(state.filteredStatuses, state),
          searchField: state.searchField,
          state,
        }),
      };
    }

    case CLEAR_COLLEAGUE_FILTER:
      return {
        ...state,
        filteredRoles: [],
        filteredStatuses: [],
        filteredColleagues: filterColleagues({
          roleArray: state.roles.map((role) => role.value),
          statusArray: state.attendanceStatus.map((attendance) => attendance.value),
          searchField: state.searchField,
          state,
        }),
      };

    case STATUS_FILTER: {
      const { filteredStatuses } = action.payload;
      return {
        ...state,
        filteredStatuses: filteredStatuses.length > 0 ? filterStatus(filteredStatuses, state) : [],
        filteredColleagues: filterColleagues({
          roleArray: filterRoles(state.filteredRoles, state),
          statusArray: filterStatus(filteredStatuses, state),
          searchField: state.searchField,
          state,
        }),
      };
    }

    case GET_COLLEAGUES_REQUESTED:
      return {
        ...state,
        isLoading: true,
      };

    case SEARCH_COLLEAGUE: {
      const { value } = action.payload;
      return {
        ...state,
        searchField: value,
        filteredColleagues: filterColleagues({
          roleArray: filterRoles(state.filteredRoles, state),
          statusArray: filterStatus(state.filteredStatuses, state),
          searchField: value,
          state,
        }),
      };
    }

    case REMOVE_COLLEAGUE_SEARCH_FIELD:
      return {
        ...state,
        searchField: '',
        filteredColleagues: filterColleagues({
          roleArray: filterRoles(state.filteredRoles, state),
          statusArray: filterStatus(state.filteredStatuses, state),
          searchField: '',
          state,
        }),
      };

    case GET_COLLEAGUES_FAILED:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload.errorMessage,
        hasLoadingError: true,
      };

    case USER_LOGOUT_SUCCEEDED:
      return initialState;

    default:
      return state;
  }
};

export default colleaguesReducer;
