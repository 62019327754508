import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';
import './profile-input.scss';


const ProfileInputField = ({ disabled, value, onChange, title, name, placeholder, handleFocus, handleBlur, isInvalid, errorMessage }) => {
  const isValidClassName = !isInvalid ? ' invalid' : '';
  return (
    <div className="profile-card-input-container">
      <div className="profile-card-input-label">
        {title}
      </div>
      {
        !isInvalid && errorMessage === ''
          ? <div className="profile-car-input-label-wrong">Wrong Format.</div>
          : null
      }
      {
        !isInvalid && errorMessage !== ''
          ? <div className="profile-car-input-label-wrong">{errorMessage}</div>
          : null
      }
      <Input
        disabled={disabled}
        value={value}
        onChange={onChange}
        name={name}
        placeholder={placeholder}
        onFocus={handleFocus}
        onBlur={handleBlur}
        className={isValidClassName}
      />
    </div>
  );
};

ProfileInputField.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  isInvalid: PropTypes.bool,
  onChange: PropTypes.func,
  handleFocus: PropTypes.func,
  handleBlur: PropTypes.func,
  errorMessage: PropTypes.string,
};

ProfileInputField.defaultProps = {
  onChange: () => { },
  handleFocus: () => { },
  handleBlur: () => { },
  isInvalid: true,
  disabled: false,
  value: null,
  errorMessage: '',
};

export default ProfileInputField;
