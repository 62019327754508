import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MdMenu from 'react-ionicons/lib/MdMenu';
import { getCurrentUser, getStatuses, getCurrentStatus, requestStatusChange, menuClick, logout } from 'actions/appActions';
import { getMessages, getUnreadMessages } from 'actions/messagesActions';
import HeaderProfile from 'components/Header/HeaderProfile';
import HeaderStatus from 'components/Header/HeaderStatus';
import { CompanyIcon } from 'utils/branding';
import 'containers/AppHeader/app-header.scss';


class AppHeader extends Component {
  componentDidMount() {
    const {
      dispatchGetCurrentUser,
      dispatchGetStatuses,
      dispatchGetCurrentStatus,
      isAuthenticated,
      dispatchGetMessages,
      dispatchGetUnreadMessages,
    } = this.props;
    if (isAuthenticated) {
      dispatchGetCurrentUser();
      dispatchGetStatuses();
      dispatchGetCurrentStatus();
      dispatchGetMessages();
      dispatchGetUnreadMessages();
    }
  }

  render() {
    const { app, dispatchStatusChange, setVisible, dispatchMenuClick, dispatchLogout } = this.props;

    return (
      <div className="app-header">
        {
          !app.showMenu
            ? (
              <div className="app-header-mobile-menu">
                <button
                  type="button"
                  className="app-header-mobile"
                  onClick={setVisible}
                >
                  <CompanyIcon />
                </button>
                <button
                  type="button"
                  className="app-header-mobile menu"
                  onClick={setVisible}
                >
                  <MdMenu fontSize="32px" color="#3695FF" />
                </button>
                <HeaderStatus
                  showMenu
                  statuses={app.statuses || []}
                  currentStatus={app.currentStatus || 'Away'}
                  handleStatusChange={dispatchStatusChange}
                />
              </div>
            )
            : (
              <HeaderStatus
                statuses={app.statuses || []}
                currentStatus={app.currentStatus || 'Away'}
                handleStatusChange={dispatchStatusChange}
              />
            )
        }
        <HeaderProfile
          handleLogout={dispatchLogout}
          handleProfile={dispatchMenuClick}
          user={app.user}
          isMobile={app.isMobile}
        />
      </div>
    );
  }
}

AppHeader.propTypes = {
  app: PropTypes.shape().isRequired,
  dispatchGetMessages: PropTypes.func.isRequired,
  dispatchGetCurrentUser: PropTypes.func.isRequired,
  dispatchGetStatuses: PropTypes.func.isRequired,
  dispatchGetCurrentStatus: PropTypes.func.isRequired,
  dispatchStatusChange: PropTypes.func.isRequired,
  dispatchLogout: PropTypes.func.isRequired,
  dispatchMenuClick: PropTypes.func.isRequired,
  dispatchGetUnreadMessages: PropTypes.func.isRequired,
  setVisible: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  app: state.appReducer,
  isAuthenticated: state.userReducer.isAuthenticated,
});

const mapDispatchToProps = {
  dispatchGetCurrentUser: getCurrentUser,
  dispatchGetStatuses: getStatuses,
  dispatchGetCurrentStatus: getCurrentStatus,
  dispatchStatusChange: requestStatusChange,
  dispatchLogout: logout,
  dispatchMenuClick: menuClick,
  dispatchGetMessages: getMessages,
  dispatchGetUnreadMessages: getUnreadMessages,
};

export default connect(mapStateToProps, mapDispatchToProps)(AppHeader);
