import React from 'react';
import PropTypes from 'prop-types';
import { Popover } from 'antd';
import moment from 'moment';
import './progress-bar.scss';

const ProgressBar = ({
  currentLoggedTime, currentLoggedTimeFormatted, expectedLoggedTime, totalLoggedTime,
  loggedTimeStatus, remainingLoggedTimeStatus, currentDate, remainingLogTimeFormatted,
  popoverRight, popoverLeft, title,
}) => {
  const isMax = currentLoggedTime / 3600 > totalLoggedTime;
  const currentStyle = isMax ? '100%' : `${(currentLoggedTime / 3600 / totalLoggedTime) * 100}%`;
  const expectedStyle = (expectedLoggedTime / totalLoggedTime) * 100;
  return (
    <div className="progress-bar">
      <div className="progress-bar-body-workhours-title">{title}</div>
      <div className="progress-bar-body">
        <div className="progress-bar-workhours-mobile">
          <div className="progress-bar-body-workhours-mobile">
            <Popover
              placement="bottomRight"
              content={popoverLeft}
              title={title}
              trigger="hover"
            >
              <div className={`progress-bar-body-workhours-value ${loggedTimeStatus}`}>{currentLoggedTimeFormatted}</div>
            </Popover>
          </div>
          <div className="progress-bar-body-workhours-mobile">
            <Popover
              placement="bottomRight"
              content={popoverRight}
              title={title}
              trigger="hover"
            >
              <div className={`progress-bar-body-workhours-value ${remainingLoggedTimeStatus}`}>{remainingLogTimeFormatted}</div>
            </Popover>
          </div>
        </div>
        <div className="progress-bar-body-workhours">
          <Popover
            placement="bottomRight"
            content={popoverLeft}
            title={title}
            trigger="hover"
          >
            <div className={`progress-bar-body-workhours-value ${loggedTimeStatus}`}>{currentLoggedTimeFormatted}</div>
          </Popover>
        </div>
        <div className="progress-bar-body-progress-bar">
          <div className={`progress-bar-body-progress-bar-current ${loggedTimeStatus}Background`} style={{ width: currentStyle }} />
          <div className="progress-bar-body-progress-bar-expected" style={{ left: `${expectedStyle}%` }} />
          <div className={`progress-bar-body-progress-bar-title-container ${expectedStyle < 50 ? 'right' : 'left'}`} style={{ left: `${expectedStyle}%` }}>
            <div className="progress-bar-body-progress-bar-title-date">{moment(currentDate).format('D MMM')}</div>
            <div className="progress-bar-body-progress-bar-title-time">{`${expectedLoggedTime} HOURS`}</div>
          </div>
        </div>
        <div className="progress-bar-body-workhours">
          <Popover
            placement="bottomRight"
            content={popoverRight}
            title={title}
            trigger="hover"
          >
            <div className={`progress-bar-body-workhours-value ${remainingLoggedTimeStatus}`}>{remainingLogTimeFormatted}</div>
          </Popover>
        </div>
      </div>
    </div>
  );
};

ProgressBar.propTypes = {
  currentLoggedTime: PropTypes.number.isRequired,
  currentLoggedTimeFormatted: PropTypes.string.isRequired,
  expectedLoggedTime: PropTypes.number.isRequired,
  totalLoggedTime: PropTypes.number.isRequired,
  loggedTimeStatus: PropTypes.string.isRequired,
  remainingLoggedTimeStatus: PropTypes.string.isRequired,
  currentDate: PropTypes.string.isRequired,
  remainingLogTimeFormatted: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  popoverRight: PropTypes.string.isRequired,
  popoverLeft: PropTypes.string.isRequired,
};

export default ProgressBar;
