import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { setConfig } from 'react-hot-loader';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { hot } from 'react-hot-loader/root';
import moment from 'moment';
import App from 'components/App/App';
import { currentBrandColors } from 'utils/branding';

setConfig({ trackTailUpdates: false });
moment.updateLocale('en', {
  week: {
    dow: 1,
  },
});

export const Root = ({ store, history }) => {
  useLayoutEffect(() => {
    Object.entries(currentBrandColors).forEach(([key, value]) => {
      document.documentElement.style.setProperty(key, value);
    });
  }, []);

  return (
    <Provider store={store}>
      <Router history={history}>
        <App />
      </Router>
    </Provider>
  );
};

Root.propTypes = {
  store: PropTypes.shape().isRequired,
  history: PropTypes.shape().isRequired,
};

export default hot(Root);
