import {
  USER_LOGIN_REQUESTED, USER_LOGIN_SUCCEEDED, USER_LOGIN_FAILED, USER_LOGOUT_SUCCEEDED,
  RESTORE_USER_BY_TOKEN_SUCCEEDED, RESTORE_USER_BY_TOKEN_REQUESTED, RESTORE_USER_BY_TOKEN_FAILED,
  USER_UPDATE_USERINFO_REQUESTED, USER_UPDATE_USERINFO_SUCCEEDED, USER_UPDATE_USERINFO_FAILED,
  USER_COGNITO_LOGIN_REQUESTED, USER_COGNITO_CODE,
} from 'consts/actionTypes';
import initialState from 'reducers/user/initialState';

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUESTED:
      return {
        ...state,
        isAuthenticating: true,
      };

    case USER_COGNITO_LOGIN_REQUESTED:
      return {
        ...state,
        isAuthenticating: true,
      };

    case USER_LOGIN_SUCCEEDED: {
      return {
        ...state,
        isAuthenticating: true,
        isAuthenticated: false,
        hasAuthenticatingError: false,
        errorMessage: '',
      };
    }

    case USER_LOGIN_FAILED:
      return {
        ...state,
        isAuthenticating: false,
        hasAuthenticatingError: true,
        errorMessage: action.payload,
      };

    case USER_LOGOUT_SUCCEEDED:
      return initialState;

    case RESTORE_USER_BY_TOKEN_REQUESTED: {
      return {
        ...state,
        isAuthenticating: true,
        isAuthenticated: false,
      };
    }

    case RESTORE_USER_BY_TOKEN_FAILED: {
      return {
        ...state,
        isAuthenticating: false,
        isAuthenticated: false,
        hasAuthenticatingError: true,
      };
    }

    case RESTORE_USER_BY_TOKEN_SUCCEEDED: {
      return {
        ...state,
        isAuthenticating: false,
        isAuthenticated: true,
        token: action.payload.token,
        user: action.payload.user,
        userId: action.payload.userId,
        userData: action.payload.userData,
      };
    }

    case USER_UPDATE_USERINFO_REQUESTED: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case USER_UPDATE_USERINFO_SUCCEEDED: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case USER_UPDATE_USERINFO_FAILED: {
      return {
        ...state,
        isLoading: false,
        hasLoadingError: true,
        errorMessage: action.payload,
      };
    }

    case USER_COGNITO_CODE: {
      return {
        code: action.payload,
      };
    }

    default:
      return state;
  }
};

export default userReducer;
