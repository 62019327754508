import moment from 'moment';
import {
  CHANGE_PROFILE_INPUT, USER_LOGOUT_SUCCEEDED,
  DEPENDANTS_MODAL_OPEN, EDUCATION_MODAL_OPEN,
  PROFILE_SELECT_CHANGE, PROFILE_DATE_CHANGE,
  GET_PERSONAL_DATA_SUCCEEDED, GET_PERSONAL_DATA_REQUESTED,
  GET_PERSONAL_DATA_FAILED, DELETE_EXAM_MODAL_OPEN, DELETE_EXAM_PLAN_MODAL_OPEN, COMPLETE_EXAM_PLAN_MODAL_OPEN, EXAM_MODAL_OPEN, EXAM_PLAN_MODAL_OPEN,
} from 'consts/actionTypes';
import initialState from 'reducers/profile/initialState';

const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_PROFILE_INPUT: {
      const { field, value } = action.payload;
      return {
        ...state,
        inputs: {
          ...state.inputs,
          [field]: value,
        },
      };
    }

    case GET_PERSONAL_DATA_REQUESTED:
      return {
        ...state,
        isLoading: true,
      };

    case GET_PERSONAL_DATA_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case GET_PERSONAL_DATA_SUCCEEDED: {
      const {
        maritalStatuses,
        personalDetails,
        educations,
        dependants,
        exams,
        examPlans,
        changableData,
        degrees,
      } = action.payload;
      return {
        ...state,
        maritalStatuses,
        degrees,
        personalDetails,
        educations,
        dependants,
        exams,
        examPlans,
        isLoading: false,
        inputs: {
          ...state.inputs,
          ...changableData,
        },
      };
    }

    case DEPENDANTS_MODAL_OPEN: {
      const { modalOpen, childName, childTaxNumber, childTajNumber, childsMotherName, childsBirthday, nodeId } = action.payload;
      return {
        ...state,
        dependantsModal: modalOpen,
        inputs: {
          ...state.inputs,
          childName,
          childTaxNumber,
          childTajNumber,
          childsMotherName,
          childsBirthday,
          nodeId,
        },
      };
    }

    case EDUCATION_MODAL_OPEN: {
      const { modalOpen, instituteName, fieldOfStudy, degree, serialNumber, nodeId } = action.payload;
      return {
        ...state,
        educationModal: modalOpen,
        inputs: {
          ...state.inputs,
          instituteName,
          fieldOfStudy,
          degree,
          serialNumber,
          nodeId,
        },
      };
    }

    case DELETE_EXAM_MODAL_OPEN: {
      const { modalOpen, name, nodeId } = action.payload;
      return {
        ...state,
        deleteExamModal: modalOpen,
        inputs: {
          name,
          nodeId,
        },
      };
    }


    case EXAM_MODAL_OPEN: {
      const { modalOpen, name, acquiredAt, expiresAt, nodeId } = action.payload;
      return {
        ...state,
        examModal: modalOpen,
        inputs: {
          ...state.inputs,
          name,
          acquiredAt,
          expiresAt,
          nodeId,
        },
      };
    }

    case DELETE_EXAM_PLAN_MODAL_OPEN: {
      const { modalOpen, name, nodeId } = action.payload;
      return {
        ...state,
        deleteExamPlanModal: modalOpen,
        inputs: {
          name,
          nodeId,
        },
      };
    }

    case COMPLETE_EXAM_PLAN_MODAL_OPEN: {
      const { modalOpen, name, nodeId } = action.payload;
      return {
        ...state,
        completeExamPlanModal: modalOpen,
        inputs: {
          name,
          nodeId,
        },
      };
    }

    case EXAM_PLAN_MODAL_OPEN: {
      const { modalOpen, name, deadlineDate, nodeId } = action.payload;
      return {
        ...state,
        examPlanModal: modalOpen,
        inputs: {
          ...state.inputs,
          name,
          deadlineDate,
          nodeId,
        },
      };
    }

    case PROFILE_SELECT_CHANGE: {
      const { field, value } = action.payload;
      return {
        ...state,
        [field]: value,
      };
    }

    case PROFILE_DATE_CHANGE: {
      const { name, date } = action.payload;
      return {
        ...state,
        inputs: {
          ...state.inputs,
          [name]: date ? moment(date) : '',
        },
      };
    }

    case USER_LOGOUT_SUCCEEDED:
      return initialState;

    default:
      return state;
  }
};

export default profileReducer;
