const initialState = {
  currentTable: 'status',
  absence: {
    baseUrl: 'absence_list?_format=json',
    columns: [
      { title: 'Status', key: 'moderationState', dataIndex: 'moderationState', filters: ['published', 'pending', 'cancelled', 'denied'] },
      { title: 'date', key: 'intraAbsenceDateStart', dataIndex: 'intraAbsenceDateStart', sortable: true },
      { title: 'day(s) requested', key: 'intraDaysRequested', dataIndex: 'intraDaysRequested' },
      { title: 'type', key: 'intraAbsenceType', dataIndex: 'intraAbsenceType' },
      { title: 'action', key: 'id', dataIndex: 'id' },
    ],
    dataSource: [],
    filters: [],
    isClickable: false,
    isMobileView: true,
    pager: {},
    range: {},
    searchValue: null,
    selectedRow: {},
    sort: {},
  },
  bonus: {
    isMobileView: true,
  },
  dashboard: {
    baseUrl: 'redmine_open_issues?_format=json',
    columns: [
      { title: 'id', key: 'taskId', dataIndex: 'taskId' },
      { title: 'subject', key: 'taskSubject', dataIndex: 'taskSubject' },
      { title: 'project', key: 'taskProject', dataIndex: 'taskProject' },
      { title: 'spent/est.time', key: 'taskSpentTimeFormatted', dataIndex: 'taskSpentTimeFormatted' },
    ],
    dataSource: [],
    filters: [],
    isClickable: false,
    isMobileView: true,
    searchValue: null,
    pager: {},
    range: {},
    selectedRow: {},
    sort: {},
  },
  status: {
    baseUrl: 'list/worktime?_format=json',
    columns: [
      { title: 'date', key: 'date', dataIndex: 'date' },
      { title: 'worktime', key: 'worktime', dataIndex: 'workTime' },
    ],
    dataSource: [],
    filters: [],
    isClickable: true,
    isMobileView: true,
    searchValue: null,
    pager: {},
    range: {},
    selectedRow: {},
    sort: {},
  },
  outer: {},
  educations: {
    isMobileView: true,
  },
  dependants: {
    isMobileView: true,
  },
  exams: {
    isMobileView: true,
  },
  examPlans: {
    isMobileView: true,
  },
  changeLog: {
    isMobileView: true,
  },
};

export default initialState;
