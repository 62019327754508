import React from 'react';
import PropTypes from 'prop-types';
import IntraModal from 'components/Modal/Modal';
import './confirmation-modal.scss';

const ConfirmationModal = ({
  subject, showModal, handleClose, modalTitle,
  handleSubmit, isDisabled, description, submitButtonLabel,
}) => (
  <>
    <IntraModal
      title={modalTitle}
      open={showModal}
      close={handleClose}
      submit={handleSubmit}
      disabled={isDisabled}
      okButton={submitButtonLabel}
      cancelButton="CANCEL"
    >
      <div>
        {description}
      </div>
      {subject && (
      <div className="confirmation-modal__subject">
        {subject}
      </div>
      )}
    </IntraModal>
  </>
);

ConfirmationModal.propTypes = {
  modalTitle: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  subject: PropTypes.string,
  submitButtonLabel: PropTypes.string.isRequired,
  showModal: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
};

ConfirmationModal.defaultProps = {
  subject: undefined,
  isDisabled: false,
};

export default ConfirmationModal;
