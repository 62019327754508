import { takeEvery, call, put, select } from 'redux-saga/effects';
import { isEmpty, snakeCase, camelCase } from 'lodash';
import {
  COMMON_TABLE_FAILED,
  COMMON_TABLE_SORT_CLICK,
  COMMON_TABLE_REQUESTED,
  COMMON_TABLE_SELECT_FILTER,
  COMMON_TABLE_SELECT_RANGE,
  COMMON_TABLE_SELECT_PAGER,
  COMMON_TABLE_SUCCEEDED,
} from 'consts/actionTypes';
import * as config from 'services/config';
import * as API from 'services/api';


export const getTableReducer = (store) => store.tableReducer;
export const getUserReducer = (store) => store.userReducer;

export const convertToRequestParams = ({ sort, filters, range, pager, extraRanges }) => {
  let paramsObj = {};
  if (!isEmpty(sort)) {
    paramsObj = {
      ...paramsObj,
      soryBy: sort.dataIndex,
      sortOrder: sort.asc ? 'ASC' : 'DESC',
    };
  }

  if (!isEmpty(filters)) {
    filters.forEach(({ dataIndex, value }) => {
      paramsObj = {
        ...paramsObj,
        [dataIndex]: value,
      };
    });
  }

  if (!isEmpty(pager)) {
    paramsObj = {
      ...paramsObj,
      page: pager.currentPage,
    };
  }

  if (!isEmpty(range)) {
    if (!isEmpty(extraRanges)) {
      Object.entries(extraRanges)
        .forEach(([key, value]) => {
          paramsObj = {
            ...paramsObj,
            [key]: value > 0
              ? range.max
              : range.min,
          };
        });
    } else {
      paramsObj = {
        ...paramsObj,
        'date[min]': range.min,
        'date[max]': range.max,
      };
    }
  }

  return Object.entries(paramsObj).reduce((p, [key, val]) => `${p}&${key.includes('[') ? key : snakeCase(key)}=${val}`, '');
};

export function* requestTable({ payload }) {
  try {
    const userReducer = yield select(getUserReducer);
    const { userId } = userReducer;
    const url = payload[payload.table].extraUrl
      ? `${config.host}/${payload[payload.table].extraUrl}${convertToRequestParams(payload[payload.table])}`
      : `${config.host}/api/user/${userId}/${payload[payload.table].baseUrl}${convertToRequestParams(payload[payload.table])}`;
    const data = yield call(API.getData, url);
    yield put({
      type: COMMON_TABLE_SUCCEEDED,
      payload: {
        data: {
          data: data.data
            ? data.data
            : data,
          pager: !data || !data.pager
            ? {}
            : Object.keys(data.pager).reduce((prev, key) => ({
              ...prev,
              [camelCase(key)]: key === 'total_rows'
                ? Math.floor((data.pager[key] - 1) / data.pager.items_per_page)
                : data.pager[key],
            }),
            {}),
        },
        newState: payload,
      },
    });
  } catch (error) {
    yield put({
      type: COMMON_TABLE_FAILED,
    });
    console.log(error); // eslint-disable-line
  }
}


export function* sortClick({ payload: { table, column } }) {
  const state = yield select(getTableReducer);
  let payload;
  if (column.dataIndex === state[table].sort.dataIndex && state[table].sort.asc) {
    payload = {
      ...state,
      [table]: {
        ...state[table],
        sort: {},
      },
      pager: {},
      table,
    };
  } else {
    payload = {
      ...state,
      [table]: {
        ...state[table],
        sort: {
          dataIndex: column.dataIndex,
          asc: column.dataIndex === state[table].sort.dataIndex,
          type: column.type,
        },
      },
      pager: {},
      table,
    };
  }

  yield put({
    type: COMMON_TABLE_REQUESTED,
    payload,
  });
}

export function* selectFilter({ payload: { table, column, value } }) {
  const state = yield select(getTableReducer);
  const newFilterState = state[table].filters
    .filter((filter) => filter.dataIndex !== column.dataIndex);

  yield put({
    type: COMMON_TABLE_REQUESTED,
    payload: {
      ...state,
      [table]: {
        ...state[table],
        filters: value !== ''
          ? [
            ...newFilterState,
            {
              dataIndex: column.dataIndex,
              value,
            },
          ]
          : newFilterState,
        pager: {},
      },
      table,
    },
  });
}

export function* selectRange({ payload: { table, min, max } }) {
  const state = yield select(getTableReducer);

  yield put({
    type: COMMON_TABLE_REQUESTED,
    payload: {
      ...state,
      [table]: {
        ...state[table],
        range: min
          ? {
            min,
            max,
          }
          : {},
        pager: {},
      },
      table,
    },
  });
}

export function* selectPager({ payload: { table, currentPage } }) {
  const state = yield select(getTableReducer);
  yield put({
    type: COMMON_TABLE_REQUESTED,
    payload: {
      ...state,
      [table]: {
        ...state[table],
        pager: {
          ...state[table].pager,
          currentPage,
        },
      },
      table,
    },
  });
}

export const tableSagas = [
  takeEvery(COMMON_TABLE_SORT_CLICK, sortClick),
  takeEvery(COMMON_TABLE_REQUESTED, requestTable),
  takeEvery(COMMON_TABLE_SELECT_FILTER, selectFilter),
  takeEvery(COMMON_TABLE_SELECT_RANGE, selectRange),
  takeEvery(COMMON_TABLE_SELECT_PAGER, selectPager),
];
