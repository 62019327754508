import React from 'react';
import PropTypes from 'prop-types';

const WorktimeChartBottomLabel = ({ weekData }) => (
  <div className="worktime-chart-label">
    {
      weekData
        ? weekData.map((dailyData, index) => {
          const pmLoggedTime = dailyData.pm_logged_time_formatted ? dailyData.pm_logged_time_formatted : '0:00';
          return (
            <div key={index.toString()} className="worktime-label-item">
              <div className="name-of-day">{dailyData.name_of_day}</div>
              <div className={`time-worktime${!dailyData.isOfficeTimeCorrect ? ' wasted' : ''}`}>{dailyData.work_time_formatted}</div>
              <div className={dailyData.isTimeCorrect ? 'time-pm-logged-time' : 'time-pm-logged-time wasted'}>{pmLoggedTime}</div>
              {
                dailyData.is_home_office
                && <div className="homeoffice">HO</div>
              }
            </div>
          );
        })
        : null
    }
  </div>
);

WorktimeChartBottomLabel.propTypes = {
  weekData: PropTypes.arrayOf(PropTypes.shape({})),
};

WorktimeChartBottomLabel.defaultProps = {
  weekData: null,
};

export default WorktimeChartBottomLabel;
