import React from 'react';
import PropTypes from 'prop-types';
import './bread-crumb.scss';


const BreadCrumb = ({ breadcrumbText }) => (
  <div className="breadcrumb">
    {breadcrumbText}
  </div>
);

BreadCrumb.propTypes = {
  breadcrumbText: PropTypes.string.isRequired,
};

export default BreadCrumb;
