import React from 'react';
import PropTypes from 'prop-types';
import 'components/Bookmarks/bookmark-accordion.scss';
import IosArrowDown from 'react-ionicons/lib/IosArrowDown';

const BookmarkAccordion = ({ isMobile, items, selectedCategory, setSelectedCategory }) => {
  const toggleCategory = (categoryId) => {
    if (categoryId !== selectedCategory) {
      setSelectedCategory(categoryId);
    } else {
      setSelectedCategory(null);
    }
  };

  return (
    <>
      <div className="bookmark-items">
        {items.map((item) => (
          <div>
            {isMobile && (
              <div
                className={selectedCategory === item.category.id ? 'bookmark-accordion-title-active' : 'bookmark-accordion-title-inactive'}
                onClick={() => toggleCategory(item.category.id)}
              >
                <div className="title-text">{item.category.title}</div>
                <div className="title-icon"><IosArrowDown /></div>
              </div>
            )}
            <div className={`bookmark-accordion-content ${isMobile ? ' with-bottom-border' : ''}`}>
              {(item.category.id === selectedCategory) && item.items.map((link) => (
                <div className={`bookmark-accordion-content-item${isMobile ? '-mobile' : ''}`}>
                  <div><a href={link.url} rel="noopener noreferrer" target="_blank">{link.title}</a></div>
                  <div className="bookmark-description" dangerouslySetInnerHTML={{ __html: link.description }} />
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

BookmarkAccordion.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(PropTypes.object),
  selectedCategory: PropTypes.number,
  setSelectedCategory: PropTypes.func.isRequired,
};

BookmarkAccordion.defaultProps = {
  items: [],
  selectedCategory: null,
};

export default BookmarkAccordion;
