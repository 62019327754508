import React from 'react';
import PropTypes from 'prop-types';
import MagicTable from 'containers/MagicTable/MagicTable';
import './wiki-change-log.scss';


const WikiChangeLog = ({ isMobile, changeLog }) => (
  <div className="wiki-magic-table-container">
    <MagicTable
      table="changeLog"
      isMobile={isMobile}
      outerColumns={[
        { title: 'Article title', key: 'title', dataIndex: 'title' },
        { title: 'Category', key: 'category', dataIndex: 'category' },
        { title: 'Editor', key: 'editor', dataIndex: 'editor' },
        { title: 'Date', key: 'date', dataIndex: 'date' },
      ]}
      outerDataSource={changeLog}
    />
  </div>
);

WikiChangeLog.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  changeLog: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default WikiChangeLog;
