import React from 'react';
/* eslint-disable */
import PropTypes from 'prop-types';
import {
  VictoryBar,
  VictoryChart,
  VictoryAxis,
  VictoryGroup,
  VictoryContainer,
} from 'victory';
import './worktime-chart.scss';

const styles = {
  firstBarData: {
    data: { fill: (d) => (d.data[d.index].isOfficeTimeCorrect ? '#6796E4' : '#F08887') },
  },
  secondBarData: {
    data: { fill: (d) => (d.data[d.index].isTimeCorrect ? '#67E49C' : '#F08887') },
  },
  victoryAxis: {
    tickLabels: { display: 'none' },
    axis: { stroke: 'none' },
  },
};

const WorktimeChart = ({ data, height, width, padding,
  margin, offset, barWidth,
}) => (
    <svg height={height} width={width}>
      <VictoryChart
        standalone={false}
        height={height}
        width={width}
        padding={padding}
        margin={margin}
        containerComponent={<VictoryContainer responsive={false} />}
      >
        <VictoryGroup
          offset={offset}
          name="bar"
        >
          <VictoryBar
            barWidth={barWidth}
            style={styles.firstBarData}
            data={data}
            y="work_time"
          />
          <VictoryBar
            barWidth={barWidth}
            data={data}
            style={styles.secondBarData}
            y="pm_logged_time"
          />
        </VictoryGroup>
        <VictoryAxis style={styles.victoryAxis} />
      </VictoryChart>
    </svg>
  );

WorktimeChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  height: PropTypes.number,
  width: PropTypes.number,
  padding: PropTypes.number,
  offset: PropTypes.number,
  barWidth: PropTypes.number,
  margin: PropTypes.number,
};

WorktimeChart.defaultProps = {
  data: [],
  height: null,
  width: null,
  padding: null,
  offset: null,
  barWidth: null,
  margin: null,
};

export default WorktimeChart;
