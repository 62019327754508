/* eslint-disable */

// USER ACTIONS
export const USER_LOGIN_REQUESTED = 'USER_LOGIN_REQUESTED';
export const USER_LOGIN_SUCCEEDED = 'USER_LOGIN_SUCCEEDED';
export const USER_LOGIN_FAILED = 'USER_LOGIN_FAILED';

export const USER_COGNITO_CODE = 'USER_COGNITO_CODE';

export const RESTORE_USER_BY_TOKEN_REQUESTED = 'RESTORE_USER_BY_TOKEN_REQUESTED';
export const RESTORE_USER_BY_TOKEN_SUCCEEDED = 'RESTORE_USER_BY_TOKEN_SUCCEEDED';
export const RESTORE_USER_BY_TOKEN_FAILED = 'RESTORE_USER_BY_TOKEN_FAILED';

export const USER_LOGOUT_REQUESTED = 'USER_LOGOUT_REQUESTED';
export const USER_LOGOUT_SUCCEEDED = 'USER_LOGOUT_SUCCEEDED';
export const USER_LOGOUT_FAILED = 'USER_LOGOUT_SUCCEEDED';
export const USER_UPDATE_USERINFO_REQUESTED = 'USER_UPDATE_USERINFO_REQUESTED';
export const USER_UPDATE_USERINFO_SUCCEEDED = 'USER_UPDATE_USERINFO_SUCCEEDED';
export const USER_UPDATE_USERINFO_FAILED = 'USER_UPDATE_USERINFO_FAILED';
export const USER_COGNITO_LOGIN_REQUESTED = 'USER_COGNITO_LOGIN_REQUESTED';


export const WORKTIME_GET_WORKTIME_REQUESTED = 'WORKTIME_GET_WORKTIME_REQUESTED';
export const WORKTIME_GET_WORKTIME_SUCCEEDED = 'WORKTIME_GET_WORKTIME_SUCCEEDED';
export const WORKTIME_GET_WORKTIME_FAILED = 'WORKTIME_GET_WORKTIME_FAILED';

export const WORKTIME_GET_WORKTIMELIST_REQUESTED = 'WORKTIME_GET_WORKTIMELIST_REQUESTED';
export const WORKTIME_GET_WORKTIMELIST_SUCCEEDED = 'WORKTIME_GET_WORKTIMELIST_SUCCEEDED';
export const WORKTIME_GET_WORKTIMELIST_FAILED = 'WORKTIME_GET_WORKTIMELIST_FAILED';
export const WORKTIME_EMPTY_WORKTIMELIST = 'WORKTIME_EMPTY_WORKTIMELIST';

export const APP_GET_CURRENT_USER_REQUESTED = 'APP_GET_CURRENT_USER_REQUESTED';
export const APP_GET_CURRENT_USER_SUCCEEDED = 'APP_GET_CURRENT_USER_SUCCEEDED';
export const APP_GET_CURRENT_USER_FAILED = 'APP_GET_CURRENT_USER_FAILED';
export const APP_GET_STATUSES_REQUESTED = 'APP_GET_STATUSES_REQUESTED';
export const APP_GET_STATUSES_SUCCEEDED = 'APP_GET_STATUSES_SUCCEEDED';
export const APP_GET_STATUSES_FAILED = 'APP_GET_STATUSES_FAILED';
export const APP_GET_CURRENT_STATUS_REQUESTED = 'APP_GET_CURRENT_STATUS_REQUESTED';
export const APP_GET_CURRENT_STATUS_SUCCEEDED = 'APP_GET_CURRENT_STATUS_SUCCEEDED';
export const APP_GET_CURRENT_STATUS_FAILED = 'APP_GET_CURRENT_STATUS_FAILED';
export const APP_MENU_CLICK = 'APP_MENU_CLICK';
export const APP_REQUEST_STATUS_CHANGE_REQUESTED = 'APP_REQUEST_STATUS_CHANGE_REQUESTED';
export const APP_REQUEST_STATUS_CHANGE_SUCCEEDED = 'APP_REQUEST_STATUS_CHANGE_SUCCEEDED';
export const APP_REQUEST_STATUS_CHANGE_FAILED = 'APP_REQUEST_STATUS_CHANGE_FAILED';
export const APP_RESIZE = 'APP_RESIZE';

export const GET_BONUS_REQUESTED = 'GET_BONUS_REQUESTED';
export const GET_BONUS_SUCCEEDED = 'GET_BONUS_SUCCEEDED';
export const GET_BONUS_FAILED = 'GET_BONUS_FAILED';
export const GET_BONUS_CATEGORIES_REQUESTED = 'GET_BONUS_CATEGORIES_REQUESTED';
export const GET_BONUS_CATEGORIES_SUCCEEDED = 'GET_BONUS_CATEGORIES_SUCCEEDED';
export const GET_BONUS_CATEGORIES_FAILED = 'GET_BONUS_CATEGORIES_FAILED';



export const ADD_BONUS_REQUESTED = 'ADD_BONUS_REQUESTED';
export const ADD_BONUS_SUCCEEDED = 'ADD_BONUS_SUCCEEDED';
export const ADD_BONUS_FAILED = 'ADD_BONUS_FAILED';

export const COMMON_TABLE_REQUESTED = 'COMMON_TABLE_REQUESTED';
export const COMMON_TABLE_FAILED = 'COMMON_TABLE_FAILED';
export const COMMON_TABLE_SUCCEEDED = 'COMMON_TABLE_SUCCEEDED';
export const COMMON_TABLE_ROW_CLICK = 'COMMON_TABLE_ROW_CLICK';
export const COMMON_TABLE_SORT_CLICK = 'COMMON_TABLE_SORT_CLICK';
export const COMMON_TABLE_SELECT_FILTER = 'COMMON_TABLE_SELECT_FILTER';
export const COMMON_TABLE_SELECT_PAGER = 'COMMON_TABLE_SELECT_PAGER';
export const COMMON_TABLE_SELECT_RANGE = 'COMMON_TABLE_SELECT_RANGE';
export const COMMON_TABLE_CHANGE_TABLE = 'COMMON_TABLE_CHANGE_TABLE';

export const GET_MESSAGES_REQUESTED = 'GET_MESSAGES_REQUESTED';
export const GET_MESSAGES_FAILED = 'GET_MESSAGES_FAILED';
export const GET_MESSAGES_SUCCEEDED = 'GET_MESSAGES_SUCCEEDED';

export const GET_MESSAGE_DETAILS_REQUESTED = 'GET_MESSAGE_DETAILS_REQUESTED';
export const GET_MESSAGE_DETAILS_FAILED = 'GET_MESSAGE_DETAILS_FAILED';
export const GET_MESSAGE_DETAILS_SUCCEEDED = 'GET_MESSAGE_DETAILS_SUCCEEDED';

export const SELECT_MESSAGE = 'SELECT_MESSAGE';
export const SELECT_MESSAGE_FILTER = 'SELECT_MESSAGE_FILTER';
export const CLEAR_MESSAGE_FILTER = 'CLEAR_MESSAGE_FILTER';

export const GET_UNREAD_MESSAGES_REQUESTED = 'GET_UNREAD_MESSAGES_REQUESTED';
export const GET_UNREAD_MESSAGES_SUCCEEDED = 'GET_UNREAD_MESSAGES_SUCCEEDED';
export const GET_UNREAD_MESSAGES_FAILED = 'GET_UNREAD_MESSAGES_FAILED';

export const GET_COLLEAGUES_REQUESTED = 'GET_COLLEAGUES_REQUESTED';
export const GET_COLLEAGUES_SUCCEEDED = 'GET_COLLEAGUES_SUCCEEDED';
export const GET_COLLEAGUES_FAILED = 'GET_COLLEAGUES_FAILED';
export const EMPTY_COLLEAGUES = 'EMPTY_COLLEAGUES';

export const GET_ABSENCES_REQUESTED = 'GET_ABSENCES_REQUESTED';
export const GET_ABSENCES_SUCCEEDED = 'GET_ABSENCES_SUCCEEDED';
export const GET_ABSENCES_FAILED = 'GET_ABSENCES_FAILED';

export const GET_ABSENCE_DETAILS_REQUESTED = 'GET_ABSENCE_DETAILS_REQUESTED';
export const GET_ABSENCE_DETAILS_SUCCEEDED = 'GET_ABSENCE_DETAILS_SUCCEEDED';
export const GET_ABSENCE_DETAILS_FAILED = 'GET_ABSENCE_DETAILS_FAILED';

export const UPDATE_ABSENCE_DETAILS_REQUESTED = 'UPDATE_ABSENCE_DETAILS_REQUESTED';
export const UPDATE_ABSENCE_DETAILS_SUCCEEDED = 'UPDATE_ABSENCE_DETAILS_SUCCEEDED';
export const UPDATE_ABSENCE_DETAILS_FAILED = 'UPDATE_ABSENCE_DETAILS_FAILED';

export const DELETE_ABSENCE_REQUESTED = 'DELETE_ABSENCE_REQUESTED';
export const DELETE_ABSENCE_SUCCEEDED = 'DELETE_ABSENCE_SUCCEEDED';
export const DELETE_ABSENCE_FAILED = 'DELETE_ABSENCE_FAILED';

export const NEW_ABSENCES_REQUESTED = 'NEW_ABSENCES_REQUESTED';
export const NEW_ABSENCES_SUCCEEDED = 'NEW_ABSENCES_SUCCEEDED';
export const NEW_ABSENCES_FAILED = 'NEW_ABSENCES_FAILED';

export const UPDATE_BONUS_DETAILS_REQUESTED = 'UPDATE_BONUS_DETAILS_REQUESTED';
export const UPDATE_BONUS_DETAILS_SUCCEEDED = 'UPDATE_BONUS_DETAILS_SUCCEEDED';
export const UPDATE_BONUS_DETAILS_FAILED = 'UPDATE_BONUS_DETAILS_FAILED';

export const GET_BONUS_DETAILS_REQUESTED = 'GET_BONUS_DETAILS_REQUESTED';
export const GET_BONUS_DETAILS_SUCCEEDED = 'GET_BONUS_DETAILS_SUCCEEDED';
export const GET_BONUS_DETAILS_FAILED = 'GET_BONUS_DETAILS_FAILED';

export const DELETE_BONUS_REQUESTED = 'DELETE_BONUS_REQUESTED';
export const DELETE_BONUS_SUCCEEDED = 'DELETE_BONUS_SUCCEEDED';
export const DELETE_BONUS_FAILED = 'DELETE_BONUS_FAILED';

export const BONUS_SET_TABLE_PAGENUMBER ='BONUS_SET_TABLE_PAGENUMBER';
export const BONUS_SELECT_RANGE ='BONUS_SELECT_RANGE';
export const BONUS_SELECT_MYBONUS_FILTER ='BONUS_SELECT_MYBONUS_FILTER';

export const SEARCH_COLLEAGUE = 'SEARCH_COLLEAGUE';
export const REMOVE_COLLEAGUE_SEARCH_FIELD = 'REMOVE_COLLEAGUE_SEARCH_FIELD';
export const ROLE_FILTER = 'ROLE_FILTER';
export const STATUS_FILTER = 'STATUS_FILTER';
export const CLEAR_COLLEAGUE_FILTER = 'CLEAR_COLLEAGUE_FILTER';

export const MAP_GET_MAPINFO_REQUESTED = 'MAP_GET_MAPINFO_REQUESTED';
export const MAP_GET_MAPINFO_SUCCEEDED = 'MAP_GET_MAPINFO_SUCCEEDED';
export const MAP_GET_MAPINFO_FAILED = 'MAP_GET_MAPINFO_FAILED';

export const READ_MESSAGE_REQUESTED = 'READ_MESSAGE_REQUESTED';
export const READ_MESSAGE_SUCCEEDED = 'READ_MESSAGE_SUCCEEDED';
export const READ_MESSAGE_FAILED = 'READ_MESSAGE_FAILED';

export const CHANGE_PROFILE_INPUT = 'CHANGE_PROFILE_INPUT';
export const DEPENDANTS_MODAL_OPEN = 'DEPENDANTS_MODAL_OPEN';
export const EDUCATION_MODAL_OPEN = 'EDUCATION_MODAL_OPEN';
export const DELETE_EXAM_MODAL_OPEN = 'DELETE_EXAM_MODAL_OPEN';
export const DELETE_EXAM_PLAN_MODAL_OPEN = 'DELETE_EXAM_PLAN_MODAL_OPEN';
export const COMPLETE_EXAM_PLAN_MODAL_OPEN = 'COMPLETE_EXAM_PLAN_MODAL_OPEN';
export const EXAM_MODAL_OPEN = 'EXAM_MODAL_OPEN';
export const EXAM_PLAN_MODAL_OPEN = 'EXAM_PLAN_MODAL_OPEN';
export const PROFILE_SELECT_CHANGE = 'PROFILE_SELECT_CHANGE';
export const PROFILE_DATE_CHANGE = 'PROFILE_DATE_CHANGE';
export const REPORT_CHANGE_MODAL_OPEN = 'REPORT_CHANGE_MODAL_OPEN';

export const SAVE_PROFILE_FORM_REQUESTED = 'SAVE_PROFILE_FORM_REQUESTED';
export const SAVE_PROFILE_FORM_SUCCEEDED = 'SAVE_PROFILE_FORM_SUCCEEDED';
export const SAVE_PROFILE_FORM_FAILED = 'SAVE_PROFILE_FORM_FAILED';

export const ADD_EDUCATION_REQUESTED = 'ADD_EDUCATION_REQUESTED';
export const ADD_EDUCATION_SUCCEEDED = 'ADD_EDUCATION_SUCCEEDED';
export const ADD_EDUCATION_FAILED = 'ADD_EDUCATION_FAILED';

export const ADD_DEPENDANT_REQUESTED = 'ADD_DEPENDANT_REQUESTED';
export const ADD_DEPENDANT_SUCCEEDED = 'ADD_DEPENDANT_SUCCEEDED';
export const ADD_DEPENDANT_FAILED = 'ADD_DEPENDANT_FAILED';
export const DELETE_EXAM_REQUESTED = 'DELETE_EXAM_REQUESTED';
export const DELETE_EXAM_SUCCEEDED = 'DELETE_EXAM_SUCCEEDED';
export const DELETE_EXAM_FAILED = 'DELETE_EXAM_FAILED';
export const DELETE_EXAM_PLAN_REQUESTED = 'DELETE_EXAM_PLAN_REQUESTED';
export const DELETE_EXAM_PLAN_SUCCEEDED = 'DELETE_EXAM_PLAN_SUCCEEDED';
export const DELETE_EXAM_PLAN_FAILED = 'DELETE_EXAM_PLAN_FAILED';
export const COMPLETE_EXAM_PLAN_REQUESTED = 'COMPLETE_EXAM_PLAN_REQUESTED';
export const COMPLETE_EXAM_PLAN_SUCCEEDED = 'COMPLETE_EXAM_PLAN_SUCCEEDED';
export const COMPLETE_EXAM_PLAN_FAILED = 'COMPLETE_EXAM_PLAN_FAILED';
export const ADD_EXAM_REQUESTED = 'ADD_EXAM_REQUESTED';
export const ADD_EXAM_SUCCEEDED = 'ADD_EXAM_SUCCEEDED';
export const ADD_EXAM_FAILED = 'ADD_EXAM_FAILED';
export const ADD_EXAM_PLAN_REQUESTED = 'ADD_EXAM_PLAN_REQUESTED';
export const ADD_EXAM_PLAN_SUCCEEDED = 'ADD_EXAM_PLAN_SUCCEEDED';
export const ADD_EXAM_PLAN_FAILED = 'ADD_EXAM_PLAN_FAILED';

export const CHANGE_PERSONAL_DATA_REQUESTED = 'CHANGE_PERSONAL_DATA_REQUESTED';
export const CHANGE_PERSONAL_DATA_SUCCEEDED = 'CHANGE_PERSONAL_DATA_SUCCEEDED';
export const CHANGE_PERSONAL_DATA_FAILED = 'CHANGE_PERSONAL_DATA_FAILED';

export const GET_PERSONAL_DATA_REQUESTED = 'GET_PERSONAL_DATA_REQUESTED';
export const GET_PERSONAL_DATA_FAILED = 'GET_PERSONAL_DATA_FAILED';
export const GET_PERSONAL_DATA_SUCCEEDED = 'GET_PERSONAL_DATA_SUCCEEDED';

export const CHANGE_PROFILE_PICTURE_REQUESTED = 'CHANGE_PROFILE_PICTURE_REQUESTED';
export const CHANGE_PROFILE_PICTURE_SUCCEEDED = 'CHANGE_PROFILE_PICTURE_SUCCEEDED';
export const CHANGE_PROFILE_PICTURE_FAILED = 'CHANGE_PROFILE_PICTURE_FAILED';

export const REMOVE_PROFILE_PICTURE = 'REMOVE_PROFILE_PICTURE';
export const SELECT_PROFILE_PICTURE = 'SELECT_PROFILE_PICTURE';
export const EVENTS_GET_EVENTS_REQUESTED = 'EVENTS_GET_EVENTS_REQUESTED';
export const EVENTS_GET_EVENTS_SUCCEEDED = 'EVENTS_GET_EVENTS_SUCCEEDED';
export const EVENTS_GET_EVENTS_FAILED = 'EVENTS_GET_EVENTS_FAILED';

export const BOOKMARKS_GET_BOOKMARKS_REQUESTED = 'BOOKMARKS_GET_BOOKMARKS_REQUESTED';
export const BOOKMARKS_GET_BOOKMARKS_SUCCEEDED = 'BOOKMARKS_GET_BOOKMARKS_SUCCEEDED';
export const BOOKMARKS_GET_BOOKMARKS_FAILED = 'BOOKMARKS_GET_BOOKMARKS_FAILED';

export const SELECT_WIKI_TOPIC_HISTORY = 'SELECT_WIKI_TOPIC_HISTORY';
export const SELECT_WIKI_HISTORY = 'SELECT_WIKI_HISTORY';
export const SELECT_WIKI_TAG = 'SELECT_WIKI_TAG';
export const SELECT_TOPIC_LANG = 'SELECT_TOPIC_LANG';
export const SELECT_TOPIC = 'SELECT_TOPIC';

export const WIKI_GET_TOPICS_BY_CATEGORY_REQUESTED = 'WIKI_GET_TOPICS_BY_CATEGORY_REQUESTED';
export const WIKI_GET_TOPICS_BY_CATEGORY_SUCCEEDED = 'WIKI_GET_TOPICS_BY_CATEGORY_SUCCEEDED';
export const WIKI_GET_TOPICS_BY_CATEGORY_FAILED = 'WIKI_GET_TOPICS_BY_CATEGORY_FAILED';

export const WIKI_GET_CHANGELOG_REQUESTED = 'WIKI_GET_CHANGELOG_REQUESTED';
export const WIKI_GET_CHANGELOG_SUCCEEDED = 'WIKI_GET_CHANGELOG_SUCCEEDED';
export const WIKI_GET_CHANGELOG_FAILED = 'WIKI_GET_CHANGELOG_FAILED';

export const WIKI_GET_CATEGORIES_REQUESTED = 'WIKI_GET_CATEGORIES_REQUESTED';
export const WIKI_GET_CATEGORIES_SUCCEEDED = 'WIKI_GET_CATEGORIES_SUCCEEDED';
export const WIKI_GET_CATEGORIES_FAILED = 'WIKI_GET_CATEGORIES_FAILED';

export const WIKI_GET_TOPIC_REQUESTED = 'WIKI_GET_TOPIC_REQUESTED';
export const WIKI_GET_TOPIC_SUCCEEDED = 'WIKI_GET_TOPIC_SUCCEEDED';
export const WIKI_GET_TOPIC_FAILED = 'WIKI_GET_TOPIC_FAILED';

export const WIKI_GET_HISTORIES_REQUESTED = 'WIKI_GET_HISTORIES_REQUESTED';
export const WIKI_GET_HISTORIES_SUCCEEDED = 'WIKI_GET_HISTORIES_SUCCEEDED';
export const WIKI_GET_HISTORIES_FAILED = 'WIKI_GET_HISTORIES_FAILED';

export const WIKI_SEARCH_REQUESTED = 'WIKI_SEARCH_REQUESTED';
export const WIKI_SEARCH_SUCCEEDED = 'WIKI_SEARCH_SUCCEEDED';
export const WIKI_SEARCH_FAILED = 'WIKI_SEARCH_FAILED';
export const WIKI_EMPTY_SEARCH_VALUES = 'WIKI_EMPTY_SEARCH_VALUES';
export const WIKI_SET_NO_RESULT_MESSAGE = 'WIKI_SET_NO_RESULT_MESSAGE';
export const WIKI_SET_LIST_ITEM_NUMBER = 'WIKI_SET_LIST_ITEM_NUMBER';

export const WIKI_GET_TAGS_REQUESTED = 'WIKI_GET_TAGS_REQUESTED';
export const WIKI_GET_TAGS_SUCCEEDED = 'WIKI_GET_TAGS_SUCCEEDED';
export const WIKI_GET_TAGS_FAILED = 'WIKI_GET_TAGS_FAILED';
export const SELECT_WIKI_CATEGORY = 'SELECT_WIKI_CATEGORY';

export const WIKI_SET_SEARCH_VALUE = 'WIKI_SET_SEARCH_VALUE';
export const WIKI_SET_SELECTED_TAGS = 'WIKI_SET_SELECTED_TAGS';
export const WIKI_CLOSE_SELECTED_TAG = 'WIKI_CLOSE_SELECTED_TAG';
export const WIKI_EMPTY_SELECTED_TAGS = 'WIKI_EMPTY_SELECTED_TAGS';
export const WIKI_SEARCH_EMPTY_ALL = 'WIKI_SEARCH_EMPTY_ALL';
export const WIKI_CATEGORY_SELECT_FAILED = 'WIKI_CATEGORY_SELECT_FAILED';
export const WIKI_TOPIC_SELECT_FAILED = 'WIKI_TOPIC_SELECT_FAILED';

export const WIKI_TOPIC_LOADING = 'WIKI_TOPIC_LOADING';

export const GET_ABSENCE_TYPES_REQUESTED = 'GET_ABSENCE_TYPES_REQUESTED';
export const GET_ABSENCE_TYPES_SUCCEEDED = 'GET_ABSENCE_TYPES_SUCCEEDED';
export const GET_ABSENCE_TYPES_FAILED = 'GET_ABSENCE_TYPES_FAILED';
