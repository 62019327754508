import React from 'react';
import PropTypes from 'prop-types';
import { Avatar } from 'antd';
import getStatusColor from 'styles/statusColors';
import './avatar.scss';

const camelize = (string) => {
  const withDeletedHyphens = string.replace('-', '');
  return withDeletedHyphens.replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => (
    index === 0 ? word.toLowerCase() : word.toUpperCase()
  )).replace(/\s+/g, '');
};

const IntraAvatar = ({ size, src, status, firstName, lastName, isDisabled }) => {
  const monogram = `${firstName.charAt(0)} ${lastName.charAt(0)}`;
  const camelizedStatus = camelize(status);
  const inOffice = (camelizedStatus === 'inOfficeWorking'
  || camelizedStatus === 'inOfficeNotWorking');
  const styles = {
    width: `${size + 8}px`,
    height: `${size + 8}px`,
    borderColor: getStatusColor(status),
  };
  return (
    src
      ? (
        <div
          style={styles}
          className="avatar-container"
        >
          <Avatar
            size={size}
            src={src}
            style={!inOffice && !isDisabled ? { filter: 'grayscale(1)' } : null}
          />
        </div>
      )
      : (
        <div
          className="avatar-container"
          style={styles}
        >
          <Avatar
            size={size}
            style={inOffice ? { backgroundColor: '#3895FF' } : { backgroundColor: '#CCCCCC' }}
          >
            {monogram}
          </Avatar>
        </div>
      )
  );
};

IntraAvatar.propTypes = {
  isDisabled: PropTypes.bool,
  size: PropTypes.number.isRequired,
  src: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
};

IntraAvatar.defaultProps = {
  isDisabled: false,
};

export default IntraAvatar;
