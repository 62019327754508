const initialState = {
  currentStatus: [],
  attendanceStatus: null,
  roles: null,
  searchField: '',
  filteredRoles: [],
  filteredStatuses: [],
  filteredColleagues: null,
  isLoading: false,
  hasLoadingError: false,
  errorMessage: '',
  colleagues: null,
};

export default initialState;
