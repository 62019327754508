import React from 'react';
import PropTypes from 'prop-types';
import IosClock from 'react-ionicons/lib/IosClock';
import IosTrendingUp from 'react-ionicons/lib/IosTrendingUp';
import IosPie from 'react-ionicons/lib/IosPie';
import IosSunny from 'react-ionicons/lib/IosSunny';
import IosListBox from 'react-ionicons/lib/IosListBox';
import LogoUsd from 'react-ionicons/lib/LogoUsd';
import IosCalendar from 'react-ionicons/lib/IosCalendar';
import ShareOutlined from 'components/Icons/ShareOutlined';
import CelebrationOutlined from 'components/Icons/CelebrationOutlined';
import './chart-title.scss';

const components = {
  IosClock,
  IosTrendingUp,
  IosPie,
  IosListBox,
  LogoUsd,
  IosSunny,
  IosCalendar,
  ShareOutlined,
  CelebrationOutlined,
};

function ChartIcon({ iconName, fontSize }) {
  const Icon = components[iconName];
  return <Icon fontSize={fontSize} />;
}

const ChartTitle = ({ chartTitle, iconName }) => (
  <div className="chart-title">
    <ChartIcon iconName={iconName} fontSize="18px" />
    <div className="chart-title-text">{chartTitle}</div>
  </div>
);

ChartIcon.propTypes = {
  iconName: PropTypes.string.isRequired,
  fontSize: PropTypes.string.isRequired,
};
ChartTitle.propTypes = {
  chartTitle: PropTypes.string.isRequired,
  iconName: PropTypes.string.isRequired,
};

export default ChartTitle;
