import React from 'react';
import PropTypes from 'prop-types';
import { Dashboard } from '@uppy/react';
import IntraModal from 'components/Modal/Modal';
import './picture-modal.scss';


const PictureModal = ({ showModal, handleClose, handleSubmit, picture, uppy }) => (
  <IntraModal
    title="PROFILE PICTURE"
    open={showModal}
    close={handleClose}
    disabled={!picture}
    submit={handleSubmit}
    okButton="SAVE"
    cancelButton="CANCEL"
    className="profile-picture-modal"
  >
    <Dashboard
      uppy={uppy}
      height={300}
      plugins={[]}
      showProgressDetails={false}
      hideUploadButton
      proudlyDisplayPoweredByUppy={false}
      allowMultipleUploads={false}
      target="body"
      locale={{ strings: { xFilesSelected: { 0: 'PREVIEW' } } }}
    />
  </IntraModal>
);

PictureModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  picture: PropTypes.shape({
    name: PropTypes.string,
  }),
  uppy: PropTypes.shape({}).isRequired,
};

PictureModal.defaultProps = {
  picture: null,
};

export default PictureModal;
