import React from 'react';
import PropTypes from 'prop-types';

const WorktimeChartDateLabel = ({ weekTitle }) => (
  <div className="worktime-chart-date-label">{weekTitle}</div>
);

WorktimeChartDateLabel.propTypes = {
  weekTitle: PropTypes.string,
};

WorktimeChartDateLabel.defaultProps = {
  weekTitle: null,
};

export default WorktimeChartDateLabel;
