import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DatePicker } from 'antd';
import moment from 'moment';
import { getWorktimeList, emptyWorktimeList } from 'actions/worktimeActions';
import MagicTable from 'containers/MagicTable/MagicTable';
import ChartTitle from 'components/Charts/ChartTitle/ChartTitle';
import { selectFilter, selectRange } from 'actions/tableActions';
import './status-and-worktime-container.scss';
import { getPageTitle } from '../../utils/branding';

const { RangePicker } = DatePicker;

class StatusAndWorktimeContainer extends Component {
  ranges = {
    'A week': [moment().subtract(7, 'd'), moment()],
    'A month': [moment().subtract(1, 'months'), moment()],
    '3 month': [moment().subtract(3, 'months'), moment()],
  }

  componentDidMount() {
    document.title = getPageTitle('Status & Worktime');
    const { dispatchFilterTable } = this.props;
    dispatchFilterTable('status', 'date', '');
  }

  componentWillUnmount() {
    const { dispatchEmptyWorktimeList } = this.props;
    dispatchEmptyWorktimeList();
  }

  onChange = (dates, dateStrings) => {
    const { dispatchSelectRange } = this.props;
    dispatchSelectRange('status', dateStrings[0], dateStrings[1]);
  }

  render() {
    const { dispatchGetWorktimeList, worktimeList, date, isLoading } = this.props;
    const dailyWorkList = [];
    if (worktimeList) {
      Object.values(worktimeList).forEach((work) => {
        const data = {
          data: work,
        };
        dailyWorkList.push(data);
      });
    }
    return (
      <div className="status-and-worktime-card">
        <ChartTitle
          chartTitle="worktime"
          iconName="IosClock"
        />
        <div className="status-and-worktime-table-container">
          <div id="status-and-wortime-range-container">
            <RangePicker
              ranges={this.ranges}
              onChange={this.onChange}
            />
          </div>
          <div className="magictable-container">
            <MagicTable
              table="status"
              tableRowClick={(name, row) => row.data.date !== date && dispatchGetWorktimeList(row.data.date)}
              selected={date}
            >
              <MagicTable
                table="outer"
                outerDataSource={dailyWorkList}
                isLoading={isLoading}
                customStyle
                outerColumns={[
                  { title: 'time', key: 'time', dataIndex: 'time' },
                  { title: 'status', key: 'status', dataIndex: 'status_name' },
                  { title: 'data source', key: 'dataSource', dataIndex: 'datasource' },
                ]}
              />
            </MagicTable>
          </div>
        </div>
      </div>
    );
  }
}

StatusAndWorktimeContainer.propTypes = {
  worktimeList: PropTypes.shape({}),
  dispatchFilterTable: PropTypes.func.isRequired,
  dispatchSelectRange: PropTypes.func.isRequired,
  dispatchGetWorktimeList: PropTypes.func.isRequired,
  dispatchEmptyWorktimeList: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  date: PropTypes.string,
};

StatusAndWorktimeContainer.defaultProps = {
  worktimeList: null,
  date: null,
};

const mapStateToProps = (store) => ({
  isLoading: store.worktimeReducer.isLoading,
  worktimeList: store.worktimeReducer.worktimeList,
  date: store.worktimeReducer.date,
});

const mapDispatchToProps = {
  dispatchFilterTable: selectFilter,
  dispatchSelectRange: selectRange,
  dispatchGetWorktimeList: getWorktimeList,
  dispatchEmptyWorktimeList: emptyWorktimeList,
};

export default connect(mapStateToProps, mapDispatchToProps)(StatusAndWorktimeContainer);
