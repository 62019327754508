import React from 'react';
import { Collapse } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import IosArrowDown from 'react-ionicons/lib/IosArrowDown';
import './wiki-details-history.scss';

const { Panel } = Collapse;


const WikiDetailsHistory = ({ history }) => history && history.length && (
  <Collapse>
    <Panel
      header={(
        <div className="wiki-details-header-container">
          <span>DOCUMENT HISTORY:</span>
          <IosArrowDown className="arrow-transform" />
        </div>
      )}
      key="1"
      showArrow={false}
    >
      <div className="wiki-details-panel-container">
        {history && history.length && history.map((element) => element && element.id && (
          <div className="wiki-details-card-container" key={element.id.toString()}>
            <div className="wiki-details-link-container">
              <div className="wiki-details-date">{moment(element.last_edit).format('DD MMM YYYY')}</div>
              <a
                className="edited-by"
                role="button"
                tabIndex={0}
                // onClick={() => { changeDocumentHistory(element.id); }}
              >
                {element.edited_by}
              </a>
            </div>
          </div>
        ))}
      </div>
    </Panel>
  </Collapse>
);

WikiDetailsHistory.propTypes = {
  history: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default WikiDetailsHistory;
