import { notification } from 'antd';

export const validatePhone = (phoneNumber) => {
  if (phoneNumber !== '' && phoneNumber.includes('+36')) {
    const withoutPrefix = phoneNumber.split('+36').join('');
    return (!Number.isNaN(Number(withoutPrefix)) && withoutPrefix.length === 9);
  }
  return false;
};

export const validateEmail = (email) => {
  const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line
  return email !== '' ? regex.test(String(email).toLowerCase()) : true;
};

export const validateBankAccount = (bankAccount) => {
  const withoutSpace = bankAccount.toString().split('-')
    .join('').split(' ')
    .join('');
  return bankAccount !== '' ? !Number.isNaN(Number(withoutSpace)) && (withoutSpace.length === 16 || withoutSpace.length === 24) : true;
};

export const validateTajNumber = (tajNumber) => {
  const withoutSpace = tajNumber.toString().split('-')
    .join('').split(' ')
    .join('');
  return tajNumber !== '' ? !Number.isNaN(Number(withoutSpace)) && withoutSpace.length === 9 : true;
};

export const validateTaxNumber = (taxNumber) => {
  const withoutSpace = taxNumber.toString().split('-')
    .join('').split(' ')
    .join('');
  return taxNumber !== '' ? !Number.isNaN(Number(withoutSpace)) && withoutSpace.length === 10 : true;
};

export const openNotificationWithIcon = (type, message, description) => {
  notification[type]({
    duration: 3,
    message,
    description,
  });
};

const isValidHttpUrl = (string) => {
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    let errorMessage = 'It is not a valid URL: ';
    errorMessage += string;
    throw new Error(errorMessage);
  }
  return {
    isHttpUrl: url.protocol === 'http:' || url.protocol === 'https:',
    isHttps: url.protocol === 'https:',
  };
};

export const urlCheck = (url) => {
  const checkResult = isValidHttpUrl(url);
  const { isHttpUrl, isHttps } = checkResult;
  let result = url;
  if (!isHttpUrl) throw new Error('It is not a valid http URL!');
  if (!isHttps) {
    // no need to rewrite the hostname.
    // result = `${url.slice(0, 4)}s${url.slice(4)}`;
  }
  if (result[result.length - 1] === '/') {
    result = result.slice(0, -1);
  }
  return result;
};
