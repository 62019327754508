import React from 'react';
import PropTypes from 'prop-types';
import { Popover } from 'antd';
import { ReactComponent as InfoSVG } from 'assets/images/Info_ic.svg';
import './info-hover.scss';

const InfoHover = ({ content, title }) => (
  <Popover placement="bottomRight" content={content} title={title} trigger="hover">
    <div className="info-icon-container">
      <InfoSVG />
    </div>
  </Popover>
);

InfoHover.propTypes = {
  content: PropTypes.shape({}).isRequired,
  title: PropTypes.string.isRequired,
};

export default InfoHover;
