import { combineReducers } from 'redux';
import appReducer from 'reducers/app/appReducer';
import absenceReducer from 'reducers/absence/absenceReducer';
// import bonusReducer from 'reducers/bonus/bonusReducer';
import colleaguesReducer from 'reducers/colleagues/colleaguesReducer';
// import messageReducer from 'reducers/message/messageReducer';
// import officeMapReducer from 'reducers/officeMap/officeMapReducer';
import tableReducer from 'reducers/table/tableReducer';
import userReducer from 'reducers/user/userReducer';
import worktimeReducer from 'reducers/worktime/worktimeReducer';
import profileReducer from 'reducers/profile/profileReducer';
import eventsReducer from 'reducers/events/eventsReducer';
import wikiReducer from 'reducers/wiki/wikiReducer';
import bookmarksReducer from 'reducers/bookmark/bookmarksReducer';

const rootReducer = combineReducers({
  absenceReducer,
  appReducer,
  bookmarksReducer,
  // bonusReducer,
  colleaguesReducer,
  // messageReducer,
  // officeMapReducer,
  tableReducer,
  userReducer,
  worktimeReducer,
  profileReducer,
  eventsReducer,
  wikiReducer,
});

export default rootReducer;
