import React from 'react';
import PropTypes from 'prop-types';
import { Menu, Checkbox, Dropdown } from 'antd';
import IosArrowDown from 'react-ionicons/lib/IosArrowDown';
import StatusCard from 'components/ColleagueDropDown/StatusCard';

class ColleagueDropDown extends React.Component {
  state = {
    visible: false,
  };

  handleVisibleChange = (flag) => {
    this.setState({
      visible: flag,
    });
  };

  renderMenu = () => {
    const { attendanceStatus, filteredStatuses, roles, filteredRoles, onStatusChange, onRoleChange, onClearFilter } = this.props;
    return (
      <Menu
        className="menu-style"
        onClick={({ domEvent }) => { this.handleVisibleChange(true); domEvent.stopPropagation(); }}
      >
        <Menu.Item key="0">
          <div className="filter-title">OFFICE STATUS</div>
          <Checkbox.Group
            options={attendanceStatus}
            value={filteredStatuses}
            onChange={onStatusChange}
          />
        </Menu.Item>
        <Menu.Item key="1">
          <div className="filter-title">ROLE</div>
          <Checkbox.Group
            options={roles}
            value={filteredRoles}
            onChange={onRoleChange}
          />
        </Menu.Item>
        <div className="dropdown-clear-all">
          <div
            className="dropdown-clear-all-button"
            tabIndex={0}
            role="button"
            onKeyPress={() => { }}
            onClick={() => { this.handleVisibleChange(false); onClearFilter(); }}
          >
            CLEAR ALL
          </div>
        </div>
      </Menu>
    );
  }

  render() {
    const { visible } = this.state;
    const { filteredStatuses, filteredRoles, width } = this.props;
    return (
      <Dropdown
        overlay={this.renderMenu}
        trigger={['click']}
        overlayClassName="menu-container"
        onVisibleChange={this.handleVisibleChange}
        visible={visible}
        placement={width < 768 ? 'bottomCenter' : 'bottomLeft'}
      >
        <div className="dropdown-filter-title">
          <div className="dropdown-filter-placeholder">
            {
              !filteredStatuses.length && !filteredRoles.length
                ? <div className="dropdown-filter-placeholder-title">Filter by office status and role</div>
                : null
            }
            <StatusCard array={filteredStatuses} title="Status:" />
            <StatusCard array={filteredRoles} title="Roles:" />
          </div>
          <IosArrowDown fontSize="16px" />
        </div>
      </Dropdown>
    );
  }
}

ColleagueDropDown.propTypes = {
  attendanceStatus: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  roles: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onRoleChange: PropTypes.func.isRequired,
  onStatusChange: PropTypes.func.isRequired,
  onClearFilter: PropTypes.func.isRequired,
  filteredStatuses: PropTypes.arrayOf(PropTypes.string).isRequired,
  filteredRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
  width: PropTypes.number.isRequired,
};

export default ColleagueDropDown;
