import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  getColleagues, searchColleague,
  removeSearchField, roleChange,
  statusChange, clearFilter,
} from 'actions/colleaguesActions';
import IosSadOutline from 'react-ionicons/lib/IosSadOutline';
import BasicSearch from 'components/BasicSearch/BasicSearch';
import ColleagueDropDown from 'components/ColleagueDropDown/ColleagueDropDown';
import ColleagueRole from 'components/ColleagueRole/ColleagueRole';
import SpinnerContainer from 'components/SpinnerContainer/SpinnerContainer';
import './colleagues.scss';
import { getPageTitle } from '../../utils/branding';

class ColleaguesPage extends React.Component {
  state = {
    width: window.innerWidth,
  }

  componentDidMount() {
    document.title = getPageTitle('Colleagues');
    const { dispatchGetColleagues } = this.props;
    dispatchGetColleagues();
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  render() {
    const {
      attendanceStatus, roles,
      dispatchSearchColleague, dispatchRemoveSearchField,
      searchField, dispatchRoleChange, dispatchStatusChange,
      dispatchClearFilter, filteredStatuses, filteredRoles,
      filteredColleagues,
    } = this.props;
    const { width } = this.state;
    const realStatuses = filteredStatuses && filteredStatuses.length ? filteredStatuses : attendanceStatus;
    if (filteredColleagues === null) {
      return (
        <SpinnerContainer className="spin-position" />
      );
    }
    return (
      <>
        <div className="header-container">
          <BasicSearch
            onChange={(value) => { dispatchSearchColleague(value); }}
            removeSearchField={() => { dispatchRemoveSearchField(); }}
            searchField={searchField}
            placeholderText="Search for name"
          />
          <ColleagueDropDown
            attendanceStatus={attendanceStatus}
            roles={roles}
            onRoleChange={(role) => { dispatchRoleChange(role); }}
            onStatusChange={(status) => { dispatchStatusChange(status); }}
            onClearFilter={() => { dispatchClearFilter(); }}
            filteredStatuses={filteredStatuses}
            filteredRoles={filteredRoles}
            width={width}
          />
        </div>
        <div className="colleagues-body-container">
          {
            realStatuses.length
              ? realStatuses.map((attendance) => (
                <ColleagueRole
                  key={attendance.value ? attendance.value : attendance}
                  filteredColleagues={filteredColleagues}
                  attendance={attendance.value ? attendance.value : attendance}
                  width={width}
                />
              ))
              : null
          }
          {
            filteredColleagues.length
              ? null
              : (
                <div className="no-result">
                  <IosSadOutline fontSize="60px" />
                  No colleagues found for the given filter criteria.
                </div>
              )
          }
        </div>
      </>
    );
  }
}

const mapDispatchToProps = {
  dispatchGetColleagues: getColleagues,
  dispatchSearchColleague: searchColleague,
  dispatchRemoveSearchField: removeSearchField,
  dispatchRoleChange: roleChange,
  dispatchStatusChange: statusChange,
  dispatchClearFilter: clearFilter,
};

const mapStateToProps = (store) => ({
  attendanceStatus: store.colleaguesReducer.attendanceStatus,
  roles: store.colleaguesReducer.roles,
  searchField: store.colleaguesReducer.searchField,
  filteredStatuses: store.colleaguesReducer.filteredStatuses,
  filteredRoles: store.colleaguesReducer.filteredRoles,
  filteredColleagues: store.colleaguesReducer.filteredColleagues,
});

ColleaguesPage.propTypes = {
  dispatchClearFilter: PropTypes.func.isRequired,
  dispatchGetColleagues: PropTypes.func.isRequired,
  dispatchRemoveSearchField: PropTypes.func.isRequired,
  dispatchSearchColleague: PropTypes.func.isRequired,
  dispatchStatusChange: PropTypes.func.isRequired,
  dispatchRoleChange: PropTypes.func.isRequired,
  searchField: PropTypes.string.isRequired,
  roles: PropTypes.arrayOf(PropTypes.shape({})),
  filteredColleagues: PropTypes.arrayOf(PropTypes.shape({})),
  attendanceStatus: PropTypes.arrayOf(PropTypes.shape({})),
  filteredStatuses: PropTypes.arrayOf(PropTypes.string).isRequired,
  filteredRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
};

ColleaguesPage.defaultProps = {
  filteredColleagues: null,
  roles: null,
  attendanceStatus: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(ColleaguesPage);
