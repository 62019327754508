const initialState = {
  user: {
    avatar: {},
  },
  isMobile: window.innerWidth < 768,
  showMenu: window.innerWidth >= 992,
  currentPage: '/dashboard',
};

export default initialState;
