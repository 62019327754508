import {
  USER_LOGOUT_SUCCEEDED, SELECT_WIKI_TOPIC_HISTORY, SELECT_WIKI_TAG,
  SELECT_TOPIC_LANG, SELECT_TOPIC, WIKI_GET_CATEGORIES_SUCCEEDED,
  WIKI_GET_HISTORIES_SUCCEEDED, WIKI_GET_CHANGELOG_SUCCEEDED,
  WIKI_GET_TOPICS_BY_CATEGORY_SUCCEEDED, WIKI_GET_TOPICS_BY_CATEGORY_REQUESTED, WIKI_GET_TOPICS_BY_CATEGORY_FAILED,
  WIKI_SEARCH_REQUESTED, WIKI_SEARCH_SUCCEEDED, WIKI_SEARCH_FAILED,
  WIKI_EMPTY_SEARCH_VALUES, WIKI_GET_TAGS_SUCCEEDED,
  SELECT_WIKI_CATEGORY, WIKI_SET_SEARCH_VALUE,
  WIKI_SET_SELECTED_TAGS, WIKI_CLOSE_SELECTED_TAG,
  WIKI_EMPTY_SELECTED_TAGS, WIKI_SEARCH_EMPTY_ALL,
  WIKI_TOPIC_LOADING, SELECT_WIKI_HISTORY, WIKI_SET_NO_RESULT_MESSAGE,
  WIKI_GET_TOPIC_REQUESTED, WIKI_GET_TOPIC_SUCCEEDED, WIKI_GET_TOPIC_FAILED,
} from 'consts/actionTypes';
import initialState from 'reducers/wiki/initialState';

const wikiReducer = (state = initialState, action) => {
  switch (action.type) {
    case WIKI_TOPIC_LOADING:
      return {
        ...state,
        topicLoading: true,
      };

    case WIKI_GET_TOPICS_BY_CATEGORY_FAILED:
      return {
        ...state,
        categoryLoading: false,
      };

    case WIKI_GET_TOPICS_BY_CATEGORY_REQUESTED:
      return {
        ...state,
        categoryLoading: true,
      };

    case SELECT_WIKI_TOPIC_HISTORY: {
      const { key } = action.payload;
      return {
        ...state,
        selectedHistory: key,
      };
    }

    case SELECT_WIKI_TAG: {
      const { tagId } = action.payload;
      return {
        ...state,
        selectedTag: tagId,
      };
    }

    case WIKI_SET_SEARCH_VALUE: {
      const { searchValue } = action.payload;
      return {
        ...state,
        searchValue,
      };
    }

    case WIKI_SET_SELECTED_TAGS: {
      const { tag } = action.payload;
      if (state.selectedTags.indexOf(tag) === -1) {
        return {
          ...state,
          selectedTags: [...state.selectedTags, tag],
        };
      }
      return state;
    }

    case WIKI_CLOSE_SELECTED_TAG: {
      const { tag } = action.payload;
      const index = state.selectedTags.indexOf(tag);
      const newTagList = [...state.selectedTags];
      newTagList.splice(index, 1);
      return {
        ...state,
        selectedTags: newTagList,
      };
    }

    case WIKI_EMPTY_SELECTED_TAGS: {
      return {
        ...state,
        selectedTags: [],
      };
    }

    case WIKI_SEARCH_EMPTY_ALL: {
      return {
        ...state,
        selectedTags: [],
        searchValue: '',
        searchResults: [],
      };
    }

    case SELECT_TOPIC_LANG: {
      const { lang } = action.payload;
      return {
        ...state,
        selectedLang: lang,
        backupLang: state.selectedLang,
      };
    }

    case SELECT_TOPIC: {
      const { topic } = action.payload;
      return {
        ...state,
        selectedTopic: topic,
        topicLoading: false,
      };
    }

    case SELECT_WIKI_CATEGORY: {
      const { category } = action.payload;
      return {
        ...state,
        selectedCategory: category,
        categoryLoading: true,
      };
    }

    case WIKI_GET_CATEGORIES_SUCCEEDED: {
      const { data } = action.payload;
      const categories = Object.keys(data).map((item) => ({ id: item, displayName: data[item], subTitle: data[item] }));
      return {
        ...state,
        categories: categories.reduce((prev, obj) => ({
          ...prev,
          [obj.displayName.replace(' ', '-')]: {
            ...obj,
          },
        }), {}),
      };
    }

    case SELECT_WIKI_HISTORY: {
      const { selectedLang, backupLang, selectedTopic, histories } = state;
      const currentLang = selectedTopic[selectedLang] ? selectedLang : backupLang;
      return {
        ...state,
        currentHistories: selectedTopic[currentLang].history.map((id) => histories[id]),
      };
    }

    case WIKI_GET_HISTORIES_SUCCEEDED: {
      const { selectedLang, backupLang, selectedTopic, histories } = state;
      const { data } = action.payload;
      const newHistories = {
        ...histories,
        ...data.reduce((prev, history) => ({
          ...prev,
          [history.id.toString()]: history,
        }), {}),
      };
      const currentLang = selectedTopic[selectedLang] ? selectedLang : backupLang;
      return {
        ...state,
        histories: newHistories,
        currentHistories: selectedTopic[currentLang].history.map((id) => newHistories[id]),
      };
    }

    case WIKI_GET_CHANGELOG_SUCCEEDED: {
      const { data } = action.payload;
      const formattedData = data.map((item) => ({ data: item }));
      return {
        ...state,
        changeLog: formattedData,
      };
    }

    case WIKI_GET_TOPICS_BY_CATEGORY_SUCCEEDED: {
      const { data, categoryName } = action.payload;
      const newArr = data.map((topic) => {
        const title = topic.eng ? topic.eng.title : topic.hun.title;
        return {
          ...topic,
          title,
          subTopics: topic.subTopics.length ? topic.subTopics.map((subTopic) => {
            const subTitle = subTopic.eng ? subTopic.eng.title : subTopic.hun.title;
            return {
              ...subTopic,
              title: subTitle,
              subTopics: subTopic.subTopics.length ? subTopic.subTopics.map((subSubTopic) => {
                const subSubTitle = subSubTopic.eng ? subSubTopic.eng.title : subSubTopic.hun.title;
                return {
                  ...subSubTopic,
                  title: subSubTitle,
                };
              }) : [],
            };
          }) : [],
        };
      });

      return {
        ...state,
        categoryLoading: false,
        topics: {
          ...state.topics,
          [categoryName]: newArr,
        },
      };
    }

    case WIKI_SEARCH_REQUESTED:
      return {
        ...state,
        searchLoading: true,
      };

    case WIKI_EMPTY_SEARCH_VALUES:
      return {
        ...state,
        searchResults: null,
      };

    case WIKI_SEARCH_SUCCEEDED:
      return {
        ...state,
        searchResults: action.payload.data,
        searchLoading: false,
      };

    case WIKI_SEARCH_FAILED:
      return {
        ...state,
        searchLoading: false,
      };

    case WIKI_GET_TOPIC_REQUESTED:
      return {
        ...state,
        topicLoading: true,
      };

    case WIKI_GET_TOPIC_SUCCEEDED:
      return {
        ...state,
        topicLoading: false,
      };

    case WIKI_GET_TOPIC_FAILED:
      return {
        ...state,
        topicLoading: false,
      };

    case WIKI_GET_TAGS_SUCCEEDED:
      return {
        ...state,
        tags: action.payload.data,
      };

    case WIKI_SET_NO_RESULT_MESSAGE: {
      const { noResultMessage } = action.payload;
      return {
        ...state,
        noResultMessage,
      };
    }

    case USER_LOGOUT_SUCCEEDED:
      return initialState;

    default:
      return state;
  }
};

export default wikiReducer;
