import React, { useState } from 'react';
import ChartTitle from 'components/Charts/ChartTitle/ChartTitle';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SpinnerContainer from 'components/SpinnerContainer/SpinnerContainer';
import BookmarkCategoryMenu from './BookmarkCategoryMenu';
import BookmarkAccordion from './BookmarkAccordion';
import 'components/Bookmarks/bookmark-container.scss';

const BookmarkContainer = ({ isMobile, bookmarks }) => {
  if (bookmarks === null) {
    return (
      <div className="events-main-container">
        <SpinnerContainer className="events-spinner-container" />
      </div>
    );
  }
  if (bookmarks[0] === undefined) {
    return (
      <></>
    );
  }
  const [selectedCategory, setSelectedCategory] = useState(!isMobile ? bookmarks[0].category.id : null);
  return (
    <div className="bookmarks-container">
      <ChartTitle chartTitle="Devertix jumpstart" iconName="ShareOutlined" />
      <div className="bookmarks-container-main">
        {!isMobile && (
          <div className="side-menu">
            <BookmarkCategoryMenu
              items={bookmarks}
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
            />
          </div>
        )}
        <div className="content">
          <BookmarkAccordion
            items={bookmarks}
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
            isMobile={isMobile}
          />
        </div>
      </div>
    </div>
  );
};

BookmarkContainer.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  bookmarks: PropTypes.arrayOf(PropTypes.object),
};
BookmarkContainer.defaultProps = {
  bookmarks: [],
};
const mapStateToProps = (state) => ({
  isMobile: state.appReducer.isMobile,
});

export default connect(mapStateToProps)(BookmarkContainer);
