import React from 'react';
import PropTypes from 'prop-types';
import { history } from 'services/config';


const WikiTopics = ({ topics, selectedTopic, handleClick, selectedCategory }) => (
  <div className="wiki-topics-topic-container">
    {
      topics && topics.map((topic) => (
        <div
          key={topic.id}
          className={`wiki-topics-topic${selectedTopic.id === topic.id ? ' active' : ''}`}
          role="button"
          tabIndex={0}
          onKeyPress={() => { }}
          onClick={() => { handleClick(topic); history.push(`/wiki/${selectedCategory}/${topic.id}`); }}
        >
          {topic.eng ? topic.eng.title : topic.hun.title}
          {topic.subTopics.length ? topic.subTopics.map((subTopic) => (
            <div
              key={subTopic.id}
              className={`wiki-topics-subtopic${selectedTopic.id === subTopic.id ? ' active' : ''}`}
              role="button"
              tabIndex={0}
              onKeyPress={() => { }}
              onClick={(e) => { e.stopPropagation(); handleClick(subTopic); history.push(`/wiki/${selectedCategory}/${subTopic.id}`); }}
            >
              {subTopic.eng ? subTopic.eng.title : subTopic.hun.title}
              {subTopic.subTopics.length ? subTopic.subTopics.map((subSubTopic) => (
                <div
                  key={subSubTopic.id}
                  className={`wiki-topics-subtopic${selectedTopic.id === subSubTopic.id ? ' active' : ''}`}
                  role="button"
                  tabIndex={0}
                  onKeyPress={() => { }}
                  onClick={(e) => { e.stopPropagation(); handleClick(subSubTopic); history.push(`/wiki/${selectedCategory}/${subSubTopic.id}`); }}
                >
                  {subSubTopic.eng ? subSubTopic.eng.title : subSubTopic.hun.title}
                </div>
              )) : null}
            </div>
          )) : null}
        </div>
      ))
    }
  </div>
);

WikiTopics.propTypes = {
  topics: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleClick: PropTypes.func.isRequired,
  selectedCategory: PropTypes.string.isRequired,
  selectedTopic: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
};

export default WikiTopics;
