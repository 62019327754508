import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { sortClick, selectFilter, pagerClick } from 'actions/tableActions';
import Table from 'components/Tables/Table';
import MobileTable from 'components/Tables/MobileTable';


export const MagicTable = ({
  render, children, selected,
  outerDataSource, outerColumns,
  tableRowClick, tableReducer, table, isMobile, isLoading,
  dispatchSortClick, dispatchSelectFilter, dispatchPagerClick,
  customStyle, outerPager, outerPagerClick,
}) => {
  const { dataSource, columns, filters, sort, isMobileView, isClickable, pager = outerPager } = tableReducer[table];

  const applyTableActions = () => {
    const currentSource = outerDataSource || dataSource;
    return currentSource.map((data) => ({
      ...data,
      data: {
        ...Object.entries(data.data).reduce((prev, [key, value]) => ({
          ...prev,
          [key]: render[key]
            ? render[key](data.data, key, value)
            : value,
        }), {}),
      },
      selected: selected === data.data.date,
      handleRowClick: isClickable && tableRowClick
        ? tableRowClick
        : null,
    }));
  };

  const transformPager = () => {
    if (isEmpty(pager) || pager.totalRows < 1 || (outerDataSource && !outerPager)) {
      return null;
    }

    const showAmount = 5;
    const rows = [];

    for (let i = 0; i < showAmount; i += 1) {
      const index = pager.currentPage + i - 2;
      if (i === 0 && index > 0) {
        rows.push({
          value: 0,
          type: 'normal',
        });
        if (index > 1) {
          rows.push({
            value: pager.currentPage - 3 < 0 ? 0 : pager.currentPage - 3,
            type: 'back',
          });
        }
      }

      if (index >= 0 && index <= pager.totalRows) {
        rows.push({
          type: 'normal',
          value: pager.currentPage + i - 2,
        });
      }
      if (i === showAmount - 1 && index < pager.totalRows) {
        if (index < pager.totalRows - 1) {
          rows.push({
            value: pager.currentPage + 3 > pager.totalRows ? pager.totalRows : pager.currentPage + 3,
            type: 'forward',
          });
        }
        rows.push({
          value: pager.totalRows,
          type: 'normal',
        });
      }
    }

    return {
      ...pager,
      totalRows: rows,
    };
  };

  const transformColumns = () => (outerColumns
    ? outerColumns.map((column) => ({
      ...column,
      handleClick: column.sortable
        ? dispatchSortClick
        : () => { },
      handleSelect: column.filters
        ? dispatchSelectFilter
        : null,
    }))
    : columns.map((column) => ({
      ...column,
      handleClick: column.sortable
        ? dispatchSortClick
        : () => { },
      handleSelect: column.filters
        ? dispatchSelectFilter
        : null,
    })));

  return isMobileView && isMobile
    ? (
      <MobileTable
        dataSource={applyTableActions()}
        columns={transformColumns()}
        table={table}
        filters={filters || []}
        pager={transformPager()}
        handlePagerClick={outerPagerClick || dispatchPagerClick}
        sort={sort}
      >
        {
          children && children
        }
      </MobileTable>
    )
    : (
      <Table
        dataSource={applyTableActions()}
        columns={transformColumns()}
        table={table}
        filters={filters || []}
        pager={transformPager()}
        handlePagerClick={outerPagerClick || dispatchPagerClick}
        sort={sort}
        customStyle={customStyle}
        isLoading={isLoading}
      >
        {
          children && children
        }
      </Table>
    );
};

MagicTable.propTypes = {
  selected: PropTypes.string,
  children: PropTypes.node,
  outerPager: PropTypes.shape({}),
  outerPagerClick: PropTypes.func,
  outerDataSource: PropTypes.arrayOf(PropTypes.shape({
    handeRowClick: PropTypes.func,
    data: PropTypes.shape({}),
  })),
  outerColumns: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.node),
    ]).isRequired,
    key: PropTypes.string.isRequired,
    dataIndex: PropTypes.string.isRequired,
    filters: PropTypes.arrayOf(PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ])),
    isSortable: PropTypes.bool,
  })),
  tableRowClick: PropTypes.func,
  dispatchSortClick: PropTypes.func.isRequired,
  dispatchSelectFilter: PropTypes.func.isRequired,
  dispatchPagerClick: PropTypes.func.isRequired,
  render: PropTypes.shape(),
  table: PropTypes.string,
  tableReducer: PropTypes.shape().isRequired,
  isMobile: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  customStyle: PropTypes.bool,
};

MagicTable.defaultProps = {
  children: null,
  outerColumns: null,
  outerDataSource: null,
  tableRowClick: null,
  render: {},
  selected: null,
  table: '',
  isLoading: false,
  customStyle: false,
  outerPager: null,
  outerPagerClick: null,
};

const mapStateToProps = (state) => ({
  tableReducer: state.tableReducer,
  isMobile: state.appReducer.isMobile,
});

const mapDispatchToProps = {
  dispatchSortClick: sortClick,
  dispatchSelectFilter: selectFilter,
  dispatchPagerClick: pagerClick,
};

export default connect(mapStateToProps, mapDispatchToProps)(MagicTable);
