import {
  BOOKMARKS_GET_BOOKMARKS_FAILED,
  BOOKMARKS_GET_BOOKMARKS_REQUESTED,
  BOOKMARKS_GET_BOOKMARKS_SUCCEEDED,
  USER_LOGOUT_SUCCEEDED,
} from 'consts/actionTypes';
import initialState from 'reducers/bookmark/initialState';

const bookmarksReducer = (state = initialState, action) => {
  switch (action.type) {
    case BOOKMARKS_GET_BOOKMARKS_REQUESTED:
      return {
        ...state,
        isLoading: true,
      };

    case BOOKMARKS_GET_BOOKMARKS_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        bookmarks: action.payload.bookmarks,
        hasLoadingError: false,
        errorMessage: '',
      };

    case BOOKMARKS_GET_BOOKMARKS_FAILED:
      return {
        ...state,
        isLoading: false,
        hasLoadingError: true,
        errorMessage: action.payload,
      };
    case USER_LOGOUT_SUCCEEDED:
      return initialState;

    default:
      return state;
  }
};

export default bookmarksReducer;
