import React from 'react';
import PropTypes from 'prop-types';


const PersonalDatas = ({ isCollapse, firstName, lastName, birthDay, birthPlace, motherName, idCard, taxNumber, tajNumber }) => {
  const className = `profile-required-data-group${isCollapse ? ' collapse' : ''}`;
  return (
    <>
      {
        !isCollapse
          ? (
            <>
              <div className="profile-page-main-title">PERSONAL DATA</div>
              <div className="profile-required-data-group">
                <div className="profile-required-data-group-field">FIRST NAME</div>
                <div className="profile-required-data-group-value">{firstName}</div>
              </div>
              <div className="profile-required-data-group">
                <div className="profile-required-data-group-field">LAST NAME</div>
                <div className="profile-required-data-group-value">{lastName}</div>
              </div>
            </>
          ) : null
      }
      <div className={className}>
        <div className="profile-required-data-group-field">BIRTHDAY</div>
        <div className="profile-required-data-group-value">{birthDay}</div>
      </div>
      <div className={className}>
        <div className="profile-required-data-group-field">BIRTH PLACE</div>
        <div className="profile-required-data-group-value">{birthPlace}</div>
      </div>
      <div className={className}>
        <div className="profile-required-data-group-field">MOTHER&apos;S MAIDEN NAME</div>
        <div className="profile-required-data-group-value">{motherName}</div>
      </div>
      <div className={className}>
        <div className="profile-required-data-group-field">ID CARD NUMBER</div>
        <div className="profile-required-data-group-value">{idCard}</div>
      </div>
      <div className={className}>
        <div className="profile-required-data-group-field">TAX NUMBER</div>
        <div className="profile-required-data-group-value">{taxNumber}</div>
      </div>
      <div className={className}>
        <div className="profile-required-data-group-field">SOCIAL SECURITY NUMBER</div>
        <div className="profile-required-data-group-value">{tajNumber}</div>
      </div>
    </>
  );
};

PersonalDatas.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  birthDay: PropTypes.string.isRequired,
  birthPlace: PropTypes.string.isRequired,
  motherName: PropTypes.string.isRequired,
  idCard: PropTypes.string.isRequired,
  taxNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  tajNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isCollapse: PropTypes.bool.isRequired,
};

export default PersonalDatas;
