import { uniq } from 'lodash';
import {
  GET_ABSENCES_FAILED, GET_ABSENCES_REQUESTED, GET_ABSENCES_SUCCEEDED,
  NEW_ABSENCES_FAILED, NEW_ABSENCES_REQUESTED, NEW_ABSENCES_SUCCEEDED,
  GET_ABSENCE_DETAILS_SUCCEEDED, GET_ABSENCE_DETAILS_FAILED, GET_ABSENCE_DETAILS_REQUESTED,
  UPDATE_ABSENCE_DETAILS_SUCCEEDED, UPDATE_ABSENCE_DETAILS_REQUESTED, UPDATE_ABSENCE_DETAILS_FAILED,
  DELETE_ABSENCE_REQUESTED, DELETE_ABSENCE_SUCCEEDED, DELETE_ABSENCE_FAILED,
  GET_ABSENCE_TYPES_REQUESTED, GET_ABSENCE_TYPES_SUCCEEDED, GET_ABSENCE_TYPES_FAILED,
} from 'consts/actionTypes';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import * as API from 'services/api';
import * as config from 'services/config';
import { openNotificationWithIcon } from 'services/utils';
import { selectFilter } from 'actions/tableActions';

export const getUserReducer = (store) => store.userReducer;

export function* getAbsences() {
  try {
    const userReducer = yield select(getUserReducer);
    const { userId } = userReducer;
    const yearly = yield call(API.getData, `${config.host}/api/user/${userId}/absence_yearly?_format=json`);
    yield put({
      type: GET_ABSENCES_SUCCEEDED,
      payload: {
        yearly,
      },
    });
  } catch (error) {
    yield put({
      type: GET_ABSENCES_FAILED,
      payload: {
        errorMessage: error.message,
      },
    });
    console.log(error.message); // eslint-disable-line
  }
}

export function* getAbsenceTypes() {
  try {
    const types = yield call(API.getData, `${config.host}/api/list/taxonomy_term/absence_type?_format=json`);
    yield put({
      type: GET_ABSENCE_TYPES_SUCCEEDED,
      payload: {
        types,
      },
    });
  } catch (error) {
    yield put({
      type: GET_ABSENCE_TYPES_FAILED,
      payload: {
        errorMessage: error.message,
      },
    });
    console.log(error.message); // eslint-disable-line
  }
}

export function* getAbsenceDetails({ payload }) {
  try {
    const data = yield call(API.getData, `${config.host}/api/node/absence/${payload.id}?_format=json`);
    yield put({
      type: GET_ABSENCE_DETAILS_SUCCEEDED,
      payload: data,
    });
    payload.openModal();
  } catch (error) {
    yield put({
      type: GET_ABSENCE_DETAILS_FAILED,
      payload: {
        errorMessage: error.message,
      },
    });
    console.log(error.message); // eslint-disable-line
  }
}

export function* newAbsence({ payload }) {
  const body = {
    type: 'absence',
    intra_absence_type: { target_id: payload.radio },
    intra_body: { value: payload.text },
    intra_interval: [{ value: payload.range[0], end_value: payload.range[1] }],
    moderation_state: { value: 'pending' },
  };

  try {
    yield call(API.postData, `${config.host}/api/node/absence?_format=json`, body);
    yield put({
      type: NEW_ABSENCES_SUCCEEDED,
    });
    yield put(selectFilter('absence', 'date', '')); // TODO: refact
    payload.callback();
    openNotificationWithIcon('success', 'success', 'You request has been sent!');
  } catch (error) {
    yield put({
      type: NEW_ABSENCES_FAILED,
      payload: {
        errorMessage: error.message,
      },
    });
    uniq(error.response.data.message.split('intra_interval: '))
      .forEach((text, i) => i > 0 && openNotificationWithIcon('error', 'error', text));
  }
}

export function* updateAbsence({ payload }) {
  const body = {
    type: 'absence',
    intra_absence_type: { target_id: payload.radio },
    intra_body: { value: payload.text },
    intra_interval: [{ value: payload.range[0], end_value: payload.range[1] }],
    moderation_state: { value: 'pending' },
  };

  try {
    yield call(API.patchData, `${config.host}/api/node/absence/${payload.id}?_format=json`, body);
    yield put({
      type: UPDATE_ABSENCE_DETAILS_SUCCEEDED,
    });
    yield put(selectFilter('absence', 'date', ''));
    payload.callback();
    openNotificationWithIcon('success', 'success', 'You request has been sent!');
  } catch (error) {
    yield put({
      type: UPDATE_ABSENCE_DETAILS_FAILED,
      payload: {
        errorMessage: error.message,
      },
    });
    uniq(error.response.data.message.split('intra_interval: '))
      .forEach((text, i) => i > 0 && openNotificationWithIcon('error', 'error', text));
  }
}

export function* deleteAbsence({ payload }) {
  const body = {
    type: 'absence',
    moderation_state: { value: 'cancelled' },
  };

  try {
    yield call(API.patchData, `${config.host}/api/node/absence/${payload}?_format=json`, body);
    yield put({
      type: DELETE_ABSENCE_SUCCEEDED,
    });
    yield put(selectFilter('absence', 'date', ''));
    openNotificationWithIcon('success', 'success', 'You holiday request has been cancelled!');
  } catch (error) {
    yield put({
      type: DELETE_ABSENCE_FAILED,
      payload: {
        errorMessage: error.message,
      },
    });
    uniq(error.response.data.message.split('intra_interval: '))
      .forEach((text, i) => i > 0 && openNotificationWithIcon('error', 'error', text));
  }
}

export const absenceSagas = [
  takeEvery(GET_ABSENCES_REQUESTED, getAbsences),
  takeEvery(GET_ABSENCE_TYPES_REQUESTED, getAbsenceTypes),
  takeEvery(GET_ABSENCE_DETAILS_REQUESTED, getAbsenceDetails),
  takeEvery(NEW_ABSENCES_REQUESTED, newAbsence),
  takeEvery(UPDATE_ABSENCE_DETAILS_REQUESTED, updateAbsence),
  takeEvery(DELETE_ABSENCE_REQUESTED, deleteAbsence),
];
