import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import IosArrowUp from 'react-ionicons/lib/IosArrowUp';
import { history } from 'services/config';
import {
  selectHistory, selectTag, selectTopicLang, selectTopic,
  selectCategory, getCategories, getTags, loading,
} from 'actions/wikiActions';
import WikiDetailsHistory from 'components/Wiki/WikiDetailsHistory';
import WikiDetailsContent from 'components/Wiki/WikiDetailsContent';
import WikiTopicsContainer from 'components/Wiki/WikiTopicsContainer';
import WikiSearchTile from 'components/Wiki/WikiSearchTile';
import './wiki-details-page.scss';
import { getPageTitle } from '../../utils/branding';

class WikiDetailsPage extends React.Component {
  constructor(props) {
    super(props);
    const {
      dispatchSelectCategory, dispatchGetCategories,
      dispatchGetTags, topics, selectedCategory,
      match: { params: { category, id } },
    } = props;
    if (!topics || !selectedCategory || !topics[selectedCategory]) {
      dispatchGetCategories();
      dispatchGetTags();
      dispatchSelectCategory(category, +id);
    }
  }

  componentDidMount() {
    document.title = getPageTitle('Wiki');
  }

  render() {
    const {
      topic, dispatchSelectHistory, dispatchSelectTag,
      dispatchSelectLang, topics, dispatchSelectTopic,
      selectedTopic, isMobile, selectedLang, backupLang,
      histories, selectedCategory, currentHistories, dispatchLoading,
      topicLoading,
    } = this.props;
    if (selectedCategory && selectedTopic && histories) {
      const currentLang = selectedTopic[selectedLang] ? selectedLang : backupLang;
      return (
        <>
          <div
            className="wiki-details-go-back-container"
            role="button"
            tabIndex={0}
            onClick={() => { history.push('/wiki'); }}
          >
            <IosArrowUp className="wiki-details-go-back-arrow" />
            {selectedCategory.split('-').join(' ')}
          </div>
          <div className="wiki-details-page-container">
            <WikiSearchTile />
            <div className="wiki-details-page-container-content">
              <WikiTopicsContainer
                topics={topics[selectedCategory]}
                handleClick={(clickedTopic) => { dispatchLoading(); dispatchSelectTopic(clickedTopic); }}
                selectedCategory={selectedCategory}
                selectedTopic={selectedTopic}
                isMobile={isMobile}
              />
              <div className="wiki-details-content-history-container">
                {
                  topicLoading ? (
                    <Skeleton count={5} />
                  ) : (
                    <>
                      <WikiDetailsContent
                        selectedTopic={topic}
                        onTagClick={dispatchSelectTag}
                        onSelectLanguage={(lang) => { dispatchSelectLang(lang); dispatchSelectTopic(topic); }}
                        currentLang={currentLang}
                        selectedLang={selectedLang}
                      />
                      <WikiDetailsHistory history={currentHistories} changeDocumentHistory={dispatchSelectHistory} />
                    </>
                  )
                }
              </div>
            </div>
          </div>
        </>
      );
    }
    return <div className="wiki-details-page-container" style={{ paddingTop: 54 }}><Skeleton count={5} /></div>;
  }
}

const mapStateToProps = (store) => ({
  topic: store.wikiReducer.selectedTopic,
  topics: store.wikiReducer.topics,
  categories: store.wikiReducer.categories,
  selectedTopic: store.wikiReducer.selectedTopic,
  selectedLang: store.wikiReducer.selectedLang,
  backupLang: store.wikiReducer.backupLang,
  histories: store.wikiReducer.histories,
  isMobile: store.appReducer.isMobile,
  selectedCategory: store.wikiReducer.selectedCategory,
  currentHistories: store.wikiReducer.currentHistories,
  topicLoading: store.wikiReducer.topicLoading,
});

const mapDispatchToProps = {
  dispatchSelectHistory: selectHistory,
  dispatchSelectTag: selectTag,
  dispatchSelectLang: selectTopicLang,
  dispatchSelectTopic: selectTopic,
  dispatchGetTags: getTags,
  dispatchSelectCategory: selectCategory,
  dispatchGetCategories: getCategories,
  dispatchLoading: loading,
};

WikiDetailsPage.propTypes = {
  topicLoading: PropTypes.bool.isRequired,
  topic: PropTypes.shape({
    history: PropTypes.shape({}),
    html: PropTypes.string,
  }),
  selectedCategory: PropTypes.string,
  histories: PropTypes.shape({}),
  currentHistories: PropTypes.arrayOf(PropTypes.shape({})),
  dispatchGetTags: PropTypes.func.isRequired,
  dispatchSelectHistory: PropTypes.func.isRequired,
  selectedLang: PropTypes.string.isRequired,
  backupLang: PropTypes.string.isRequired,
  dispatchSelectTag: PropTypes.func.isRequired,
  dispatchSelectLang: PropTypes.func.isRequired,
  dispatchGetCategories: PropTypes.func.isRequired,
  dispatchSelectTopic: PropTypes.func.isRequired,
  dispatchSelectCategory: PropTypes.func.isRequired,
  dispatchLoading: PropTypes.func.isRequired,
  topics: PropTypes.shape({}).isRequired,
  categories: PropTypes.shape({}),
  selectedTopic: PropTypes.shape({}),
  match: PropTypes.shape({
    params: PropTypes.shape({
      category: PropTypes.string,
      id: PropTypes.string,
    }),
  }).isRequired,
  isMobile: PropTypes.bool.isRequired,
};

WikiDetailsPage.defaultProps = {
  selectedTopic: null,
  currentHistories: null,
  categories: null,
  histories: null,
  selectedCategory: null,
  topic: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(WikiDetailsPage);
