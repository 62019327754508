import {
  APP_GET_CURRENT_USER_REQUESTED,
  APP_MENU_CLICK,
  APP_GET_STATUSES_REQUESTED,
  APP_GET_CURRENT_STATUS_REQUESTED,
  APP_REQUEST_STATUS_CHANGE_REQUESTED,
  USER_LOGOUT_REQUESTED,
  APP_RESIZE,
} from 'consts/actionTypes';

export const getCurrentUser = () => ({
  type: APP_GET_CURRENT_USER_REQUESTED,
});

export const getStatuses = () => ({
  type: APP_GET_STATUSES_REQUESTED,
});

export const getCurrentStatus = () => ({
  type: APP_GET_CURRENT_STATUS_REQUESTED,
});

export const menuClick = ({ key, item: { props: { table } } }) => ({
  type: APP_MENU_CLICK,
  payload: {
    key,
    table: table || key,
  },
});

export const requestStatusChange = ({ id, name, value }) => ({
  type: APP_REQUEST_STATUS_CHANGE_REQUESTED,
  payload: {
    id,
    type: name,
    value: value || '',
  },
});

export const logout = () => ({
  type: USER_LOGOUT_REQUESTED,
});

export const appResize = (isMobile, showMenu) => ({
  type: APP_RESIZE,
  payload: {
    isMobile,
    showMenu,
  },
});
