import * as consts from 'services/config';
import * as API from 'services/api';
import store from 'store';
import { logout } from 'actions/userActions';

/* eslint no-underscore-dangle: 0 */
export const requestInterceptor = {
  setupInterceptors: (api) => {
    api.interceptors.request.use(
      (request) => (
        request.url.includes('/user/null') ? null : request
      ),
      (error) => console.log(error), // eslint-disable-line
    );
  },
};

export const responseInterceptor = {
  setupInterceptors: (api, setHeader) => {
    api.interceptors.response.use(
      (response) => Promise.resolve(response),
      (error) => {
        const originalRequest = error.config;
        if (error.response) {
          const { status } = error.response;
          if (status === 401 || status === 403) {
            if (!originalRequest._retry) {
              originalRequest._retry = true;
              const refreshToken = localStorage.getItem('refreshToken');
              if (!refreshToken) {
                store.dispatch(logout());
                return null;
              }
              const postLoginData = {
                grant_type: consts.BACKEND_REFRESH_TOKEN_GRANT_TYPE,
                refresh_token: refreshToken,
              };
              const bodyFormData = new FormData();
              Object.entries(postLoginData).forEach((e) => bodyFormData.append(e[0], e[1]));
              return API
                .postLoginData(`${consts.host}/oauth/token`, bodyFormData)
                .then((response) => {
                  localStorage.setItem('accessToken', response.access_token);
                  localStorage.setItem('refreshToken', response.refresh_token);
                  setHeader(response.access_token);
                  originalRequest.headers.Authorization = `Bearer ${response.access_token}`;
                  if (originalRequest.data) {
                    const requestWithJsonParsedData = {
                      ...originalRequest,
                      data: JSON.parse(originalRequest.data),
                    };
                    return api(requestWithJsonParsedData);
                  }
                  return api(originalRequest);
                });
            }
            store.dispatch(logout());
          }
        }
        return Promise.reject(error);
      },
    );
  },
};
