import { takeEvery, call, put, select } from 'redux-saga/effects';
import {
  GET_COLLEAGUES_REQUESTED,
  GET_COLLEAGUES_SUCCEEDED,
  GET_COLLEAGUES_FAILED,
  SEARCH_COLLEAGUE,
  STATUS_FILTER,
  ROLE_FILTER,
} from 'consts/actionTypes';
import { host } from 'services/config';
import * as API from 'services/api';

export const getColleaguesReducer = (store) => store.colleaguesReducer;

export function* getColleagues() {
  const colleaguesReducer = yield select(getColleaguesReducer);
  const { searchField, filteredStatuses, filteredRoles } = colleaguesReducer;
  try {
    const currentStatus = yield call(API.getData, `${host}/api/users_current_status?_format=json`);
    const attendanceStatus = yield call(API.getData, `${host}/api/list/taxonomy_term/attendance_status?_format=json`);
    const roles = yield call(API.getData, `${host}/api/list/taxonomy_term/role?_format=json`);
    yield put({
      type: GET_COLLEAGUES_SUCCEEDED,
      payload: {
        currentStatus: currentStatus.data,
        attendanceStatus,
        roles,
      },
    });
    if (searchField) {
      yield put({
        type: SEARCH_COLLEAGUE,
        payload: {
          value: searchField,
        },
      });
    }
    if (filteredStatuses.length > 0) {
      yield put({
        type: STATUS_FILTER,
        payload: {
          filteredStatuses,
        },
      });
    }
    if (filteredRoles.length > 0) {
      yield put({
        type: ROLE_FILTER,
        payload: {
          filteredRoles,
        },
      });
    }
  } catch (error) {
    yield put({
      type: GET_COLLEAGUES_FAILED,
      payload: {
        errorMessage: error.message,
      },
    });
  }
}

export const colleaguesSagas = [
  takeEvery(GET_COLLEAGUES_REQUESTED, getColleagues),
];
