import React from 'react';
import PropTypes from 'prop-types';
import hangoutsLogo from 'assets/images/hangouts.png';
import './events.scss';


const EventItem = ({
  time, name, location, htmlLink, hangoutLink, isDeclined,
}) => (
  <li className={`event-list-item${isDeclined ? ' declined' : ''}`}>
    <div className="event-time">
      {time}
    </div>
    <div className="event-details">
      <div className="event-name">
        <a
          className="event-link"
          href={htmlLink}
          target="_blank"
          rel="noopener noreferrer"
          title="Go to Calendar Event"
        >
          {name}
        </a>
        {
          hangoutLink && !isDeclined
            ? (
              <a
                className="event-hangouts-link"
                href={hangoutLink}
                target="_blank"
                rel="noopener noreferrer"
                title="Join Hangouts Meet"
              >
                <img
                  src={hangoutsLogo}
                  alt="hangouts"
                  width="20"
                  height="20"
                />
              </a>
            ) : (
              null
            )
        }
      </div>
      <div className="event-location">{location}</div>
    </div>
  </li>
);

EventItem.propTypes = {
  time: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  htmlLink: PropTypes.string.isRequired,
  hangoutLink: PropTypes.string,
  location: PropTypes.string,
  isDeclined: PropTypes.bool,
};

EventItem.defaultProps = {
  hangoutLink: null,
  location: null,
  isDeclined: false,
};

export default EventItem;
