const initialState = {
  isLoading: false,
  hasLoadingError: false,
  errorMessage: '',
  maritalStatuses: null,
  dataTypes: null,
  dependantsModal: false,
  educationModal: false,
  examModal: false,
  examPlanModal: false,
  personalChangeModal: false,
  personalDetails: null,
  selectedProfilePicture: null,
  inputs: {
    datatype: undefined,
    newData: '',
    personalDataComment: '',
    fieldOfStudy: '',
    instituteName: '',
    degree: '',
    serialNumber: '',
    childsBirthday: '',
    childName: '',
    childTajNumber: '',
    childTaxNumber: '',
    childsMotherName: '',
  },
};

export default initialState;
