import { takeEvery, call, put, select } from 'redux-saga/effects';
import {
  APP_GET_CURRENT_USER_REQUESTED, APP_GET_CURRENT_USER_SUCCEEDED, APP_GET_CURRENT_USER_FAILED,
  APP_GET_CURRENT_STATUS_REQUESTED, APP_GET_CURRENT_STATUS_SUCCEEDED, APP_GET_CURRENT_STATUS_FAILED,
  APP_GET_STATUSES_REQUESTED, APP_GET_STATUSES_SUCCEEDED, APP_GET_STATUSES_FAILED,
  APP_REQUEST_STATUS_CHANGE_REQUESTED, APP_REQUEST_STATUS_CHANGE_SUCCEEDED, APP_REQUEST_STATUS_CHANGE_FAILED,
} from 'consts/actionTypes';
import * as config from 'services/config';
import { openNotificationWithIcon } from 'services/utils';
import * as API from 'services/api';

export const getUserReducer = (store) => store.userReducer;


export function* getCurrentUser() {
  try {
    const data = yield call(API.getData, `${config.host}/api/user/current_user?_format=json`);
    yield put({
      type: APP_GET_CURRENT_USER_SUCCEEDED,
      payload: {
        ...data,
      },
    });
  } catch (error) {
    yield put({
      type: APP_GET_CURRENT_USER_FAILED,
      payload: {
        errorMessage: error.message,
      },
    });
    console.log(error); // eslint-disable-line
  }
}

export function* getStatuses() {
  try {
    const data = yield call(API.getData, `${config.host}/api/list/attendance_status?_format=json`);
    yield put({
      type: APP_GET_STATUSES_SUCCEEDED,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: APP_GET_STATUSES_FAILED,
      payload: {
        errorMessage: error.message,
      },
    });
    console.log(error); // eslint-disable-line
  }
}

export function* getCurrentStatus() {
  try {
    const userReducer = yield select(getUserReducer);
    const { userId } = userReducer;
    const data = yield call(API.getData, `${config.host}/api/user/${userId}/current_status?_format=json`);

    yield put({
      type: APP_GET_CURRENT_STATUS_SUCCEEDED,
      payload: {
        ...data,
      },
    });
  } catch (error) {
    yield put({
      type: APP_GET_CURRENT_STATUS_FAILED,
      payload: {
        errorMessage: error.message,
      },
    });
    console.log(error); // eslint-disable-line
  }
}

export function* changeStatus({ payload: { id, type, value } }) {
  try {
    const userReducer = yield select(getUserReducer);
    const { userId } = userReducer;
    const data = {
      message: {
        value,
      },
      source_name: {
        value: 'INTRA_APP',
      },
      source_type: {
        value: 'manual',
      },
      status: {
        target_id: Number(id),
      },
      uid: {
        target_id: Number(userId),
      },
    };
    yield call(API.postData, `${config.host}/entity/intra_attendance_event?_format=json`, data);
    yield put({
      type: APP_REQUEST_STATUS_CHANGE_SUCCEEDED,
      payload: type,
    });
  } catch (error) {
    yield put({
      type: APP_REQUEST_STATUS_CHANGE_FAILED,
      payload: {
        errorMessage: error.message,
      },
    });
    openNotificationWithIcon('error', 'Your status has not been changed', 'Please try again!');
    console.log(error); // eslint-disable-line
  }
}

export const appSagas = [
  takeEvery(APP_GET_CURRENT_USER_REQUESTED, getCurrentUser),
  takeEvery(APP_GET_CURRENT_STATUS_REQUESTED, getCurrentStatus),
  takeEvery(APP_GET_STATUSES_REQUESTED, getStatuses),
  takeEvery(APP_REQUEST_STATUS_CHANGE_REQUESTED, changeStatus),
];
