import React, { Component } from 'react';
import IosSadOutline from 'react-ionicons/lib/IosSadOutline';
import './404.scss';
import { getPageTitle } from '../../utils/branding';

class Page404 extends Component {
  componentDidMount() {
    document.title = getPageTitle('Page404');
  }

  render() {
    return (
      <div className="page page-404">
        <IosSadOutline fontSize="60px" />
        Hello beautiful user, you are in the wrong place at the wrong time.
      </div>
    );
  }
}

export default Page404;
